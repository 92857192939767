import './App.css';
import NavHeader from './components/nav-header';
import Footer from './components/Footer';
import RouterView from "./router";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Provider,connect } from 'react-redux';
import {  useLocation } from 'react-router-dom';
import { useEffect } from 'react';
function App(props) {
  const Location = useLocation();
  useEffect(()=>{
    window.reset&&window.reset()
  },[Location])
  return (<>
    <div className="cs-preloader cs-center">
      <div className="cs-preloader_in"></div>
      <span>Loading</span>
    </div>
    <div id={Location.pathname}></div> 
    <Spin style={{position:"fixed"}} spinning={props.isLoading} indicator={<LoadingOutlined spin />} tip="loading..." size="large">
    <div className="App">
        <NavHeader />
          <RouterView />
        <Footer />
    </div>
    </Spin>
  </>
  );
}

export default connect((state)=>{
  return {
    isLoading:state.isLoading
  }
})(App);
