export default () => (
  <>
    <h2 className="cs-title_title">
      Introduction to the Inverter Patent for the Launch Event
    </h2>
    <div className="cs-bid_info">
      <b>Technical Overview</b>
      <ul>
        <li>
          <b>Patent name : </b> Inverter with a single stage structure and
          controlling apparatus for the same
        </li>
        <li>
          <b>Technology summary : </b> Conventional inverters have the problem
          of having two power stages or large electrolytic capacitors. This is
          bulky and uneconomical. This invention is an inverter with a single
          power stage structure, and controls the operation of the inverter by
          adjusting the PWM (Pulse Width Modulation) ON/OFF control signal input
          to the gate terminal of each transistor T1 or T4. Accordingly, it is a
          technology that enables efficient conversion of direct current power
          generated by PV panels into alternating current power without the need
          for large electrolyte capacitors. Therefore, compared to existing
          methods, it has innovative features such as reduced inverter volume,
          reduced cost, and increased service life.
        </li>
      </ul>
      <b>⭘ Market analysis</b>
      <ul>
        <li>
          <b>Market Size : </b> The global solar power microinverter market size
          reached USD 311.9 million in 2023. IMARC Group predicts that the
          market will reach USD 988.9 million by 2032, growing at a CAGR of
          13.3% from 2024 to 2032. Growing demand for improved efficiency and
          reliability, increasing adoption of solar energy to reduce carbon
          dioxide emissions and maintain sustainability, and growing popularity
          of individual panel optimization are the key factors driving the
          market.
        </li>
        <li>
          <b>Target Market : </b> The technology is targeting manufacturers of
          power conversion devices, builders of renewable energy systems, and
          companies involved in industrial power systems.
        </li>
      </ul>
      <b>⭘ NFT Minting Information</b>
      <ul>
        <li>
          <b>1st issue quantity: </b> 10
        </li>
        <li>
          <b>Minting Schedule : </b> August 10, 2024 (Saturday)
        </li>
        <li>
          <b>Selling price : </b> 1,000 BPT
        </li>
        <li>
          <b></b>Additional BPT sponsorship and drop events to be announced
          later
        </li>
      </ul>
      <b>⭘ Seller information</b>
      <ul>
        <li>
          <b>Name of organization (company) :</b>
          Data-to-energy
        </li>
        <li>
          <b>Contact : </b>
          tearis1125@gmail.com
        </li>
      </ul>
    </div>
  </>
);
