export default function CollectionDetails() {
  return (<>



    {/* End Header Section */}

    <div className="cs-height_90 cs-height_lg_80"></div>
    {/* Start Collection Details */}
    <div className="cs-bg" data-src="/assets/img/page_head_bg.svg">
      <div className="cs-height_100 cs-height_lg_70"></div>
      <div className="container">
        <div className="cs-collection_card">
          <div className="cs-collection_img"><img src="/assets/img/general/collection_details.jpeg" alt="Collection Details" /></div>
          <div className="cs-collection_bottom">
            <div className="cs-collection_avatar"><img src="/assets/img/avatar/avatar_20.png" alt="Avatar" /></div>
            <div className="cs-collection_info">
              <div className="cs-collection_info_in cs-white_bg">
                <div className="cs-collection_info_left">
                  <h2 className="cs-collection_avatar_name">Audioglyphs</h2>
                  <div className="cs-collection_user">@johon kobra <span>Created</span></div>
                  <a href="#" className="cs-btn cs-style1">
                    <span><i className="far fa-star"></i> Favourite</span>
                  </a>
                  <span className="cs-btn cs-style1">
                    <span>
                      <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1362_13228)">
                          <path d="M10.8914 11.9518C11.7635 11.9518 12.4704 11.2449 12.4704 10.3729C12.4704 9.50086 11.7635 8.79395 10.8914 8.79395C10.0194 8.79395 9.3125 9.50086 9.3125 10.3729C9.3125 11.2449 10.0194 11.9518 10.8914 11.9518Z" fill="white" />
                          <path d="M10.8914 3.93231C11.7635 3.93231 12.4704 3.22539 12.4704 2.35336C12.4704 1.48133 11.7635 0.774414 10.8914 0.774414C10.0194 0.774414 9.3125 1.48133 9.3125 2.35336C9.3125 3.22539 10.0194 3.93231 10.8914 3.93231Z" fill="white" />
                          <path d="M10.8914 19.9704C11.7635 19.9704 12.4704 19.2635 12.4704 18.3914C12.4704 17.5194 11.7635 16.8125 10.8914 16.8125C10.0194 16.8125 9.3125 17.5194 9.3125 18.3914C9.3125 19.2635 10.0194 19.9704 10.8914 19.9704Z" fill="white" />
                        </g>
                        <defs>
                          <clipPath id="clip0_1362_13228">
                            <rect width="20.2105" height="20.2105" fill="white" transform="translate(0.0546875 0.267578)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </span>
                </div>
                <div className="cs-collection_right">
                  <div className="cs-collection_list_wrap">
                    <ul className="cs-collection_list cs-white_bg cs-mp0">
                      <li>
                        <div className="cs-collection_list_title">Collection of</div>
                        <div className="cs-collection_list_number">12</div>
                      </li>
                      <li>
                        <div className="cs-collection_list_title">Owned by</div>
                        <div className="cs-collection_list_number">2</div>
                      </li>
                      <li>
                        <div className="cs-collection_list_title">Floor Price</div>
                        <div className="cs-collection_list_number">0.24 ETH</div>
                      </li>
                      <li>
                        <div className="cs-collection_list_title">Total Sales</div>
                        <div className="cs-collection_list_number">0.53 ETH</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* End Collection Details */}
    <div className="cs-height_100 cs-height_lg_70"></div>
    <div className="container">
      <div className="cs-sidebar_frame cs-style1">
        <div className="cs-sidebar_frame_left">
          <div className="cs-filter_sidebar">
            <div className="cs-search_widget">
              <form action="#" className="cs-search">
                <input type="text" className="cs-search_input" placeholder="검색" />
                <button className="cs-search_btn">
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.16667 16.3333C12.8486 16.3333 15.8333 13.3486 15.8333 9.66667C15.8333 5.98477 12.8486 3 9.16667 3C5.48477 3 2.5 5.98477 2.5 9.66667C2.5 13.3486 5.48477 16.3333 9.16667 16.3333Z" stroke="currentColor" strokeOpacity="0.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M17.5 18L13.875 14.375" stroke="currentColor" strokeOpacity="0.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </button>
              </form>
            </div>
            <div className="cs-filter_widget">
              <h2 className="cs-filter_toggle_btn">
              카테고리
                <span className="cs-arrow_icon">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.679688 0.96582L4.67969 4.96582L8.67969 0.96582" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
              </h2>
              <div className="cs-filter_toggle_body">
                <ul>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="category" id="Art" defaultChecked={true} />
                      <label className="form-check-label" htmlFor="Art">Art</label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="category" id="Fashion" />
                      <label className="form-check-label" htmlFor="Fashion">
                        Fashion
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="category" id="Music" />
                      <label className="form-check-label" htmlFor="Music">
                        Music
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="category" id="Video" />
                      <label className="form-check-label" htmlFor="Video">
                        Video
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="category" id="Games" />
                      <label className="form-check-label" htmlFor="Games">
                        Games
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="category" id="Sports" />
                      <label className="form-check-label" htmlFor="Sports">
                        Sports
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="category" id="Collectibles" />
                      <label className="form-check-label" htmlFor="Collectibles">
                        Collectibles
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div> {/* .cs-filter_widget */}
            <div className="cs-filter_widget">
              <h2 className="cs-filter_toggle_btn">
              상태
                <span className="cs-arrow_icon">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.679688 0.96582L4.67969 4.96582L8.67969 0.96582" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
              </h2>
              <div className="cs-filter_toggle_body">
                <ul>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="flexCheckChecked" defaultChecked={true} />
                      <label className="form-check-label" htmlFor="flexCheckChecked">Buy Now</label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                      <label className="form-check-label" htmlFor="flexCheckDefault">경매 중</label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="flexCheckDefault1" />
                      <label className="form-check-label" htmlFor="flexCheckDefault1">Looking to Sell</label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" id="flexCheckDefault2" />
                      <label className="form-check-label" htmlFor="flexCheckDefault2">Has Offers</label>
                    </div>
                  </li>
                </ul>
              </div>
            </div> {/* .cs-filter_widget */}
            <div className="cs-filter_widget">
              <h2 className="cs-filter_toggle_btn">
              가격
                <span className="cs-arrow_icon">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.679688 0.96582L4.67969 4.96582L8.67969 0.96582" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
              </h2>
              <div className="cs-filter_toggle_body">
                <div className="cs-price_form">
                  <form className="row row-15">
                    <div className="col-lg-12">
                      <div className="cs-form_field_wrap cs-select_arrow">
                        <select className="cs-form_field cs-field_sm">
                          <option value="BTC">BTC</option>
                          <option value="BTC">BTC</option>
                          <option value="ETH">ETH</option>
                          <option value="ADA">ADA</option>
                        </select>
                      </div>
                      <div className="cs-height_15 cs-height_lg_15"></div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cs-form_field_wrap">
                        <input type="text" className="cs-form_field cs-field_sm" placeholder="Min" />
                      </div>
                      <div className="cs-height_15 cs-height_lg_15"></div>
                    </div>
                    <div className="col-lg-6">
                      <div className="cs-form_field_wrap">
                        <input type="text" className="cs-form_field cs-field_sm" placeholder="Max" />
                      </div>
                      <div className="cs-height_10 cs-height_lg_10"></div>
                    </div>
                    <div className="col-lg-6">
                      <input type="reset" className="cs-btn cs-style1 cs-color1 cs-btn_sm" value="Clear" />
                    </div>
                    <div className="col-lg-6">
                      <button className="cs-btn cs-style1 cs-btn_sm"><span>Apply</span></button>
                    </div>
                  </form>
                </div>
              </div>
            </div> {/* .cs-filter_widget */}
            <div className="cs-filter_widget">
              <h2 className="cs-filter_toggle_btn">
              컬렉션
                <span className="cs-arrow_icon">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.679688 0.96582L4.67969 4.96582L8.67969 0.96582" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
              </h2>
              <div className="cs-filter_toggle_body">
                <ul>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="collection" id="Audioglyphs" defaultChecked={true} />
                      <label className="form-check-label" htmlFor="Audioglyphs">Audioglyphs</label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="collection" id="Autoglyphs" />
                      <label className="form-check-label" htmlFor="Autoglyphs">
                        Autoglyphs
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="collection" id="CryptoCrystal" />
                      <label className="form-check-label" htmlFor="CryptoCrystal">
                        CryptoCrystal
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="collection" id="CryptoArte" />
                      <label className="form-check-label" htmlFor="CryptoArte">
                        CryptoArte
                      </label>
                    </div>
                  </li>
                  <li>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="collection" id="CyberKongz" />
                      <label className="form-check-label" htmlFor="CyberKongz">
                        CyberKongz
                      </label>
                    </div>
                  </li>
                </ul>
              </div>
            </div> {/* .cs-filter_widget */}
            <div className="cs-filter_widget">
              <h2 className="cs-filter_toggle_btn">
                Filter By Color
                <span className="cs-arrow_icon">
                  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.679688 0.96582L4.67969 4.96582L8.67969 0.96582" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </span>
              </h2>
              <div className="cs-filter_toggle_body">
                <div className="cs-filter_by_color">
                  <div className="cs-color_item cs-color1"></div>
                  <div className="cs-color_item cs-color2"></div>
                  <div className="cs-color_item cs-color3"></div>
                  <div className="cs-color_item cs-color4"></div>
                  <div className="cs-color_item cs-color5"></div>
                  <div className="cs-color_item cs-color6"></div>
                  <div className="cs-color_item cs-color7"></div>
                  <div className="cs-color_item cs-color8"></div>
                </div>
              </div>
            </div> {/* .cs-filter_widget */}
          </div>
        </div>
        <div className="cs-sidebar_frame_right">
          <div className="cs-filter_head">
            <div className="cs-filter_head_left">
              <span className="cs-search_result cs-medium cs-ternary_color">29064886 결과</span>
              <div className="cs-form_field_wrap">
                <input type="text" className="cs-form_field cs-field_sm" placeholder="경매 중" />
              </div>
              <a href="#" className="cs-clear_btn">모두 지우기</a>
            </div>
            <div className="cs-filter_head_right">
              <div className="cs-form_field_wrap cs-select_arrow">
                <select className="cs-form_field cs-field_sm">
                  <option value="11">Sort By</option>
                  <option value="22">Last 7 days</option>
                  <option value="33">Last 30 days</option>
                  <option value="44">All time</option>
                </select>
              </div>
              <div className="cs-form_field_wrap cs-select_arrow">
                <select className="cs-form_field cs-field_sm">
                  <option value="1">Likes</option>
                  <option value="2">Most popular</option>
                  <option value="3">By price</option>
                  <option value="4">By published</option>
                </select>
              </div>
            </div>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                <span className="cs-card_like cs-primary_color">
                  <i className="fas fa-heart fa-fw"></i>
                  2.1K
                </span>
                <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
                  <img src="/assets/img/explore/1.jpeg" alt="Image" className="cs-zoom_item" />
                </a>
                <div className="cs-card_info">
                  <a href="#" className="cs-avatar cs-white_bg">
                    <img src="/assets/img/avatar/avatar_12.png" alt="Avatar" />
                    <span>Johny E.</span>
                  </a>
                  <h3 className="cs-card_title"><a href="#/explore-details">Best Patent Token #1001</a></h3>
                  <div className="cs-card_price">가격: <b className="cs-primary_color">0.29 NFT 7/21</b></div>
                  <hr />
                  <div className="cs-card_footer">
                    <span className="cs-card_btn_1" data-modal="#history_1">
                      {/* <i className="fas fa-redo fa-fw"></i>
                    View History */}
                    </span>
                    <a href="#/explore-details" className="cs-card_btn_2"><span>구매</span></a>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>{/* .col */}
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                <span className="cs-card_like cs-primary_color">
                  <i className="fas fa-heart fa-fw"></i>
                  3.3K
                </span>
                <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
                  <img src="/assets/img/explore/2.jpeg" alt="Image" className="cs-zoom_item" />
                </a>
                <div className="cs-card_info">
                  <a href="#" className="cs-avatar cs-white_bg">
                    <img src="/assets/img/avatar/avatar_13.png" alt="Avatar" />
                    <span>debit alex</span>
                  </a>
                  <h3 className="cs-card_title"><a href="#/explore-details">Best Patent Token #1002</a></h3>
                  <div className="cs-card_price">가격: <b className="cs-primary_color">0.24 NFT 4/17</b></div>
                  <hr />
                  <div className="cs-card_footer">
                    <span className="cs-card_btn_1" data-modal="#history_1">
                      {/* <i className="fas fa-redo fa-fw"></i>
                    View History */}
                    </span>
                    <a href="#/explore-details" className="cs-card_btn_2"><span>구매</span></a>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>{/* .col */}
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                <span className="cs-card_like cs-primary_color">
                  <i className="fas fa-heart fa-fw"></i>
                  3.1K
                </span>
                <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
                  <img src="/assets/img/explore/3.jpeg" alt="Image" className="cs-zoom_item" />
                </a>
                <div className="cs-card_info">
                  <a href="#" className="cs-avatar cs-white_bg">
                    <img src="/assets/img/avatar/avatar_12.png" alt="Avatar" />
                    <span>robert Alex</span>
                  </a>
                  <h3 className="cs-card_title"><a href="#/explore-details">Best Patent Token #1003</a></h3>
                  <div className="cs-card_price">가격: <b className="cs-primary_color">0.09 NFT 1/9</b></div>
                  <hr />
                  <div className="cs-card_footer">
                    <span className="cs-card_btn_1" data-modal="#history_1">
                      {/* <i className="fas fa-redo fa-fw"></i>
                    View History */}
                    </span>
                    <a href="#/explore-details" className="cs-card_btn_2"><span>구매</span></a>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>{/* .col */}
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                <span className="cs-card_like cs-primary_color">
                  <i className="fas fa-heart fa-fw"></i>
                  2.1K
                </span>
                <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
                  <img src="/assets/img/explore/4.jpeg" alt="Image" className="cs-zoom_item" />
                </a>
                <div className="cs-card_info">
                  <a href="#" className="cs-avatar cs-white_bg">
                    <img src="/assets/img/avatar/avatar_12.png" alt="Avatar" />
                    <span>johny e.</span>
                  </a>
                  <h3 className="cs-card_title"><a href="#/explore-details">Best Patent Token #1004</a></h3>
                  <div className="cs-card_price">가격: <b className="cs-primary_color">0.19 NFT 5/11</b></div>
                  <hr />
                  <div className="cs-card_footer">
                    <span className="cs-card_btn_1" data-modal="#history_1">
                      {/* <i className="fas fa-redo fa-fw"></i>
                    View History */}
                    </span>
                    <a href="#/explore-details" className="cs-card_btn_2"><span>구매</span></a>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>{/* .col */}
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                <span className="cs-card_like cs-primary_color">
                  <i className="fas fa-heart fa-fw"></i>
                  1.2K
                </span>
                <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
                  <img src="/assets/img/explore/5.jpeg" alt="Image" className="cs-zoom_item" />
                </a>
                <div className="cs-card_info">
                  <a href="#" className="cs-avatar cs-white_bg">
                    <img src="/assets/img/avatar/avatar_13.png" alt="Avatar" />
                    <span>austin R.</span>
                  </a>
                  <h3 className="cs-card_title"><a href="#/explore-details">Best Patent Token #1005</a></h3>
                  <div className="cs-card_price">가격: <b className="cs-primary_color">0.29 NFT 11/19</b></div>
                  <hr />
                  <div className="cs-card_footer">
                    <span className="cs-card_btn_1" data-modal="#history_1">
                      {/* <i className="fas fa-redo fa-fw"></i>
                    View History */}
                    </span>
                    <a href="#/explore-details" className="cs-card_btn_2"><span>구매</span></a>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>{/* .col */}
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                <span className="cs-card_like cs-primary_color">
                  <i className="fas fa-heart fa-fw"></i>
                  1.1k
                </span>
                <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
                  <img src="/assets/img/explore/6.jpeg" alt="Image" className="cs-zoom_item" />
                </a>
                <div className="cs-card_info">
                  <a href="#" className="cs-avatar cs-white_bg">
                    <img src="/assets/img/avatar/avatar_12.png" alt="Avatar" />
                    <span>robert</span>
                  </a>
                  <h3 className="cs-card_title"><a href="#/explore-details">Best Patent Token #1006</a></h3>
                  <div className="cs-card_price">가격: <b className="cs-primary_color">0.19 NFT 7/13</b></div>
                  <hr />
                  <div className="cs-card_footer">
                    <span className="cs-card_btn_1" data-modal="#history_1">
                      {/* <i className="fas fa-redo fa-fw"></i>
                    View History */}
                    </span>
                    <a href="#/explore-details" className="cs-card_btn_2"><span>구매</span></a>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>{/* .col */}
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                <span className="cs-card_like cs-primary_color">
                  <i className="fas fa-heart fa-fw"></i>
                  2.2k
                </span>
                <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
                  <img src="/assets/img/explore/7.jpeg" alt="Image" className="cs-zoom_item" />
                </a>
                <div className="cs-card_info">
                  <a href="#" className="cs-avatar cs-white_bg">
                    <img src="/assets/img/avatar/avatar_13.png" alt="Avatar" />
                    <span>debit alex</span>
                  </a>
                  <h3 className="cs-card_title"><a href="#/explore-details">Best Patent Token #1007</a></h3>
                  <div className="cs-card_price">가격: <b className="cs-primary_color">0.28 NFT 21/91</b></div>
                  <hr />
                  <div className="cs-card_footer">
                    <span className="cs-card_btn_1" data-modal="#history_1">
                      {/* <i className="fas fa-redo fa-fw"></i>
                    View History */}
                    </span>
                    <a href="#/explore-details" className="cs-card_btn_2"><span>구매</span></a>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>{/* .col */}
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                <span className="cs-card_like cs-primary_color">
                  <i className="fas fa-heart fa-fw"></i>
                  1.4k
                </span>
                <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
                  <img src="/assets/img/explore/8.jpeg" alt="Image" className="cs-zoom_item" />
                </a>
                <div className="cs-card_info">
                  <a href="#" className="cs-avatar cs-white_bg">
                    <img src="/assets/img/avatar/avatar_12.png" alt="Avatar" />
                    <span>debit alex</span>
                  </a>
                  <h3 className="cs-card_title"><a href="#/explore-details">Best Patent Token #1008</a></h3>
                  <div className="cs-card_price">가격: <b className="cs-primary_color">0.17 NFT 14/23</b></div>
                  <hr />
                  <div className="cs-card_footer">
                    <span className="cs-card_btn_1" data-modal="#history_1">
                      {/* <i className="fas fa-redo fa-fw"></i>
                    View History */}
                    </span>
                    <a href="#/explore-details" className="cs-card_btn_2"><span>구매</span></a>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>{/* .col */}
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                <span className="cs-card_like cs-primary_color">
                  <i className="fas fa-heart fa-fw"></i>
                  1.9k
                </span>
                <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
                  <img src="/assets/img/explore/9.jpeg" alt="Image" className="cs-zoom_item" />
                </a>
                <div className="cs-card_info">
                  <a href="#" className="cs-avatar cs-white_bg">
                    <img src="/assets/img/avatar/avatar_13.png" alt="Avatar" />
                    <span>austin r.</span>
                  </a>
                  <h3 className="cs-card_title"><a href="#/explore-details">Best Patent Token #1009</a></h3>
                  <div className="cs-card_price">가격: <b className="cs-primary_color">0.09 NFT 10/91</b></div>
                  <hr />
                  <div className="cs-card_footer">
                    <span className="cs-card_btn_1" data-modal="#history_1">
                      {/* <i className="fas fa-redo fa-fw"></i>
                    View History */}
                    </span>
                    <a href="#/explore-details" className="cs-card_btn_2"><span>구매</span></a>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>{/* .col */}
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                <span className="cs-card_like cs-primary_color">
                  <i className="fas fa-heart fa-fw"></i>
                  9.3k
                </span>
                <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
                  <img src="/assets/img/explore/10.jpeg" alt="Image" className="cs-zoom_item" />
                </a>
                <div className="cs-card_info">
                  <a href="#" className="cs-avatar cs-white_bg">
                    <img src="/assets/img/avatar/avatar_12.png" alt="Avatar" />
                    <span>gallant j.</span>
                  </a>
                  <h3 className="cs-card_title"><a href="#/explore-details">Best Patent Token #1010</a></h3>
                  <div className="cs-card_price">가격: <b className="cs-primary_color">0.06 NFT 11/109</b></div>
                  <hr />
                  <div className="cs-card_footer">
                    <span className="cs-card_btn_1" data-modal="#history_1">
                      {/* <i className="fas fa-redo fa-fw"></i>
                    View History */}
                    </span>
                    <a href="#/explore-details" className="cs-card_btn_2"><span>구매</span></a>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>{/* .col */}
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                <span className="cs-card_like cs-primary_color">
                  <i className="fas fa-heart fa-fw"></i>
                  4.2k
                </span>
                <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
                  <img src="/assets/img/explore/11.jpeg" alt="Image" className="cs-zoom_item" />
                </a>
                <div className="cs-card_info">
                  <a href="#" className="cs-avatar cs-white_bg">
                    <img src="/assets/img/avatar/avatar_13.png" alt="Avatar" />
                    <span>robert alex</span>
                  </a>
                  <h3 className="cs-card_title"><a href="#/explore-details">Best Patent Token #1003</a></h3>
                  <div className="cs-card_price">가격: <b className="cs-primary_color">0.09 NFT 1/9</b></div>
                  <hr />
                  <div className="cs-card_footer">
                    <span className="cs-card_btn_1" data-modal="#history_1">
                      {/* <i className="fas fa-redo fa-fw"></i>
                    View History */}
                    </span>
                    <a href="#/explore-details" className="cs-card_btn_2"><span>구매</span></a>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>{/* .col */}
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                <span className="cs-card_like cs-primary_color">
                  <i className="fas fa-heart fa-fw"></i>
                  5.2k
                </span>
                <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
                  <img src="/assets/img/explore/12.jpeg" alt="Image" className="cs-zoom_item" />
                </a>
                <div className="cs-card_info">
                  <a href="#" className="cs-avatar cs-white_bg">
                    <img src="/assets/img/avatar/avatar_13.png" alt="Avatar" />
                    <span>robert</span>
                  </a>
                  <h3 className="cs-card_title"><a href="#/explore-details">Best Patent Token #1006</a></h3>
                  <div className="cs-card_price">가격: <b className="cs-primary_color">0.19 NFT 7/13</b></div>
                  <hr />
                  <div className="cs-card_footer">
                    <span className="cs-card_btn_1" data-modal="#history_1">
                      {/* <i className="fas fa-redo fa-fw"></i>
                    View History */}
                    </span>
                    <a href="#/explore-details" className="cs-card_btn_2"><span>구매</span></a>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>{/* .col */}
          </div>
          <div className="cs-height_10 cs-height_lg_10"></div>
          <div className="text-center">
            <a href="#" className="cs-btn cs-style1 cs-btn_lg"><span>더 보기</span></a>
          </div>
        </div>
      </div>
    </div>




  </>);
}