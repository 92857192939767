import { useWallet } from "../WalletContext";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import store from "@/store";
import http from "@/http";
import { useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
function navHeader(props) {
  const { t } = useTranslation();
  const { wallet, connectWallet, disconnectWallet, clearAppState } =
    useWallet();
  const navigate = useNavigate();
  const toLogin = () => navigate("/login");
  const loginOut = () => {
    http.post("/logout").then((res) => {
      store.dispatch({ type: "logout" });
      toLogin();
      // window.location.reload();
    });
  };
  const userBtn = useRef();
  let closeUserBtn = () => {
    $(userBtn.current).trigger("click");
  };
  const languageBtn = useRef();
  let closeLanguageBtn = () => {
    $(languageBtn.current).trigger("click");
  };
  useEffect(() => {
    $(window).on("click", () => {
      $("#header .cs-toggle_btn.active").trigger("click");
    });
  }, []);
  return (
    <header
      id="header"
      className="cs-site_header cs-style1 cs-sticky-header cs-white_bg"
      onClick={(event) => event.stopPropagation()}
    >
      <div className="cs-main_header">
        <div className="container-fluid">
          <div className="cs-main_header_in">
            <div className="cs-main_header_left">
              <a className="cs-site_branding" href="/">
                <img src="/assets/img/logo.jpg" alt="Logo" />
              </a>
              <b
                className="s-site_branding-desc"
                style={{
                  marginLeft: "10px",
                  marginTop: "25px",
                  display: "inline-block",
                  fontSize: "13px",
                }}
              >
                {t("components.patent_idea_trade")}
              </b>
            </div>
            <div className="cs-main_header_right">
              {/* <div className="cs-search_wrap" >
                <form action="#" className="cs-search">
                  <input
                    type="text"
                    className="cs-search_input"
                    placeholder="검색"
                  />
                  <button className="cs-search_btn">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.16667 16.3333C12.8486 16.3333 15.8333 13.3486 15.8333 9.66667C15.8333 5.98477 12.8486 3 9.16667 3C5.48477 3 2.5 5.98477 2.5 9.66667C2.5 13.3486 5.48477 16.3333 9.16667 16.3333Z"
                        stroke="currentColor"
                        strokeOpacity="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.5 18L13.875 14.375"
                        stroke="currentColor"
                        strokeOpacity="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </form>
              </div> */}
              <div className="cs-nav_wrap">
                <div className="cs-nav_out">
                  <div className="cs-nav_in">
                    <div className="cs-nav">
                      <ul className="cs-nav_list">
                        <li>
                          <a href="/">{t("components.home")}</a>
                        </li>
                        <li>
                          <a href="#/bigtech">{t("components.artwork")}</a>
                        </li>
                        <li>
                          <a href="#/general">{t("components.patent")} NFT</a>
                        </li>
                        <li>
                          <a href="#/idea">{t("components.idea_challenge")}</a>
                        </li>
                        {/* <li className="menu-item-has-children">
                        <a href="#/explore-1">둘러보기</a>
                        <ul>
                          <li><a href="#/explore-1">Explore Style 1</a></li>
                          <li><a href="#/explore-2">Explore Style 2</a></li>
                          <li><a href="#/explore-details">Explore Details</a></li>
                          <li><a href="#/live-action">Live Auction</a></li>
                          <li><a href="#/collection">Collection</a></li>
                          <li><a href="#/collection-details">Collection Details</a></li>
                        </ul>
                      </li> */}
                        {/* <li><a href="#/how-it-works">How It Works</a></li> */}
                        {/* <li className="menu-item-has-children">
                        <a href="#/blog">Community</a>
                        <ul>
                          <li><a href="#/blog">Blog</a></li>
                          <li><a href="#/blog-with-sidebar">Blog With Sidebar</a></li>
                          <li><a href="#/blog-details">Blog Details</a></li>
                        </ul>
                      </li>
                      <li><a href="#/activity">Activity</a></li>
                      <li className="menu-item-has-children cs-mega-menu">
                        <a href="#">Pages</a>
                        <ul className="cs-mega-wrapper">
                          <li className="menu-item-has-children">
                            <a href="">Column One</a>
                            <ul>
                              <li><a href="#/connect-wallet">지갑 연결</a></li>
                              <li><a href="#/about">회사소개</a></li>
                              <li><a href="#/help-center">Help Center</a></li>
                              <li><a href="#/help-center-browser-by-category">Help Center Catagory</a></li>
                              <li><a href="#/help-details">Help Center Details</a></li>
                              <li><a href="#/terms-condition">Terms & Conditions</a></li>
                              <li><a href="#/privacy-policy">Privacy Policy</a></li>
                              <li><a href="#/faq">FAQ</a></li>
                            </ul>
                          </li>
                          <li className="menu-item-has-children">
                            <a href="">Column Two</a>
                            <ul>
                              <li><a href="#/user-profile">Edit Profile</a></li>
                              <li><a href="#/user-account-settings">Profile Settings</a></li>
                              <li><a href="#/user-items">My Item</a></li>
                              <li><a href="#/create-items">Create Items</a></li>
                              <li><a href="#/user-activity">My Activity</a></li>
                              <li><a href="#/user-wallet">My Wallet</a></li>
                             <li><a href="#/login">Login</a></li>
                              <li><a href="#/register">Register</a></li>
                              <li><a href="#/forget-password">Forget Password</a></li>
                              <li><a href="#/404">404</a></li>
                            </ul>
                          </li>
                        </ul>
                      </li> */}
                        <li>
                          <a href="#/contact">
                            {t("components.customer_inquiry")}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cs-header_btns_wrap">
                <div className="cs-header_btns">
                  {/* <div className="cs-header_icon_btn cs-center cs-mobile_search_toggle">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.16667 16.3333C12.8486 16.3333 15.8333 13.3486 15.8333 9.66667C15.8333 5.98477 12.8486 3 9.16667 3C5.48477 3 2.5 5.98477 2.5 9.66667C2.5 13.3486 5.48477 16.3333 9.16667 16.3333Z"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.5 18L13.875 14.375"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div> */}
                  <div className="cs-toggle_box cs-notification_box">
                    <div
                      className="cs-toggle_btn cs-header_icon_btn cs-center"
                      ref={languageBtn}
                    >
                      {props.language == "en" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.5em"
                          height="1.5em"
                          viewBox="0 0 36 36"
                        >
                          <path
                            fill="#bf0a30"
                            d="M35.692 28.538H.308A4 4 0 0 0 4 31h28a4 4 0 0 0 3.692-2.462M0 23.811h36v2.561H0zM11.5 9.629H36v2.561H11.5zm24.192-2.167A4 4 0 0 0 32 5H11.5v2.462zM11.5 14.356H36v2.561H11.5zM0 19.083h36v2.561H0z"
                          />
                          <path
                            fill="#eee"
                            d="M11.5 12.189H36v2.167H11.5zM0 16.917v2.166h36v-2.166H12zm11.5-9.455v2.167H36V9c0-.545-.111-1.064-.308-1.538zM0 21.644h36v2.167H0zM0 27c0 .545.111 1.064.308 1.538h35.383A4 4 0 0 0 36 27v-.629H0z"
                          />
                          <path
                            fill="#002868"
                            d="M4 5a4 4 0 0 0-4 4v7.917h12V5z"
                          />
                          <path
                            fill="#fff"
                            d="M6.794 10.245L6 7.802l-.794 2.443H2.638l2.078 1.51l-.794 2.443L6 12.688l2.078 1.51l-.794-2.443l2.078-1.51z"
                          />
                        </svg>
                      )}
                      {props.language == "ko" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1.5em"
                          height="1.5em"
                          viewBox="0 0 36 36"
                        >
                          <path
                            fill="#eee"
                            d="M36 27a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V9a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4z"
                          />
                          <path
                            fill="#c60c30"
                            d="M21.441 13.085a6 6 0 0 0-8.356 1.474A3.001 3.001 0 0 0 18 18a3 3 0 0 1 4.915 3.442a6 6 0 0 0-1.474-8.357"
                          />
                          <path
                            fill="#003478"
                            d="M22.178 17.264A3 3 0 0 0 18 18a3.001 3.001 0 0 1-4.915-3.442a6 6 0 1 0 9.829 6.882a2.997 2.997 0 0 0-.736-4.176"
                          />
                          <path
                            fill="#292f33"
                            d="m24.334 25.572l1.928-2.298l.766.643l-1.928 2.298zm2.57-3.063l1.928-2.297l.766.643l-1.928 2.297zm-1.038 4.351l1.928-2.297l.766.643l-1.928 2.297zm2.572-3.066l1.93-2.297l.766.644l-1.93 2.296zm-1.041 4.352l1.93-2.297l.765.643l-1.929 2.297zm2.571-3.065l1.927-2.3l.767.643l-1.927 2.3zm.004-14.162l.766-.643l1.93 2.299l-.767.643zM27.4 7.853l.766-.643l1.928 2.299l-.767.642zm-1.533 1.288l.766-.643l4.5 5.362l-.766.643zm-1.532 1.284l.767-.643l1.927 2.298l-.766.642zm2.57 3.065l.766-.643l1.93 2.297l-.765.643zM6.4 20.854l.766-.643l4.499 5.363l-.767.643zM4.87 22.14l.765-.642l1.929 2.298l-.767.643zm2.567 3.066l.766-.643l1.93 2.297l-.766.643zm-4.101-1.781l.766-.643l4.5 5.362l-.767.643zm-.001-10.852l4.498-5.362l.767.642l-4.5 5.363zm1.532 1.287l4.5-5.363l.766.643l-4.5 5.362zM6.4 15.145l4.5-5.363l.766.643l-4.5 5.363z"
                          />
                        </svg>
                      )}

                      {/* <span className="cs-btn_badge">8</span> */}
                    </div>
                    <div className="cs-toggle_body">
                      <ul className="cs-notification_list">
                        <li
                          className="cs-notification_item"
                          onClick={() => {
                            store.dispatch({
                              type: "changeLanguage",
                              language: "ko",
                            });
                            closeLanguageBtn();
                          }}
                        >
                          한국어
                        </li>
                        <li
                          className="cs-notification_item"
                          onClick={() => {
                            store.dispatch({
                              type: "changeLanguage",
                              language: "en",
                            });
                            closeLanguageBtn();
                          }}
                        >
                          English
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    className="cs-toggle_box cs-profile_box"
                    style={{ display: props.isLogin ? "block" : "none" }}
                  >
                    <div
                      className="cs-toggle_btn cs-header_icon_btn cs-center"
                      ref={userBtn}
                    >
                      <svg
                        width="19"
                        height="18"
                        viewBox="0 0 19 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.5 15.75V14.25C15.5 13.4544 15.1839 12.6913 14.6213 12.1287C14.0587 11.5661 13.2956 11.25 12.5 11.25H6.5C5.70435 11.25 4.94129 11.5661 4.37868 12.1287C3.81607 12.6913 3.5 13.4544 3.5 14.25V15.75"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9.5 8.25C11.1569 8.25 12.5 6.90685 12.5 5.25C12.5 3.59315 11.1569 2.25 9.5 2.25C7.84315 2.25 6.5 3.59315 6.5 5.25C6.5 6.90685 7.84315 8.25 9.5 8.25Z"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="cs-toggle_body">
                      <div className="cs-user_info">
                        <h3 className="cs-user_name">
                          {props.userInfo?.userName}
                        </h3>
                        <h4 className="cs-user_balance">
                          {wallet?.balance || 0}ETH
                        </h4>

                        <div className="cs-user_profile_link">
                          {!!wallet?.account && (
                            <div className="cs-user_profile_link_in">
                              <span className="text-ellipsis d-block">
                                {wallet?.account}
                              </span>
                              <button>
                                <svg
                                  width="19"
                                  height="19"
                                  viewBox="0 0 19 19"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4.35381 4.15531L4.35156 5.74756V13.6256C4.35156 14.272 4.60837 14.892 5.06549 15.3491C5.52261 15.8063 6.1426 16.0631 6.78906 16.0631H13.2511C13.1346 16.3921 12.9191 16.6769 12.634 16.8784C12.349 17.0799 12.0086 17.1881 11.6596 17.1881H6.78906C5.84423 17.1881 4.93809 16.8127 4.26999 16.1446C3.6019 15.4765 3.22656 14.5704 3.22656 13.6256V5.74756C3.22656 5.01256 3.69681 4.38631 4.35381 4.15531ZM13.5391 2.18506C13.7607 2.18506 13.9801 2.22871 14.1848 2.31351C14.3896 2.39832 14.5756 2.52262 14.7323 2.67932C14.889 2.83601 15.0133 3.02204 15.0981 3.22678C15.1829 3.43152 15.2266 3.65095 15.2266 3.87256V13.6226C15.2266 13.8442 15.1829 14.0636 15.0981 14.2683C15.0133 14.4731 14.889 14.6591 14.7323 14.8158C14.5756 14.9725 14.3896 15.0968 14.1848 15.1816C13.9801 15.2664 13.7607 15.3101 13.5391 15.3101H6.78906C6.34151 15.3101 5.91229 15.1323 5.59582 14.8158C5.27935 14.4993 5.10156 14.0701 5.10156 13.6226V3.87256C5.10156 3.42501 5.27935 2.99578 5.59582 2.67932C5.91229 2.36285 6.34151 2.18506 6.78906 2.18506H13.5391ZM13.5391 3.31006H6.78906C6.63988 3.31006 6.4968 3.36932 6.39132 3.47481C6.28583 3.5803 6.22656 3.72337 6.22656 3.87256V13.6226C6.22656 13.9331 6.47856 14.1851 6.78906 14.1851H13.5391C13.6882 14.1851 13.8313 14.1258 13.9368 14.0203C14.0423 13.9148 14.1016 13.7717 14.1016 13.6226V3.87256C14.1016 3.72337 14.0423 3.5803 13.9368 3.47481C13.8313 3.36932 13.6882 3.31006 13.5391 3.31006Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <ul>
                        <li>
                          <a onClick={closeUserBtn} href="#/user-profile">
                            {t("components.my_profile")}
                          </a>
                        </li>
                        <li>
                          <a onClick={closeUserBtn} href="#/user-items">
                            {t("components.my_items")}
                          </a>
                        </li>
                        <li>
                          <a onClick={closeUserBtn} href="#/user-nfts">
                            {t("userItems.holdingsRecord")}
                          </a>
                        </li>
                        <li>
                          <a onClick={closeUserBtn} href="#/user-wallet">
                            {t("components.my_wallet")}
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={closeUserBtn}
                            href="#/user-account-settings"
                          >
                            {t("components.settings")}
                          </a>
                        </li>
                        {/* <li>
                        <div className="form-check form-switch">
                          <input className="form-check-input" type="checkbox" id="mode_switch"/>
                          <label className="form-check-label" htmlFor="mode_switch">Night Mode</label>
                        </div>
                      </li> */}
                        {/* <li><a onClick={loginOut}>Logout</a></li> */}
                      </ul>
                      <div className="text-center">
                        <a className="cs-btn cs-style1" onClick={loginOut}>
                          <span>{t("components.logout")}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  {props.isLogin ? (
                    <>
                      {wallet.account ? (
                        <a
                          className="cs-btn cs-style1 wallter-user"
                          onClick={disconnectWallet}
                        >
                          {wallet.account}
                        </a>
                      ) : (
                        <a
                          className="cs-btn cs-style1 pointer"
                          onClick={connectWallet}
                        >
                          <span>{t("components.connect_wallet")}</span>
                        </a>
                      )}
                    </>
                  ) : (
                    <a
                      className="cs-btn cs-style1 wallter-user"
                      onClick={toLogin}
                    >
                      <span>{t("components.login")}</span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default connect((state) => {
  return {
    userInfo: state.userInfo,
    isLogin: state.isLogin,
    language: state.language,
  };
})(navHeader);
