export default () => (
  <>
    <p>서비스 이용약관</p>

    <p>
      <strong>제 1조 (목적)</strong>
    </p>
    <p>
      본 이용약관은 Best Patent 이용약관에 동의한 Best Patent 회원(이하
      "회원"이라고 합니다)이 패튼에프티 주식회사(이하 "회사"라고 합니다)가
      제공하는Best Patent 서비스(이하 "서비스"라고 합니다) 내에서 NFT(본 약관
      제2조에서 정의합니다)를 거래함에 있어 회사와 회원의 권리와 의무, 기타
      필요한 사항을 규정함을 목적으로 합니다.
    </p>
    <p>
      <strong>제 2조 (정의)</strong>
    </p>
    <p>이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
    <p>
      1. "NFT"란, 블록체인 기반의 디지털 자산으로서, 이미지, 동영상, 음악, 서적
      등의 "디지털 저작물"에 대한 메타데이터 정보를 포함한 상태로 발행되는 대체
      불가능한 토큰(Non-Fungible Token)을 의미합니다. Best Patent에서 거래되는
      NFT는 다음 각 목의 두 가지로 구분됩니다.
    </p>

    <p>
      · 가. "<span style={{ color: "rgb(33, 94, 154)" }}>아트워크</span>
      NFT"란,<span style={{ color: "rgb(0, 0, 0)" }}> </span>역사적으로 의미있는
      특허, 빅테크 기업이 보유한 특허, 유명인이 보유한 특허 등 특허명세서의 내용
      (기술 내용, 도면)을 이미지화하여 디지털 자산으로 판매를 위해 "회사"가 Best
      Patent를 통하여 최초로 배포한 NFT를 의미합니다.
    </p>

    <p>
      · 나. "특허 NFT"란, 사업성이 뛰어난 특허를 BPT로 후원하고 추후 사업 수익
      발생 시 BPT 드랍 이벤트를 실시하여 후원자들에게 보상을 제공하기 위해
      “회사”가 Best Patent를 통하여 최초로 배포한 NFT를 의미합니다.
    </p>
    <p>
      <br />
    </p>

    <p>
      2. "디지털 저작물"이란, NFT가 발행된 시점에 발행인에 의하여 해당 NFT에
      1:1로 연계되어, 특별한 사정이 없는 한 변경되지 않는 디지털 이미지, 동영상,
      음악, 서적 등의 디지털 형태의 저작물을 의미합니다.
    </p>

    <p>
      3. "디지털 저작물 온라인 전시장"이란, NFT와 연계된 "디지털 저작물"을
      조회하고 감상할 수 있는 웹사이트 및 앱 등의 온라인 화면 및 그 구현에
      필요한 서버, 데이터베이스 등을 의미합니다.
    </p>
    <p>
      <br />
    </p>

    <p>
      4. "NFT 마켓플레이스"란, NFT를 거래할 수 있는 온라인 상의 장소 및 그
      구현에 필요한 서버, 데이터베이스 등을 의미합니다. 별도의 기재가 없는 한 본
      약관에서 "NFT 마켓플레이스"라 함은 회사가 제공하는 NFT 서비스(본조
      제8호에서 정의합니다)를 의미합니다.
    </p>

    <p>5. "NFT 발행인"이란, NFT를 발행하여 최초로 배포하는 자를 의미합니다.</p>

    <p>
      6. "NFT IP"란 다음 각 목의 행위를 하는데 필요한 일체의 적법한 권한을
      의미합니다.
    </p>

    <p>
      · 가. "디지털 저작물" 제작 [(i) "디지털 저작물" 자체에 대한 지식재산권을
      취득하거나 (ii) 원저작물 저작권자와의 디지털 저작물 제작 위탁계약 등을
      통하여 "디지털 저작물" 제작에 필요한 지식재산권 또는 이에 관한 라이선스를
      취득하는 경우를 포함한다]
    </p>
    <p>· 나. "디지털 저작물"과 연계된 NFT 발행 및 유통</p>

    <p>
      · 다. 발행 및 유통된 NFT를 보유한 자에게 "NFT 보유자의 권리"(제3조에서
      정의됨) 보장
    </p>

    <p>
      7. "외부 입∙출금"이란, 회사가 운영하는 디지털 자산 지갑과, 회사 외의
      제3자가 운영하는 디지털 자산 지갑 또는 외부의 개인 지갑 사이에 NFT를
      포함한 디지털 자산을 입∙출금하는 행위를 의미합니다.
    </p>

    <p>
      8. "NFT 서비스"란 Best Patent에서 제공하는 NFT 관련 서비스를 의미합니다.
    </p>

    <p>
      9. "컬렉션"이란 동일한 스마트 컨트랙트로 배포된 NFT 들을 통칭하며, "NFT
      발행인" 또는 회사가 이와 달리 분류하는 경우 해당 분류에 따른 NFT들을
      통칭합니다.
    </p>

    <p>
      10. "보조 저작물"이란, NFT의 상태, 위치, 특성 등을 나타내기 위하여
      표시되는 미리보기 이미지, 로고 이미지 등 디지털 형태의 저작물로서, "디지털
      저작물"에 해당하지 않는 저작물을 의미합니다.
    </p>

    <p>제 3조 (NFT 보유자의 권리)</p>

    <p>
      1. NFT 보유자는 "NFT 발행인" 또는 적법한 권한을 가진 제3자가 해당 NFT와
      관련하여 NFT 보유자에게 부여하거나 허용한 권리(이하 "NFT 보유자의
      권리"라고 합니다)를 행사할 수 있으며, 각 NFT 별로 "NFT 보유자의 권리"의
      내용은 다를 수 있습니다.
    </p>

    <p>
      2. "NFT 보유자의 권리"가 서비스 내에 표시되는 경우, 그러한 표시는 해당
      NFT에 부여된 권리를 완전히 반영하지 않을 수 있으며 NFT를 구매하려는 자는
      각 NFT별로 부여되는 권리의 구체적인 내용을 "NFT 발행인"이 제공하는
      웹페이지 등을 통해 확인할 필요가 있습니다.
    </p>
    <p>
      <br />
    </p>

    <p>
      3. NFT는 일반적으로 저작권, 상표권을 포함한 지식재산권이나 초상권, 상품화
      권리 등 특정 권리 그 자체를 의미하지 않고, NFT를 보유하는 것이 NFT와
      연계된 디지털 저작물을 보유하거나 디지털 저작물에 관한 저작권 등의
      지식재산권 자체를 취득하는 것을 의미하지 않습니다. 단, "NFT 발행인" 또는
      "NFT IP" 보유자가 달리 정한 경우에는 그러하지 않습니다.
    </p>

    <p>
      4. "NFT 보유자의 권리"는 NFT를 적법하게 보유하고 있는 동안에만 행사할 수
      있고, 타인에게 NFT를 양도∙이전한 경우, NFT를 양도∙이전한 회원은 더 이상
      "NFT 보유자의 권리"를 행사할 수 없습니다. "회사"가 공식적으로 지원하는
      방식이 아닌 방식 및/또는 관련 법령을 위반하는 방식으로 NFT를 이전받은
      자에게는 "NFT 보유자의 권리"가 보장되지 않을 수 있습니다.
    </p>

    <p>제 4조 (NFT 관련 유의사항)</p>
    <p>
      1. Best Patent에서 거래되는 NFT는 회사가 운영하는 이더리움 체인의 BPT
      프로토콜에 기반한 것이며, 회사는 향후 새로운 NFT 체인 및/또는 프로토콜의
      거래도 지원할 수 있습니다.
    </p>

    <p>
      2. 회사는 NFT거래 중개자이며, NFT거래 당사자가 아닙니다. NFT, "NFT
      보유자의 권리", "NFT IP" 및/또는 NFT 거래에 관한 책임은 "NFT 발행인"
      및/또는 판매자가 부담하고, 회사는 "NFT 발행인" 및/또는 판매자를 대리하거나
      NFT에 연계된 "디지털 저작물"의 진정성, "NFT IP", "NFT보유자의 권리"의
      완전성 등에 대하여 어떠한 보증도 하지 않습니다. 단, 회사의 고의 또는
      과실로 인하여 손해가 발생한 경우에는 법령에서 정한 범위 내에서 회사가
      책임을 부담합니다.
    </p>

    <p>
      3. 별도의 개발 지원이 없는 한 NFT는 다른 체인의 지갑으로 전송되지
      않습니다. 어느 NFT가 그 기반 체인이 아닌 다른 체인의 지갑으로 전송된 경우,
      회사의 고의, 과실이 없는 한 회사는 손해배상, 복구지원 등 일체의 책임을
      부담하지 않습니다.
    </p>

    <p>
      4. 회사는 Best Patent 및 Best Patent 내 NFT 거래 중개에 관한 홍보 목적으로
      NFT에 연계된 디지털 저작물을 이용할 수 있습니다.
    </p>

    <p>
      5. "디지털 저작물 온라인 전시장" 개설 여부는 각 NFT별로 달라질 수 있고,
      회사는 각 NFT의 "디지털 저작물 온라인 전시장" 개설을 보장하지 않습니다.
    </p>

    <p>
      6. NFT와 관련하여 발생한 제세공과금은 관련 법령에서 납세 의무자로 정하는
      자가 부담합니다. 다만, 어느 NFT와 관련하여 납세 의무자가 아닌 자가 특정
      거래에 대한 제세공과금을 부담하기로 정한 경우에는 그러하지 않습니다.
    </p>

    <p>제 5조 (NFT 서비스 관련 유의사항)</p>

    <p>
      1. 회사는 "
      <span style={{ color: "rgb(89, 89, 89)", fontFamily: "Arial" }}> </span>
      Best Patent "에서 제공되는 서비스의 범위(거래지원하는 NFT체인 및/또는
      프로토콜의 종류, 브릿지 제공 여부, 개별 NFT의 거래 지원 여부, 거래 지원
      기간, 외부 입∙출금 지원 여부를 포함하며, 이에 한정하지 않습니다)에 관해서
      독자적인 판단 권한을 보유하며 각 NFT별로 제공되는 서비스의 범위는 상이할
      수 있습니다.
    </p>

    <p>
      2. 서비스 내 화면에, (i) " Best Patent "에서 배포 또는 거래될 수 있는 모든
      NFT 관련 정보("NFT 발행인", 크리에이터, 디지털 저작물 관련 정보 등) 및
      (ii) 회원의 닉네임, 거래 관련 정보(거래 일시, 거래 금액, 현재 판매 여부,
      판매 희망 금액, 보유 NFT 내역 등), 서비스 내 활동 등이 공개될 수 있습니다.
    </p>

    <p>
      3. "NFT 발행인"이 기존에 배포되었거나 배포 중인 NFT에 대해 조치를 취할
      필요가 있음을 소명하는 경우(NFT, 그 메타데이터 또는 배포 관련 데이터에
      오류가 있어 이를 시정할 필요가 있는 등의 사유를 포함하며, 이에 한정하지
      않습니다), 회사는 NFT의 배포 및/또는 거래를 정지하고, "NFT 발행인"이
      오류가 없는 NFT를 발행하기 위해 필요한 조치(해당 NFT 소각 및 오류 없는
      새로운 NFT 발행 등을 포함하며 이에 한정하지 않습니다)를 취하도록 할 수
      있습니다. 회사는 본 항의 조치에 협력한 후 5영업일 이내에 조치의 사유 및
      내용을 회원에게 통지합니다.
    </p>

    <p>
      4. 회사는 "NFT 발행인"이 요청하는 경우 "NFT 발행인"이 NFT와 관련하여
      진행하는 이벤트에 관해 서비스 내에 공지할 수 있습니다. 해당 NFT 관련
      이벤트의 책임주체는 "NFT 발행인"이며, 회사는 고의 또는 과실이 없는 한 해당
      NFT 관련 이벤트에 관하여 책임을 부담하지 않습니다.
    </p>

    <p>
      5. 특정 종류의 NFT를 보유한 NFT 보유자에게 별도의 NFT를 지급할 의도로 Best
      Patent 이용약관 제15조 제11항에 따른 “임의 입금”이 발생하였고, 회사가 상기
      “임의 입금”에 대하여 입금 처리 또는 복구지원을 진행하기로 결정한 경우,
      “임의 입금”된 NFT는 임의 입금의 대상인 “NFT 보유자”들에게 무작위로 지급될
      수 있습니다. “임의 입금” 발생을 위하여 NFT의 단순 보유 외 추가 행위(신청,
      임의 입금받을 디지털 주소 기입 등)가 필요한 경우 "NFT 보유자"가 이를
      독자적으로 이행하여야 하며, 회사는 회사의 고의나 과실이 없는 한 이와
      관련한 책임을 부담하지 않습니다.
    </p>

    <p>
      6. 회사는 다음 각 호의 사유에 해당하는 경우 개별 NFT의 거래지원을 정지,
      중단하거나 종료할 수 있으며, 거래지원이 정지 또는 종료된 NFT는 외부 입금이
      지원되지 않습니다.
    </p>

    <p>1. 제5조 제3항에 해당하는 경우</p>
    <p>
      2. "디지털 저작물"에 대한 권리 내지 "NFT IP"와 관련하여 분쟁이 발생하는
      경우(제8조에 해당하는 경우를 포함하며 이에 한정하지 않습니다)
    </p>
    <p>
      3. "NFT 발행인"이 회사의 특수관계인(「상법 시행령」 제34조 제4항 각 호에
      따른 특수관계인을 말합니다)으로 확인된 경우
    </p>
    <p>4. NFT가 기반한 체인 및/또는 프로토콜의 운영이 중단되는 경우</p>
    <p>
      5. NFT 기반 컨텐츠가 유실되거나, 유사한 컨텐츠에 기반한 NFT의 타
      유통경로에 의한 유통이 확인된 경우
    </p>
    <p>6. NFT와 관련하여 중대한 이상거래가 발생한 경우</p>
    <p>
      7. Best Patent 서비스 이용약관 제15조 제4항에서 정하는 디지털 자산
      거래지원 종료 사유에 해당하는 경우
    </p>
    <p>
      8. 또는 상기 각 호의 사유와 유사하거나 Best Patent NFT 회원 보호를 위해
      거래지원 종료 필요성이 인정되는 경우
    </p>

    <p>
      7. 본조 제6항에 따라 NFT에 대한 거래지원을 정지하거나 종료하는 경우, 제6항
      제2호의 경우를 제외하고, (i) 회사는 기존에 외부 입∙ 출금이 허용되던 NFT의
      거래지원이 종료된 경우, 거래지원 종료일로부터 적어도 삼십(30)일의 기간
      동안 해당 NFT의 외부 출금을 지원하고, (ii) 외부 입∙출금이 허용되지 않던
      NFT의 경우 해당 NFT에 연계된 디지털 저작물을 다운로드할 수 있도록
      지원합니다(본 조항에 따라 다운로드받은 디지털 저작물은 "NFT 보유자의
      권리"에 의하여 부여된 범위 내의 목적 및 방법으로만 이용할 수 있습니다)
    </p>

    <p>
      8. 다음 각 호의 사유에 해당하는 경우 회사는 NFT 서비스의 일부 또는 전부를
      종료할 수 있으며, 이 경우 서비스가 종료된 NFT를 보유한 자는 회사의 NFT
      서비스 내에서 "NFT 보유자의 권리"를 더 이상 행사할 수 없습니다. 회사가 NFT
      서비스의 일부 또는 전부를 종료한 경우 본 조 제7항을 준용합니다. NFT 서비스
      일부 또는 전부 종료에 관한 사전 통지 방법, 종료 후 외부 출금 지원 등 본
      조에서 정하지 않은 사항은Best Patent서비스 이용약관에서 정한 바에
      의합니다.
    </p>
    <p>1. "회사"가 Best Patent의 운영을 중단하는 경우</p>
    <p>
      2. 국가 기관의 지시, 권고 또는 관련 법령에 의할 때 기존 NFT 서비스의 전부
      또는 일부를 종료할 필요가 있는 경우
    </p>
    <p>
      <span style={{ color: "rgb(33, 94, 154)" }}>3. Best Patent </span>
      서비스 이용약관 제15조 제3항에서 정하는 서비스 중단 사유에 해당하는 경우
    </p>

    <p>제 6조 (금지 행위 및 해지)</p>
    <p>
      1. 회원은 Best Patent에서 유통된 NFT 및 이에 연계된 디지털 저작물과
      관련하여 다음 각 호의 행위를 할 수 없습니다. 단, 제1호 내지 제4호의 경우
      "NFT 발행인" 또는 "NFT IP"의 보유자가 달리 정한 경우에는 그러하지
      않습니다.
    </p>

    <p>1. 디지털 저작물을 수정, 왜곡하여 게시하는 행위</p>
    <p>
      2. 본인 또는 제3자의 제품 또는 서비스를 광고하는 등 디지털 저작물을 영리
      목적으로 이용하는 행위
    </p>
    <p>3. 디지털 저작물과 연계된 별도의 NFT를 발행하는 행위</p>
    <p>
      4. "NFT 보유자의 권리"에 따라 이용 가능한 디지털 저작물을 악용하여, 별도의
      지식재산권을 등록, 취득하는 행위
    </p>
    <p>
      5. 특정 회원과 통정하여 매매하는 방식 또는 옥션 방식으로 진행되는 거래에
      수 개의 주문을 제출하는 방식 등을 통하여 NFT 가격을 비정상적으로
      변동시키는 행위
    </p>
    <p>6. 회사가 제공하는 방식 이외의 방식으로 NFT를 외부 입∙출금하는 행위</p>
    <p>7. 기타 상기 각 호에 준하는 행위</p>

    <p>
      2. 회원이 전항을 위반한 경우 회사는 서비스 이용을 제한함과 동시에
      시정요구를 할 수 있습니다. 시정요구에도 불구하고 상당한 기간 내에 시정되지
      않거나 2회 이상 반복적으로 같은 위반행위를 하는 경우에는 NFT서비스
      이용계약 및/또는 Best Patent 서비스 이용계약을 해지할 수 있습니다.
    </p>

    <p>
      3. 회원은 NFT를 보유한 상태에서는 서비스를 탈퇴할 수 없습니다. 회원은
      보유한 NFT를 처분하거나 "외부 출금"한 후 서비스를 탈퇴할 수 있습니다. 단,
      회원은 회사에게 NFT의 소각 또는 처분을 요청한 후 서비스를 탈퇴할 수
      있으며, 회사가 회원의 요청에 응한 경우 회원은 소각 또는 처분을 요청한
      NFT의 반환을 요청할 수 없습니다. 단, 회사의 고의 또는 과실이 있는 경우에는
      그러하지 않습니다.
    </p>

    <p>
      4. 본 조에 따라 Best Patent 서비스 이용약관 제10조, 제17조, 제18조,
      제20조의 적용이 배제되는 것은 아닙니다.
    </p>

    <p>제 7조 (NFT 거래 방법 관련 유의사항)</p>

    <p>
      1. 회원은 자신이 보유하는 "외부발행 NFT"를 회사가 지원하는 방식에 따라
      회사가 운영하는 가상자산 지갑으로 "외부 입금" 할 수 있습니다. 회원이
      "외부발행 NFT"를 회사의 가상자산 지갑으로 "외부 입금"하는 경우, 최초 입금
      시에 해당 "외부발행 NFT"가 속한 컬렉션 전체에 대한 "외부 입금" 권한을
      회사에 위임합니다. 회원은 해당 지갑 서비스에서 제공하는 방식으로 회사에게
      부여한 위임 권한을 철회할 수 있습니다.
    </p>

    <p>
      2. 회사는 회원이 개인 지갑에 보유하고 있는 NFT의 거래를 중개할 수 있으며,
      이 경우 회원이 신청한 거래가 네트워크에서 처리되기 시작하면 거래 신청을
      철회하거나 거래를 중단할 수 없습니다.
    </p>

    <p>제 8조 (디지털 저작물 관련 이의제기)</p>

    <p>
      1. "회사"가 제공하는 " Best Patent "를 통하여 유통되는 NFT 및 해당 NFT에
      연계된 "디지털 저작물"에 의하여 본인의 저작권 기타 권리가 침해된다고
      주장하는 자(이하 "권리주장자"라고 합니다)는 해당 사실을 소명하여 "회사"에
      해당 NFT의 이용 및 유통을 중단할 것을 요청할 수 있습니다.
    </p>

    <p>
      2. "회사"는 제1항에 따라 NFT의 이용 및 유통 중단을 요구받은 경우에는 즉시
      해당 NFT에 대한 거래지원을 중단하고, 권리주장자, NFT를 보유하고 있는 회원
      및 "NFT 발행인"에게 해당 사실을 통보하여야 합니다.
    </p>

    <p>
      3. 제2항에 따라 통보를 받은 "NFT 발행인"이 자신에게 정당한 권리가 있음을
      소명하면서 NFT의 이용 및 유통 재개를 요구하는 경우, "회사"는 재개요구사실
      및 재개예정일을 권리주장자와 NFT를 보유하고 있는 회원에게 지체없이
      통보하고 해당 예정일에 "NFT 보유자의 권리"를 재개합니다. 다만,
      권리주장자가 재개예정일 전에 회사에 "NFT 발행인"의 침해행위에 대하여 소를
      제기한 사실을 통보한 경우에는 그러하지 않습니다.
    </p>

    <p>
      4. 특정 NFT와 이에 연계된 디지털 저작물에 관한 권리 및/또는 "NFT IP"
      관련하여 "권리주장자"가 권리 침해를 주장하는 경우, "회사"는 본 조 및 제5조
      제6항에서 정한 바에 따라 해당 NFT의 거래 중개 등 서비스 제공을 중단하고,
      지체없이 "권리주장자"와 "NFT 발행인"에게 해당 사실을 알립니다. "회사"는
      해당 문제의 해결을 위하여 "권리주장자"와 "NFT 발행인"간 협의를 주선하여
      관련 분쟁이 조속히 해결되도록 노력합니다. 회사는 관련 분쟁이 종결되면 분쟁
      종결의 조건에 따라 중단되었던 NFT 거래 중개를 재개할 수 있습니다.
    </p>

    <p>
      5. NFT 발행인과 권리주장자 사이의 분쟁이 해결되지 않아 해당 NFT를 보유하고
      있는 회원에게 손해가 발생하더라도 회사는 이에 대하여 책임지지 않습니다.
      단, 회사의 고의 또는 과실로 인하여 손해가 발생한 경우에는 법령에서 정한
      범위 내에서 회사가 책임을 부담합니다.
    </p>

    <p>
      6. 회사는, 특정 "보조 저작물"에 의하여 본인의 저작권, 초상권, 기타의
      권리가 침해된다고 주장하는 자가 해당 사실을 소명하여 "회사"에 침해 사실에
      관한 문제를 제기하였거나 또는 그와 같은 문제가 제기될 가능성이 있는 경우,
      해당 "보조 저작물"의 노출을 중단하고, 회사가 지정하는 별개의 이미지로
      교체할 수 있습니다.
    </p>

    <p>제 9조 (Best Patent이용약관과의 관계)</p>

    <p>
      1. 본 이용약관에 명시되지 않은 사항은 Best Patent 이용약관 및 관계
      법령에서 정하는 바에 따르며, 관계 법령에서 정하지 아니한 사항은 상관례를
      따릅니다.
    </p>

    <p>
      2. 본 이용약관과 Best Patent 이용약관이 충돌할 경우 본 이용약관이 우선하여
      적용됩니다.
    </p>

    <p>제 10조 (준거법 및 분쟁 해결 방법)</p>
    <p>본 약관과 관련된 분쟁 발생 시 대한민국 법을 적용합니다.</p>
    <p>&lt;부칙&gt;</p>
    <p>
      이 약관은 2024년 08월 10일부터 적용됩니다.
      <br />
      이전의 이용약관은 아래에서 확인하실 수 있습니다.
    </p>
  </>
);
