import Web3 from "web3";
import contractABIstr from "./contractABI.json";

const web3 = new Web3(process.env.REACT_APP_RPC);
// const web3 = new Web3(window.ethereum);

export async function getTokenURI(contractAddress, tokenId) {
  try {
    const contract = new web3.eth.Contract(contractABIstr, contractAddress);
    const tokenURI = await contract.methods.tokenURI(tokenId).call();
    const name = await contract.methods.name().call();
    // console.log(name);
    // console.log(tokenURI);
    return { name, tokenURI };
  } catch (error) {
    console.error("Error fetching token URI:", error);
  }
}

// Example usage:
// getTokenURI('0xYourContractAddressHere', 'YourTokenIdHere');
