export default function BlogDetails() {
  return (<>



    {/* End Header Section */}

    <div className="cs-height_90 cs-height_lg_80"></div>
    {/* Start Page Head */}
    <section className="cs-page_head cs-bg" data-src="/assets/img/page_head_bg.svg">
      <div className="container">
        <div className="text-center">
          <h1 className="cs-page_title">Blog Details</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#/index">홈</a></li>
            <li className="breadcrumb-item active">Blog Details</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Page Head */}

    <div className="cs-height_100 cs-height_lg_70"></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2">
          <div className="cs-single_post">
            <h2>Why XYZ $3M Dune Best Patent Token Mistake Proves Intelle-
              ctual Property’s Dominion in Digital Fine Art</h2>
            <div className="cs-post_avatar">
              <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_18.png" alt="Avatr" /></a>
              <div className="cs-post_avatar_right">
                <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Marina G. Trujillo</a></h2>
                <div className="cs-post_meta">
                  <span className="cs-post_meta_item">5 Mar 2022</span>
                  <span className="cs-post_meta_item">25 Comments</span>
                </div>
              </div>
            </div>
            <img src="/assets/img/single-post1.jpeg" alt="" />
            <p>
              It is a long established fact that a reader will be distrac by the readable content of a page when looking at its layout. The point of using Lorem Ipm i has a more-ornormal distribution of letters,as opposed It is a long established fact that a reader will be distrac by the readable content of a page when looking at itslayout. The point of using Lorem Ipm i has a Best Patent Token <br /><br />
              Crypto is a long established fact that a reader will be distrac by the readable content of a page when looking at its layout. The point of using Lorem Ipm i has a more-ornormal distribution of letters,as opposed It is a long established fact that a reader will be distrac by the readable content of a page when looking at itslayout. The point of using Lorem Ipm i has a Best Patent Token
            </p>
            <h3>What is an Best Patent Token art?</h3>
            <p>Best Patent Token Music is a long established fact that a reader will be distrac by the readable content of a page when looking at its layout. The point of using Lorem Ipm i has a more-ornormal distribution of letters,as opposed It is a long established fact that a reader will be distrac by the readable content of a page when looking at itslayout. The point of using Lorem Ipm i has a Best Patent Token</p>
            <ul>
              <li>How to make and sell an Best Patent Token.</li>
              <li>Buy some cryptocurrency to fund your wallet.</li>
              <li>Add some cryptocurrency to your wallet.</li>
              <li>Upload the file you want to turn into an Best Patent Token.</li>
              <li>Set up an auction for your Best Patent Token</li>
            </ul>
            <div className="cs-height_10 cs-height_lg_10"></div>
            <img src="/assets/img/single-post2.jpeg" alt="" />
            <p>
              Best Patent Token a long established fact that a reader will be distrac by the readable content of a page when looking at its layout. The point of using Lorem Ipm i has a more-ornormal distribution of letters,as opposed It is a long established fact that a reader will be distrac by the readable content of a page when looking at itslayout. The point of using Lorem Ipm i has a Best Patent Token<br /><br />
              Digital Art long established fact that a reader will be distrac by the readable content of a page when looking at its layout. The point of using Lorem Ipm i has a more-ornormal distribution of letters,as opposed It is a long established fact that a reader will be distrac by the readable content of a page when looking at itlayout. The point of using Lorem Ipm i has a Best Patent Token
            </p>
          </div>
          <div className="cs-height_60 cs-height_lg_30"></div>
          <div id="comments" className="comments-area">
            <h2 className="comments-title">Comments</h2>
            <ol className="comment-list">
              <li className="comment">
                <div className="comment-body">
                  <div className="comment-author vcard">
                    <img className="avatar" src="/assets/img/avatar/avatar_5.png" alt="Author" />
                    <a href="#" className="url">George Steven</a>
                  </div>
                  <div className="comment-meta">
                    <a href="#">Jan 24, 2022 at 9:59 am </a>
                  </div>
                  <p>Donec pellentesque luctus tortor finibus blandit. Fusce tincidunt lectus augue, quis commodo justo tincidunt eget. Praesent at elit diam. Tortor finibus blandit</p>
                  <div className="reply"><a className="comment-reply-link" href="#">
                    Reply
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 5.74707H8.5C10.0913 5.74707 11.6174 6.37921 12.7426 7.50443C13.8679 8.62965 14.5 10.1558 14.5 11.7471V13.2471M1 5.74707L5.5 10.2471M1 5.74707L5.5 1.24707" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </a></div>
                </div>
                <ol className="children">
                  <li className="comment">
                    <div className="comment-body">
                      <div className="comment-author vcard">
                        <img className="avatar" src="/assets/img/avatar/avatar_11.png" alt="Author" />
                        <a href="#" className="url">Sarah Taylor</a>
                      </div>
                      <div className="comment-meta">
                        <a href="#">Jan 24, 2022 at 9:59 am </a>
                      </div>
                      <p>Thanks bro 🙂</p>
                      <div className="reply"><a className="comment-reply-link" href="#">
                        Reply
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 5.74707H8.5C10.0913 5.74707 11.6174 6.37921 12.7426 7.50443C13.8679 8.62965 14.5 10.1558 14.5 11.7471V13.2471M1 5.74707L5.5 10.2471M1 5.74707L5.5 1.24707" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      </a></div>
                    </div>
                  </li>{/* #comment-## */}
                </ol>{/* .children */}
              </li>{/* #comment-## */}
              <li className="comment cs-accent_4_bg">
                <div className="comment-body">
                  <div className="comment-author vcard">
                    <img className="avatar" src="/assets/img/avatar/avatar_7.png" alt="Author" />
                    <a href="#" className="url">Jhon Doe</a>
                  </div>
                  <div className="comment-meta">
                    <a href="#">Jan 24, 2022 at 9:59 am </a>
                  </div>
                  <p>Fusce tincidunt lectus augue, quis commodo justo tincidunt eget. Praesent at elit diam. Tortor finibus blandit. Donec pellentesque luctus tortor finibus blandit.</p>
                  <div className="reply"><a className="comment-reply-link" href="#">
                    Reply
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 5.74707H8.5C10.0913 5.74707 11.6174 6.37921 12.7426 7.50443C13.8679 8.62965 14.5 10.1558 14.5 11.7471V13.2471M1 5.74707L5.5 10.2471M1 5.74707L5.5 1.24707" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </a></div>
                </div>
              </li>{/* #comment-## */}
            </ol>{/* .comment-list */}
          </div>
          <div className="cs-height_60 cs-height_lg_30"></div>
          <h3 className="comment-reply-title cs-semi_bold">Leave a comment</h3>
          <div id="respond" className="comment-respond cs-box_shadow cs-white_bg">
            <form action="#" method="post" id="commentform" className="comment-form tb-comment-form cs-accent_4_bg">
              <div className="cs-form_field_wrap">
                <input type="text" className="cs-form_field" placeholder="Your Name" />
              </div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <div className="cs-form_field_wrap">
                <input type="text" className="cs-form_field" placeholder="Your Email" />
              </div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <div className="cs-form_field_wrap">
                <textarea cols="30" rows="5" placeholder="Message..." className="cs-form_field"></textarea>
              </div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <button className="cs-btn cs-style1 cs-btn_lg"><span>Post Comment</span></button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div className="cs-height_100 cs-height_lg_70"></div>
    <div className="container">
      <h2 className="cs-section_heading cs-style1 text-center">Other Similar Posts</h2>
      <div className="cs-height_45 cs-height_lg_45"></div>
      <div className="row">
        <div className="col-lg-4">
          <div className="cs-post cs-style1">
            <a href="#/blog-details" className="cs-post_thumb">
              <div className="cs-post_thumb_in cs-bg" data-src="/assets/img/general/general_16.jpeg"></div>
            </a>
            <div className="cs-post_info">
              <h2 className="cs-post_title"><a href="#/blog-details">Guide to Making, Buying and Selling Best Patent Token</a></h2>
              <div className="cs-post_subtitle">It is a long established fact that a reader will be
                distrac by the readable content of a page...</div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <div className="cs-post_avatar">
                <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_18.png" alt="Avatr" /></a>
                <div className="cs-post_avatar_right">
                  <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Marina G. Trujillo</a></h2>
                  <div className="cs-post_meta">
                    <span className="cs-post_meta_item">5 Mar 2022</span>
                    <span className="cs-post_meta_item">25 Comments</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        {/* .col */}
        <div className="col-lg-4">
          <div className="cs-post cs-style1">
            <a href="#/blog-details" className="cs-post_thumb">
              <div className="cs-post_thumb_in cs-bg" data-src="/assets/img/general/general_17.jpeg"></div>
            </a>
            <div className="cs-post_info">
              <h2 className="cs-post_title"><a href="#/blog-details">Why do Best Patent Token even make sense?</a></h2>
              <div className="cs-post_subtitle">It is a long established fact that a reader will be
                distrac by the readable content of a page...</div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <div className="cs-post_avatar">
                <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_19.png" alt="Avatr" /></a>
                <div className="cs-post_avatar_right">
                  <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Author</a></h2>
                  <div className="cs-post_meta">
                    <span className="cs-post_meta_item">5 Mar 2022</span>
                    <span className="cs-post_meta_item">25 Comments</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        {/* .col */}
        <div className="col-lg-4">
          <div className="cs-post cs-style1">
            <a href="#/blog-details" className="cs-post_thumb">
              <div className="cs-post_thumb_in cs-bg" data-src="/assets/img/general/general_18.jpeg"></div>
            </a>
            <div className="cs-post_info">
              <h2 className="cs-post_title"><a href="#/blog-details">Why do Best Patent Token even make sense?</a></h2>
              <div className="cs-post_subtitle">It is a long established fact that a reader will be
                distrac by the readable content of a page...</div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <div className="cs-post_avatar">
                <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_19.png" alt="Avatr" /></a>
                <div className="cs-post_avatar_right">
                  <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Author</a></h2>
                  <div className="cs-post_meta">
                    <span className="cs-post_meta_item">5 Mar 2022</span>
                    <span className="cs-post_meta_item">25 Comments</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        {/* .col */}
      </div>
    </div>
    <div className="cs-height_65 cs-height_lg_35"></div>




  </>);
}