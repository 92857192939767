export default function BlogWithSidebar() {
  return (<>



    {/* End Header Section */}

    <div className="cs-height_90 cs-height_lg_80"></div>
    {/* Start Page Head */}
    <section className="cs-page_head cs-bg" data-src="/assets/img/page_head_bg.svg">
      <div className="container">
        <div className="text-center">
          <h1 className="cs-page_title">Our Blog</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#/index">홈</a></li>
            <li className="breadcrumb-item active">Our Blog</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Page Head */}

    <div className="cs-height_100 cs-height_lg_70"></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <div className="row">
            <div className="col-lg-12">
              <div className="cs-post cs-style1 cs-type1 cs-size_md">
                <a href="#/blog-details" className="cs-post_thumb">
                  <div className="cs-post_thumb_in cs-bg" data-src="/assets/img/general/general_22.jpeg"></div>
                </a>
                <div className="cs-post_info">
                  <h2 className="cs-post_title"><a href="#/blog-details">Why XYZ $3M Dune Best Patent Token Mistake Proves Intellectual Property’s Dominion in Digital Fine Art</a></h2>
                  <div className="cs-post_subtitle">It is a long established fact that a reader will be distrac by the readable content of a page when looking at its layout. The point of using Lorem Ipm i has a more-ornormal distribution of letters,as opposed It is a long established fact that a reader will be distrac by the readable content of a page when looking at its layout. The point of using Lorem Ipm i has a </div>
                  <div className="cs-height_20 cs-height_lg_20"></div>
                  <div className="cs-post_avatar">
                    <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_18.png" alt="Avatr" /></a>
                    <div className="cs-post_avatar_right">
                      <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Marina G. Trujillo</a></h2>
                      <div className="cs-post_meta">
                        <span className="cs-post_meta_item">5 Mar 2022</span>
                        <span className="cs-post_meta_item">25 Comments</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>
            {/* .col */}
            <div className="col-lg-6">
              <div className="cs-post cs-style1">
                <a href="#/blog-details" className="cs-post_thumb">
                  <div className="cs-post_thumb_in cs-bg" data-src="/assets/img/general/general_16.jpeg"></div>
                </a>
                <div className="cs-post_info">
                  <h2 className="cs-post_title"><a href="#/blog-details">Guide to Making, Buying and Selling Best Patent Token</a></h2>
                  <div className="cs-post_subtitle">It is a long established fact that a reader will be
                    distrac by the readable content of a page...</div>
                  <div className="cs-height_20 cs-height_lg_20"></div>
                  <div className="cs-post_avatar">
                    <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_18.png" alt="Avatr" /></a>
                    <div className="cs-post_avatar_right">
                      <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Marina G. Trujillo</a></h2>
                      <div className="cs-post_meta">
                        <span className="cs-post_meta_item">5 Mar 2022</span>
                        <span className="cs-post_meta_item">25 Comments</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>
            {/* .col */}
            <div className="col-lg-6">
              <div className="cs-post cs-style1">
                <a href="#/blog-details" className="cs-post_thumb">
                  <div className="cs-post_thumb_in cs-bg" data-src="/assets/img/general/general_17.jpeg"></div>
                </a>
                <div className="cs-post_info">
                  <h2 className="cs-post_title"><a href="#/blog-details">Why do Best Patent Token even make sense?</a></h2>
                  <div className="cs-post_subtitle">It is a long established fact that a reader will be
                    distrac by the readable content of a page...</div>
                  <div className="cs-height_20 cs-height_lg_20"></div>
                  <div className="cs-post_avatar">
                    <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_19.png" alt="Avatr" /></a>
                    <div className="cs-post_avatar_right">
                      <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Author</a></h2>
                      <div className="cs-post_meta">
                        <span className="cs-post_meta_item">5 Mar 2022</span>
                        <span className="cs-post_meta_item">25 Comments</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>
            {/* .col */}
            <div className="col-lg-6">
              <div className="cs-post cs-style1">
                <a href="#/blog-details" className="cs-post_thumb">
                  <div className="cs-post_thumb_in cs-bg" data-src="/assets/img/general/general_18.jpeg"></div>
                </a>
                <div className="cs-post_info">
                  <h2 className="cs-post_title"><a href="#/blog-details">Why do Best Patent Token even make sense?</a></h2>
                  <div className="cs-post_subtitle">It is a long established fact that a reader will be
                    distrac by the readable content of a page...</div>
                  <div className="cs-height_20 cs-height_lg_20"></div>
                  <div className="cs-post_avatar">
                    <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_19.png" alt="Avatr" /></a>
                    <div className="cs-post_avatar_right">
                      <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Author</a></h2>
                      <div className="cs-post_meta">
                        <span className="cs-post_meta_item">5 Mar 2022</span>
                        <span className="cs-post_meta_item">25 Comments</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>
            {/* .col */}
            <div className="col-lg-6">
              <div className="cs-post cs-style1">
                <a href="#/blog-details" className="cs-post_thumb">
                  <div className="cs-post_thumb_in cs-bg" data-src="/assets/img/general/general_28.jpeg"></div>
                </a>
                <div className="cs-post_info">
                  <h2 className="cs-post_title"><a href="#/blog-details">Guide to Making, Buying and Selling Best Patent Token</a></h2>
                  <div className="cs-post_subtitle">It is a long established fact that a reader will be
                    distrac by the readable content of a page...</div>
                  <div className="cs-height_20 cs-height_lg_20"></div>
                  <div className="cs-post_avatar">
                    <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_19.png" alt="Avatr" /></a>
                    <div className="cs-post_avatar_right">
                      <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Marina G. Trujillo</a></h2>
                      <div className="cs-post_meta">
                        <span className="cs-post_meta_item">5 Mar 2022</span>
                        <span className="cs-post_meta_item">25 Comments</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cs-height_30 cs-height_lg_30"></div>
            </div>
            {/* .col */}
          </div>
          <div className="cs-height_10 cs-height_lg_10"></div>
          <div className="text-center">
            <a href="#" className="cs-btn cs-style1 cs-btn_lg"><span>더 보기</span></a>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="cs-height_0 cs-height_lg_70"></div>
          <div className="cs-blog_sidebar">
            <div className="cs-sidebar_widget cs-box_shadow cs-white_bg">
              <form action="#" className="cs-search">
                <input type="text" className="cs-search_input" placeholder="검색" />
                <button className="cs-btn cs-style1">
                  <span>검색</span>
                </button>
              </form>
            </div>
            <div className="cs-sidebar_widget cs-box_shadow cs-white_bg">
              <h2 className="cs-widget_title"><span>Catagory</span></h2>
              <ul className="cs-widget_list">
                <li>
                  <a href="#">Art</a>
                  <span>(5)</span>
                </li>
                <li>
                  <a href="#">Fashion</a>
                  <span>(3)</span>
                </li>
                <li>
                  <a href="#">Music</a>
                  <span>(5)</span>
                </li>
                <li>
                  <a href="#">Video</a>
                  <span>(4)</span>
                </li>
                <li>
                  <a href="#">Games</a>
                  <span>(5)</span>
                </li>
                <li>
                  <a href="#">Collectivites</a>
                  <span>(2)</span>
                </li>
              </ul>
            </div>
            <div className="cs-sidebar_widget cs-box_shadow cs-white_bg">
              <h2 className="cs-widget_title"><span>Recent Post</span></h2>
              <ul className="cs-recent_post_widget">
                <li>
                  <div className="cs-recent_post">
                    <h3 className="cs-post_title"><a href="#/blog-details">Why do Best Patent Token even make sense?</a></h3>
                    <div className="cs-posted_by">1 Mar 2022</div>
                  </div>
                </li>
                <li>
                  <div className="cs-recent_post">
                    <h3 className="cs-post_title"><a href="#/blog-details">Why do Best Patent Token even make sense?</a></h3>
                    <div className="cs-posted_by">21 Feb 2022</div>
                  </div>
                </li>
                <li>
                  <div className="cs-recent_post">
                    <h3 className="cs-post_title"><a href="#/blog-details">What does Best Patent Token mean in crypto?</a></h3>
                    <div className="cs-posted_by">14 Feb 2022</div>
                  </div>
                </li>
                <li>
                  <div className="cs-recent_post">
                    <h3 className="cs-post_title"><a href="#/blog-details">How much does it cost to sell Best Patent Token?</a></h3>
                    <div className="cs-posted_by">8 Feb 2022</div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="cs-sidebar_widget cs-box_shadow cs-white_bg">
              <h2 className="cs-widget_title"><span>Popular Tags</span></h2>
              <div className="cs-tag_widget">
                <a href="#">Best Patent Token Art</a>
                <a href="#">Music</a>
                <a href="#">Video</a>
                <a href="#">ETH</a>
                <a href="#">Btc</a>
                <a href="#">Utility</a>
                <a href="#">Fashion</a>
                <a href="#">Bitcoin</a>
                <a href="#">Mint</a>
                <a href="#">Design</a>
                <a href="#">Best Patent Token</a>
                <a href="#">Crypto</a>
              </div>
            </div>
            <div className="cs-sidebar_widget cs-box_shadow cs-white_bg">
              <h2 className="cs-widget_title"><span>Social Share</span></h2>
              <div className="cs-social_widget">
                <a href="#"><i className="fab fa-facebook-f"></i></a>
                <a href="#"><i className="fab fa-twitter"></i></a>
                <a href="#"><i className="fab fa-linkedin-in"></i></a>
                <a href="#"><i className="fab fa-instagram"></i></a>
                <a href="#"><i className="fab fa-youtube"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="cs-height_100 cs-height_lg_70"></div>




  </>);
}