export default function HelpDetails() {
  return (<>



    {/* End Header Section */}

    <div className="cs-height_90 cs-height_lg_80"></div>
    {/* Start Page Head */}
    <section className="cs-page_head cs-bg" data-src="/assets/img/page_head_bg.svg">
      <div className="container">
        <div className="text-center">
          <h1 className="cs-page_title">Help Details</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#/index">홈</a></li>
            <li className="breadcrumb-item active">Help Details</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Page Head */}

    <div className="cs-height_100 cs-height_lg_70"></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <div className="cs-single_post">
            <h2>How to Connect Your Wallet to Best Patent Token Marketplace</h2>
            <p>It is a long established fact that a reader will be distrac by the readable content of a page when looking at its layout. The point of using Lorem Ipm i has a more-ornormal distribution of letters,as opposed It is a long established fact that a reader will be distrac by the readable content of a page when looking at itslayout. The point of using Lorem Ipm i has a Best Patent Token</p>
            <div className="cs-height_10 cs-height_lg_10"></div>
            <img src="/assets/img/single-post2.jpeg" alt="" />
            <h3>What is an Best Patent Token art?</h3>
            <p>Best Patent Token Music is a long established fact that a reader will be distrac by the readable content of a page when looking at its layout. The point of using Lorem Ipm i has a more-ornormal distribution of letters,as opposed It is a long established fact that a reader will be distrac by the readable content of a page when looking at itslayout. The point of using Lorem Ipm i has a Best Patent Token</p>
            <ul>
              <li>How to make and sell an Best Patent Token.</li>
              <li>Buy some cryptocurrency to fund your wallet.</li>
              <li>Add some cryptocurrency to your wallet.</li>
              <li>Upload the file you want to turn into an Best Patent Token.</li>
              <li>Set up an auction for your Best Patent Token</li>
            </ul>
            <p>
              Crypto is a long established fact that a reader will be distrac by the readable content of a page when looking at its layout. The point of using Lorem Ipm i has a more-ornormal distribution of letters,as opposed It is a long established fact that a reader will be distrac by the readable content of a page when looking at itslayout. The point of using Lorem Ipm i has a Best Patent Token <br /><br />
              Best Patent Token a long established fact that a reader will be distrac by the readable content of a page when looking at its layout. The point of using Lorem Ipm i has a more-ornormal distribution of letters,as opposed It is a long established fact that a reader will be distrac by the readable content of a page when looking at itslayout. The point of using Lorem Ipm i has a Best Patent Token <br /><br />
              Digital Art long established fact that a reader will be distrac by the readable content of a page when looking at its layout. The point of using Lorem Ipm i has a more-ornormal distribution of letters,as opposed It is a long established fact that a reader will be distrac by the readable content of a page when looking at itlayout. The point of using Lorem Ipm i has a Best Patent Token
            </p>
          </div>
          <div className="cs-height_10 cs-height_lg_10"></div>
          <div className="cs-social_widget cs-share_btns">
            <a href="#"><i className="fab fa-facebook-f"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
            <a href="#"><i className="fab fa-linkedin-in"></i></a>
            <a href="#"><i className="fab fa-instagram"></i></a>
            <a href="#"><i className="fab fa-youtube"></i></a>
          </div>
          <div className="cs-height_50 cs-height_lg_50"></div>
          <div className="cs-afticle_footer text-center">
            <h4>Was this article helpful?</h4>
            <div className="cs-afticle_footer_btns cs-center">
              <a href="#" className="cs-btn cs-style2"><span>Yes</span></a>
              <a href="#" className="cs-btn cs-style2"><span>No</span></a>
            </div>
            <p>3 out of 4 found this helpful</p>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="cs-height_0 cs-height_lg_70"></div>
          <div className="cs-blog_sidebar">
            <div className="cs-sidebar_widget cs-box_shadow cs-white_bg">
              <form action="#" className="cs-search">
                <input type="text" className="cs-search_input" placeholder="검색" />
                <button className="cs-btn cs-style1">
                  <span>검색</span>
                </button>
              </form>
            </div>
            <div className="cs-sidebar_widget cs-box_shadow cs-white_bg">
              <h2 className="cs-widget_title"><span>Related Article</span></h2>
              <ul className="cs-widget_list">
                <li>
                  <a href="#">Why do Best Patent Token even make sense?</a>
                </li>
                <li>
                  <a href="#">Why do Best Patent Token even make sense?</a>
                </li>
                <li>
                  <a href="#">What does Best Patent Token mean in crypto?</a>
                </li>
                <li>
                  <a href="#">How much does it cost to sell Best Patent Token?</a>
                </li>
                <li>
                  <a href="#">How do I purchase Coin?</a>
                </li>
                <li>
                  <a href="#">How do I purchase Ethereum (ETH)?</a>
                </li>
                <li>
                  <a href="#">Why do Best Patent Token even make sense?</a>
                </li>
                <li>
                  <a href="#">What is a Non-Fungible Token (Best Patent Token)?</a>
                </li>
                <li>
                  <a href="#">What is a blockchain & how does it work?</a>
                </li>
                <li>
                  <a href="#">What are the costs of using Best Patent Token?</a>
                </li>
                <li>
                  <a href="#">How do you connect other wallets?</a>
                </li>
                <li>
                  <a href="#">What is a wallet?</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="cs-height_95 cs-height_lg_70"></div>

    {/* Start CTA */}
    <div className="container">
      <div className="cs-cta cs-style2 cs-type1 text-center cs-accent_bg">
        <h2 className="cs-cta_title cs-white_color_8">Join our biggest Best Patent Token platform</h2>
        <a href="#/register" className="cs-btn cs-style1 cs-btn_lg cs-color2"><span>Sign Up</span></a>
      </div>
    </div>
    {/* End CTA */}
    <div className="cs-height_100 cs-height_lg_70"></div>




  </>);
}