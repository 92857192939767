import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import http from "../http";
import { getTokenURI } from "../utils/ethNft.js";
import axios from "axios";
export default function Explore1() {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const title = query.get("title");
  let [data, setData] = useState([]);
  useEffect(() => {
    http
      .getnft("/bpt-nft/nft_list")
      .then(async (_result) => {
        try {
          // 使用 Promise.all 来等待所有异步操作完成
          const data = await Promise.all(
            window._.map(_result.list, async (_item) => {
              // 获取 token URI
              const _nft = await getTokenURI(
                _item.contract_address,
                _item.token_id
              );
              // 获取 JSON 数据
              const response = await axios.get(_nft.tokenURI);
              if (!response.data) {
                throw new Error(`Failed to fetch ${_nft}`);
              }
              return {
                ..._item,
                ..._nft,
                ...response.data,
              };
            })
          );

          // 现在 data 是解析后的结果数组
          setData(data);
          console.log(data);
        } catch (error) {
          console.error("Error fetching NFTs:", error);
        }
      })
      .catch(() => { });
  }, []);

  return (
    <>
      {/* End Header Section */}

      <div className="cs-height_90 cs-height_lg_80"></div>
      {/* Start Page Head */}
      <section
        className="cs-page_head cs-bg"
        data-src="/assets/img/page_head_bg.svg"
      >
        <div className="container">
          <div className="text-center">
            <h1 className="cs-page_title">{title}</h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#/index">홈</a>
              </li>
              <li className="breadcrumb-item active">{title}</li>
            </ol>
          </div>
        </div>
      </section>
      {/* End Page Head */}
      <div className="cs-height_100 cs-height_lg_70"></div>
      <div className="container">
        <div className="cs-sidebar_frame cs-style1">
          <div className="cs-sidebar_frame_left">
            <div className="cs-filter_sidebar">
              <div className="cs-search_widget">
                <form action="#" className="cs-search">
                  <input
                    type="text"
                    className="cs-search_input"
                    placeholder="검색"
                  />
                  <button className="cs-search_btn">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.16667 16.3333C12.8486 16.3333 15.8333 13.3486 15.8333 9.66667C15.8333 5.98477 12.8486 3 9.16667 3C5.48477 3 2.5 5.98477 2.5 9.66667C2.5 13.3486 5.48477 16.3333 9.16667 16.3333Z"
                        stroke="currentColor"
                        strokeOpacity="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.5 18L13.875 14.375"
                        stroke="currentColor"
                        strokeOpacity="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </form>
              </div>
              <div className="cs-filter_widget">
                <h2 className="cs-filter_toggle_btn">
                  Catagory
                  <span className="cs-arrow_icon">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.679688 0.96582L4.67969 4.96582L8.67969 0.96582"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </h2>
                <div className="cs-filter_toggle_body">
                  <ul>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="category"
                          id="Art"
                          defaultChecked={true}
                        />
                        <label className="form-check-label" htmlFor="Art">
                          Art
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="category"
                          id="Fashion"
                        />
                        <label className="form-check-label" htmlFor="Fashion">
                          Fashion
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="category"
                          id="Music"
                        />
                        <label className="form-check-label" htmlFor="Music">
                          Music
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="category"
                          id="Video"
                        />
                        <label className="form-check-label" htmlFor="Video">
                          Video
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="category"
                          id="Games"
                        />
                        <label className="form-check-label" htmlFor="Games">
                          Games
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="category"
                          id="Sports"
                        />
                        <label className="form-check-label" htmlFor="Sports">
                          Sports
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="category"
                          id="Collectibles"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Collectibles"
                        >
                          Collectibles
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>{" "}
              {/* .cs-filter_widget */}
              <div className="cs-filter_widget">
                <h2 className="cs-filter_toggle_btn">
                상태
                  <span className="cs-arrow_icon">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.679688 0.96582L4.67969 4.96582L8.67969 0.96582"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </h2>
                <div className="cs-filter_toggle_body">
                  <ul>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckChecked"
                          defaultChecked={true}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          Buy Now
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          경매 중
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault2"
                        >
                          Looking to Sell
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault3"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault3"
                        >
                          Has Offers
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>{" "}
              {/* .cs-filter_widget */}
              <div className="cs-filter_widget">
                <h2 className="cs-filter_toggle_btn">
                가격
                  <span className="cs-arrow_icon">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.679688 0.96582L4.67969 4.96582L8.67969 0.96582"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </h2>
                <div className="cs-filter_toggle_body">
                  <div className="cs-price_form">
                    <form className="row row-15">
                      <div className="col-lg-12">
                        <div className="cs-form_field_wrap cs-select_arrow">
                          <select
                            className="cs-form_field cs-field_sm"
                            disabled
                          >
                            <option value="BTC">BTC</option>
                            <option value="BTC">BTC</option>
                            <option value="ETH">ETH</option>
                            <option value="ADA">ADA</option>
                          </select>
                        </div>
                        <div className="cs-height_15 cs-height_lg_15"></div>
                      </div>
                      <div className="col-lg-6">
                        <div className="cs-form_field_wrap">
                          <input
                            disabled
                            type="text"
                            className="cs-form_field cs-field_sm"
                            placeholder="Min"
                          />
                        </div>
                        <div className="cs-height_15 cs-height_lg_15"></div>
                      </div>
                      <div className="col-lg-6">
                        <div className="cs-form_field_wrap">
                          <input
                            disabled
                            type="text"
                            className="cs-form_field cs-field_sm"
                            placeholder="Max"
                          />
                        </div>
                        <div className="cs-height_10 cs-height_lg_10"></div>
                      </div>
                      <div className="col-lg-6">
                        <input
                          disabled
                          type="reset"
                          className="cs-btn cs-style1 cs-color1 cs-btn_sm"
                          value="Clear"
                        />
                      </div>
                      <div className="col-lg-6">
                        <button disabled className="cs-btn cs-style1 cs-btn_sm">
                          <span>Apply</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>{" "}
              {/* .cs-filter_widget */}
              <div className="cs-filter_widget">
                <h2 className="cs-filter_toggle_btn">
                컬렉션
                  <span className="cs-arrow_icon">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.679688 0.96582L4.67969 4.96582L8.67969 0.96582"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </h2>
                <div className="cs-filter_toggle_body">
                  <ul>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="collection"
                          id="Audioglyphs"
                          defaultChecked={true}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Audioglyphs"
                        >
                          Audioglyphs
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="collection"
                          id="Autoglyphs"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Autoglyphs"
                        >
                          Autoglyphs
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="collection"
                          id="CryptoCrystal"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="CryptoCrystal"
                        >
                          CryptoCrystal
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="collection"
                          id="CryptoArte"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="CryptoArte"
                        >
                          CryptoArte
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="collection"
                          id="CyberKongz"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="CyberKongz"
                        >
                          CyberKongz
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* .cs-filter_widget */}
              {/* <div className="cs-filter_widget">
                  <h2 className="cs-filter_toggle_btn">
                    Filter By Color
                    <span className="cs-arrow_icon">
                      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.679688 0.96582L4.67969 4.96582L8.67969 0.96582" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>                  
                    </span>
                  </h2>
                  <div className="cs-filter_toggle_body">
                    <div className="cs-filter_by_color">
                      <div className="cs-color_item cs-color1"></div>
                      <div className="cs-color_item cs-color2"></div>
                      <div className="cs-color_item cs-color3"></div>
                      <div className="cs-color_item cs-color4"></div>
                      <div className="cs-color_item cs-color5"></div>
                      <div className="cs-color_item cs-color6"></div>
                      <div className="cs-color_item cs-color7"></div>
                      <div className="cs-color_item cs-color8"></div>
                    </div>
                  </div>
                </div>  */}
              {/* .cs-filter_widget */}
            </div>
          </div>
          <div className="cs-sidebar_frame_right">
            <div className="cs-filter_head">
              <div className="cs-filter_head_left">
                <span className="cs-search_result cs-medium cs-ternary_color">
                  29064886 Results
                </span>
                <div className="cs-form_field_wrap">
                  <input
                    type="text"
                    className="cs-form_field cs-field_sm"
                    placeholder="경매 중"
                  />
                </div>
                <a href="#" className="cs-clear_btn">
                모두 지우기
                </a>
              </div>
              <div className="cs-filter_head_right">
                <div className="cs-form_field_wrap cs-select_arrow">
                  <select className="cs-form_field cs-field_sm">
                    <option value="11">Sort By</option>
                    <option value="22">Last 7 days</option>
                    <option value="33">Last 30 days</option>
                    <option value="44">All time</option>
                  </select>
                </div>
                <div className="cs-form_field_wrap cs-select_arrow">
                  <select className="cs-form_field cs-field_sm">
                    <option value="1">Likes</option>
                    <option value="2">Most popular</option>
                    <option value="3">By price</option>
                    <option value="4">By published</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="cs-height_30 cs-height_lg_30"></div>
            <div className="row">
              {data.map((item) => (
                <div className="col-xl-3 col-lg-4 col-sm-6" key={item.id}>
                  <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                    <span className="cs-card_like cs-primary_color">
                      <i className="fas fa-heart fa-fw"></i>
                      2.1K
                    </span>
                    <a
                      href="#/explore-details"
                      className="cs-card_thumb cs-zoom_effect"
                    >
                      <img
                        src={item.image}
                        alt="Image"
                        className="cs-zoom_item"
                      />
                    </a>
                    <div className="cs-card_info">
                      <a href="#" className="cs-avatar cs-white_bg">
                        <img
                          src="/assets/img/avatar/avatar_12.png"
                          alt="Avatar"
                        />
                        <span>Johny E.</span>
                      </a>
                      <h3 className="cs-card_title">
                        <a href="#/explore-details">{item.name}</a>
                      </h3>
                      <div className="cs-card_price">
                      가격: <b className="cs-primary_color">0.29 NFT 7/21</b>
                      </div>
                      <hr />
                      <div className="cs-card_footer">
                        <span className="cs-card_btn_1" data-modal="#history_1">
                          {/* <i className="fas fa-redo fa-fw"></i>
                      View History */}
                        </span>
                        <a
                          href="#/explore-details"
                          className="cs-card_btn_2"
                          data-params={JSON.stringify(item)}
                        >
                          <span>구매</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="cs-height_30 cs-height_lg_30"></div>
                </div>
              ))}
            </div>
            <div className="cs-height_10 cs-height_lg_10"></div>
            <div className="text-center">
              <a href="#" className="cs-btn cs-style1 cs-btn_lg">
                <span>더 보기</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
