export default () => (
  <>
    <b>⭘ Launch event</b>
    <p>
    We are looking for innovative and creative ideas on reuse and recycling of used electric vehicle batteries!
    </p>
    <b>⭘ Schedule</b>
    <ul>
      <li>
        <b> Application reception: </b>September 1, 2024 ~ November 30, 2024 (3 month period)

      </li>
      <li>
        <b>Announcement of results: </b>December 20, 2024 (Friday)
      </li>
    </ul>

    <b>⭘ Contest Topic</b>
    <p>- Ideas for reusing waste batteries</p>
    <ul>
      <li>Development of new products using waste batteries</li>
      <li>Improve performance by applying waste batteries to existing products</li>
    </ul>
    <p>- Waste battery recycling ideas</p>
    <ul>
      <li>Development of efficient technology for recycling waste batteries
</li>
      <li> How to solve problems that arise during the recycling process</li>
    </ul>
    <b>⭘ How to apply</b>
    <p>- Sign up as a member on the BPT platform
</p>
    <p>- Fill out the basic idea information, attach the file, and submit.
</p>
    <b>⭘ Selection method</b>
    <p>- Written evaluation by patent experts</p>
    <p>- Selection method</p>
    <table>
      <thead className="cs-gray_bg ">
        <tr>
          <th>Indicators
</th>
          <th>Score</th>
          <th>Desctiption</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Creativity</td>
          <td>30</td>
          <td>Is it an original and innovative idea?</td>
        </tr>
        <tr>
          <td>Feasibility</td>
          <td>30</td>
          <td>Is it technically and economically feasible?
</td>
        </tr>
        <tr>
          <td>Sustainability</td>
          <td>30</td>
          <td>Does it have a positive impact on the environment?</td>
        </tr>
        <tr>
          <td>Social influence</td>
          <td>10</td>
          <td>Is there a significant positive effect on society?
</td>
        </tr>
      </tbody>
    </table>
    <b>⭘ Awards details</b>
    <p></p>
    <table>
      <thead className="cs-gray_bg">
        <tr>
          <th>reward</th>
          <th>Award scale
</th>
          <th>Award benefits
</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Best grand prize</td>
          <td>1person</td>
          <td>1,000BPT payment,
AI patent specification usage rights
</td>
        </tr>
        <tr>
          <td>Grand Prize</td>
          <td>1person</td>
          <td>800BPT payment</td>
        </tr>
        <tr>
          <td>Excellence Award</td>
          <td>2person</td>
          <td>500BPT payment</td>
        </tr>
        <tr>
          <td>Encouragement Award</td>
          <td>3person</td>
          <td>300BPT payment</td>
        </tr>
      </tbody>
    </table>
    <p>⭘ Contac: patentnft@patent-nft.com</p>
    <p>⭘ Other information</p>
    <p>- If there is no suitable idea, it may not be selected.</p>
    <p>
      - Ownership of submitted ideas belongs to the submitter. However, if you submit an idea as part of a company, please be careful to ensure that there are no ownership issues between the company and the submitter.
    </p>
    <p>
      - The winning work may be put to practical use at the discretion of the organizer, and additional cooperation may be required in this process.
    </p>
    <p>
      - Participants agree to all terms and conditions of this contest upon application.
    </p>
  </>
);
