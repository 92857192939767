import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translation_en from './en.json';
import store from '../store';
import translation_ko from './ko.json';

let language=store.getState().language
const resources = {
    en: {
        translation: translation_en,
    },
    ko: {
        translation: translation_ko,
    }
};
 
i18n.use(initReactI18next).init({
    resources,
    // 默认语言  zh/en  中文/英文
    lng: language,
    interpolation: {
        escapeValue: false,
    },
});
 
export default i18n;