export default function Blog() {
  return (<>



    {/* End Header Section */}

    <div className="cs-height_90 cs-height_lg_80"></div>
    {/* Start Page Head */}
    <section className="cs-page_head cs-bg" data-src="/assets/img/page_head_bg.svg">
      <div className="container">
        <div className="text-center">
          <h1 className="cs-page_title">Our Blog</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#/index">홈</a></li>
            <li className="breadcrumb-item active">Our Blog</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Page Head */}

    <div className="cs-height_100 cs-height_lg_70"></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="cs-post cs-style1 cs-type1">
            <a href="#/blog-details" className="cs-post_thumb">
              <div className="cs-post_thumb_in cs-bg" data-src="/assets/img/general/general_22.jpeg"></div>
            </a>
            <div className="cs-post_info">
              <h2 className="cs-post_title"><a href="#/blog-details">Why XYZ $3M Dune Best Patent Token Mistake Proves Intellectual Property’s Dominion in Digital Fine Art</a></h2>
              <div className="cs-post_subtitle">It is a long established fact that a reader will be distrac by the readable content of a page when looking at its layout. The point of using Lorem Ipm i has a more-ornormal distribution of letters,as opposed It is a long established fact that a reader will be distrac by the readable content of a page when looking at its layout. The point of using Lorem Ipm i has a </div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <div className="cs-post_avatar">
                <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_18.png" alt="Avatr" /></a>
                <div className="cs-post_avatar_right">
                  <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Marina G. Trujillo</a></h2>
                  <div className="cs-post_meta">
                    <span className="cs-post_meta_item">5 Mar 2022</span>
                    <span className="cs-post_meta_item">25 Comments</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        {/* .col */}
        <div className="col-lg-4 col-md-6">
          <div className="cs-post cs-style1">
            <a href="#/blog-details" className="cs-post_thumb">
              <div className="cs-post_thumb_in cs-bg" data-src="/assets/img/general/general_16.jpeg"></div>
            </a>
            <div className="cs-post_info">
              <h2 className="cs-post_title"><a href="#/blog-details">Guide to Making, Buying and Selling Best Patent Token</a></h2>
              <div className="cs-post_subtitle">It is a long established fact that a reader will be
                distrac by the readable content of a page...</div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <div className="cs-post_avatar">
                <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_18.png" alt="Avatr" /></a>
                <div className="cs-post_avatar_right">
                  <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Marina G. Trujillo</a></h2>
                  <div className="cs-post_meta">
                    <span className="cs-post_meta_item">5 Mar 2022</span>
                    <span className="cs-post_meta_item">25 Comments</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        {/* .col */}
        <div className="col-lg-4 col-md-6">
          <div className="cs-post cs-style1">
            <a href="#/blog-details" className="cs-post_thumb">
              <div className="cs-post_thumb_in cs-bg" data-src="/assets/img/general/general_17.jpeg"></div>
            </a>
            <div className="cs-post_info">
              <h2 className="cs-post_title"><a href="#/blog-details">Why do Best Patent Token even make sense?</a></h2>
              <div className="cs-post_subtitle">It is a long established fact that a reader will be
                distrac by the readable content of a page...</div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <div className="cs-post_avatar">
                <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_19.png" alt="Avatr" /></a>
                <div className="cs-post_avatar_right">
                  <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Author</a></h2>
                  <div className="cs-post_meta">
                    <span className="cs-post_meta_item">5 Mar 2022</span>
                    <span className="cs-post_meta_item">25 Comments</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        {/* .col */}
        <div className="col-lg-4 col-md-6">
          <div className="cs-post cs-style1">
            <a href="#/blog-details" className="cs-post_thumb">
              <div className="cs-post_thumb_in cs-bg" data-src="/assets/img/general/general_18.jpeg"></div>
            </a>
            <div className="cs-post_info">
              <h2 className="cs-post_title"><a href="#/blog-details">Why do Best Patent Token even make sense?</a></h2>
              <div className="cs-post_subtitle">It is a long established fact that a reader will be
                distrac by the readable content of a page...</div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <div className="cs-post_avatar">
                <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_19.png" alt="Avatr" /></a>
                <div className="cs-post_avatar_right">
                  <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Author</a></h2>
                  <div className="cs-post_meta">
                    <span className="cs-post_meta_item">5 Mar 2022</span>
                    <span className="cs-post_meta_item">25 Comments</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        {/* .col */}
        <div className="col-lg-4 col-md-6">
          <div className="cs-post cs-style1">
            <a href="#/blog-details" className="cs-post_thumb">
              <div className="cs-post_thumb_in cs-bg" data-src="/assets/img/general/general_26.jpeg"></div>
            </a>
            <div className="cs-post_info">
              <h2 className="cs-post_title"><a href="#/blog-details">How much does it cost to sell Best Patent Token?</a></h2>
              <div className="cs-post_subtitle">It is a long established fact that a reader will be
                distrac by the readable content of a page...</div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <div className="cs-post_avatar">
                <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_19.png" alt="Avatr" /></a>
                <div className="cs-post_avatar_right">
                  <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Author</a></h2>
                  <div className="cs-post_meta">
                    <span className="cs-post_meta_item">3 Mar 2022</span>
                    <span className="cs-post_meta_item">23 Comments</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        {/* .col */}
        <div className="col-lg-4 col-md-6">
          <div className="cs-post cs-style1">
            <a href="#/blog-details" className="cs-post_thumb">
              <div className="cs-post_thumb_in cs-bg" data-src="/assets/img/general/general_27.jpeg"></div>
            </a>
            <div className="cs-post_info">
              <h2 className="cs-post_title"><a href="#/blog-details">What does Best Patent Token mean in crypto?</a></h2>
              <div className="cs-post_subtitle">It is a long established fact that a reader will be
                distrac by the readable content of a page...</div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <div className="cs-post_avatar">
                <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_19.png" alt="Avatr" /></a>
                <div className="cs-post_avatar_right">
                  <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Author</a></h2>
                  <div className="cs-post_meta">
                    <span className="cs-post_meta_item">2 Mar 2022</span>
                    <span className="cs-post_meta_item">13 Comments</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        {/* .col */}
        <div className="col-lg-4 col-md-6">
          <div className="cs-post cs-style1">
            <a href="#/blog-details" className="cs-post_thumb">
              <div className="cs-post_thumb_in cs-bg" data-src="/assets/img/general/general_28.jpeg"></div>
            </a>
            <div className="cs-post_info">
              <h2 className="cs-post_title"><a href="#/blog-details">What can you do with Best Patent Token?</a></h2>
              <div className="cs-post_subtitle">It is a long established fact that a reader will be
                distrac by the readable content of a page...</div>
              <div className="cs-height_20 cs-height_lg_20"></div>
              <div className="cs-post_avatar">
                <a href="#" className="cs-post_avatar_img"><img src="/assets/img/avatar/avatar_19.png" alt="Avatr" /></a>
                <div className="cs-post_avatar_right">
                  <h2 className="cs-post_avatar_name cs-semi_bold"><a href="#">Author</a></h2>
                  <div className="cs-post_meta">
                    <span className="cs-post_meta_item">1 Mar 2022</span>
                    <span className="cs-post_meta_item">5 Comments</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        {/* .col */}
      </div>
      <div className="cs-height_10 cs-height_lg_10"></div>
      <div className="text-center">
        <a href="#" className="cs-btn cs-style1 cs-btn_lg"><span>더 보기</span></a>
      </div>
    </div>
    <div className="cs-height_100 cs-height_lg_70"></div>




  </>);
}