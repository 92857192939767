import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import http from "../http";
import { getTokenURI } from "../utils/ethNft.js";
import { message } from "antd";
import axios from "axios";
import { useTranslation } from "react-i18next";
import GeneralKo from "../agreement/general-ko";
import GeneralEn from "../agreement/general-en";
import { connect } from "react-redux";
function Explore1(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const title = query.get("title");
  let [data, setData] = useState([]);
  useEffect(() => {
    http
      .getnft(
        `/bpt-nft/nft_list?address=${process.env.REACT_APP_GENERAL}&take=100`
      )
      .then(async (_result) => {
        try {
          // 使用 Promise.all 来等待所有异步操作完成
          const data = await Promise.all(
            window._.map(_result.list, async (_item) => {
              // 获取 token URI
              const _nft = await http
                .getnft(
                  `/bpt-nft/nft_info?token_id=${_item.token_id}&address=${_item.contract_address}`
                )
                .then((res) => {
                  return res.info.nfi_info;
                });
              return {
                ..._item,
                ..._nft,
              };
            })
          );

          // 现在 data 是解析后的结果数组
          setData(data);
          console.log(data);
        } catch (error) {
          console.error("Error fetching NFTs:", error);
        }
      })
      .catch(() => {});
  }, []);
  let toDetail = (item) => {
    if (item.is_lock)
      return message.info(t("general.locked", { name: item.name }));
    localStorage.setItem("detail_" + item.id, JSON.stringify(item));
    navigate("/explore-details?id=" + item.id);
  };

  return (
    <>
      {/* End Header Section */}

      <div className="cs-height_90 cs-height_lg_80"></div>
      {/* Start Page Head */}
      <section
        className="cs-page_head cs-bg"
        data-src="/assets/img/page_head_bg.svg"
      >
        <div className="container">
          <div className="text-center">
            <h1 className="cs-page_title">{t("general.patent_nft")}</h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#/index">{t("general.home")}</a>
              </li>
              <li className="breadcrumb-item active">
                {t("general.patent_nft")}
              </li>
            </ol>
            <p
              dangerouslySetInnerHTML={{ __html: t("general.description") }}
            ></p>
          </div>
        </div>
      </section>
      {/* End Page Head */}
      <div className="cs-height_100 cs-height_lg_70"></div>
      <div className="container">
        <div className="cs-sidebar_frame cs-style1">
          <div className="cs-sidebar_frame_left">
            <div className="cs-filter_sidebar">
              <div className="cs-search_widget">
                <form action="#" className="cs-search">
                  <input
                    type="text"
                    className="cs-search_input"
                    placeholder={t("general.search")}
                  />
                  <button className="cs-search_btn">
                    <svg
                      width="20"
                      height="21"
                      viewBox="0 0 20 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.16667 16.3333C12.8486 16.3333 15.8333 13.3486 15.8333 9.66667C15.8333 5.98477 12.8486 3 9.16667 3C5.48477 3 2.5 5.98477 2.5 9.66667C2.5 13.3486 5.48477 16.3333 9.16667 16.3333Z"
                        stroke="currentColor"
                        strokeOpacity="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M17.5 18L13.875 14.375"
                        stroke="currentColor"
                        strokeOpacity="0.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </form>
              </div>
              <div className="cs-filter_widget">
                <h2 className="cs-filter_toggle_btn">
                  {t("general.category")}
                  <span className="cs-arrow_icon">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.679688 0.96582L4.67969 4.96582L8.67969 0.96582"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </h2>
                <div className="cs-filter_toggle_body">
                  <ul>
                    <li data-modal="#inverter_pop" className="pointer">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1.5em"
                        height="1.5em"
                        viewBox="0 0 48 48"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="4"
                          d="M28 6h14v14m0 9.474V39a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3h9m7.8 16.2L41.1 6.9"
                        />
                      </svg>
                      <span
                        className="form-check-label"
                        style={{ marginLeft: "10px" }}
                        htmlFor="Art"
                      >
                        INVERTER
                      </span>
                    </li>
                  </ul>
                </div>
              </div>{" "}
              {/* .cs-filter_widget */}
              {/* <div className="cs-filter_widget">
                <h2 className="cs-filter_toggle_btn">
                  {t("general.status")}
                  <span className="cs-arrow_icon">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.679688 0.96582L4.67969 4.96582L8.67969 0.96582"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </h2>
                <div className="cs-filter_toggle_body">
                  <ul>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckChecked"
                          defaultChecked={true}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          Buy Now
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault"
                        >
                          {t("general.auction")}
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault2"
                        >
                          Looking to Sell
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault3"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckDefault3"
                        >
                          Has Offers
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>{" "} */}
              {/* .cs-filter_widget */}
              <div className="cs-filter_widget">
                <h2 className="cs-filter_toggle_btn">
                  {t("general.price")}
                  <span className="cs-arrow_icon">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.679688 0.96582L4.67969 4.96582L8.67969 0.96582"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </h2>
                <div className="cs-filter_toggle_body">
                  <div className="cs-price_form">
                    <form className="row row-15">
                      <div className="col-lg-12">
                        <div className="cs-form_field_wrap cs-select_arrow">
                          <select
                            className="cs-form_field cs-field_sm"
                            disabled
                          >
                            <option value="BTC">BPT</option>
                            <option value="BTC">BTC</option>
                            <option value="ETH">ETH</option>
                            <option value="ADA">ADA</option>
                          </select>
                        </div>
                        <div className="cs-height_15 cs-height_lg_15"></div>
                      </div>
                      <div className="col-lg-6">
                        <div className="cs-form_field_wrap">
                          <input
                            disabled
                            type="text"
                            className="cs-form_field cs-field_sm"
                            placeholder="Min"
                          />
                        </div>
                        <div className="cs-height_15 cs-height_lg_15"></div>
                      </div>
                      <div className="col-lg-6">
                        <div className="cs-form_field_wrap">
                          <input
                            disabled
                            type="text"
                            className="cs-form_field cs-field_sm"
                            placeholder="Max"
                          />
                        </div>
                        <div className="cs-height_10 cs-height_lg_10"></div>
                      </div>
                      <div className="col-lg-6">
                        <input
                          disabled
                          type="reset"
                          className="cs-btn cs-style1 cs-color1 cs-btn_sm"
                          value="Clear"
                        />
                      </div>
                      <div className="col-lg-6">
                        <button disabled className="cs-btn cs-style1 cs-btn_sm">
                          <span>Apply</span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>{" "}
              {/* .cs-filter_widget */}
              <div className="cs-filter_widget">
                <h2 className="cs-filter_toggle_btn">
                  {t("general.collection")}
                  <span className="cs-arrow_icon">
                    <svg
                      width="10"
                      height="6"
                      viewBox="0 0 10 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.679688 0.96582L4.67969 4.96582L8.67969 0.96582"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </h2>
                <div className="cs-filter_toggle_body">
                  <ul>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="collection"
                          id="Audioglyphs"
                          defaultChecked={true}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Audioglyphs"
                        >
                          {t("bigtech.ImageArtPatent")}
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="collection"
                          id="Autoglyphs"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="Autoglyphs"
                        >
                          {t("bigtech.TradingPatent")}
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="collection"
                          id="CryptoCrystal"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="CryptoCrystal"
                        >
                          {t("bigtech.IdeaPatent")}
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="collection"
                          id="CryptoArte"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="CryptoArte"
                        >
                          {t("bigtech.VideoArtPatent")}
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="form-check">
                        <input
                          disabled
                          className="form-check-input"
                          type="radio"
                          name="collection"
                          id="CyberKongz"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="CyberKongz"
                        >
                          {t("bigtech.AudioArtPatent")}
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              {/* .cs-filter_widget */}
              {/* <div className="cs-filter_widget">
                  <h2 className="cs-filter_toggle_btn">
                    Filter By Color
                    <span className="cs-arrow_icon">
                      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.679688 0.96582L4.67969 4.96582L8.67969 0.96582" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>                  
                    </span>
                  </h2>
                  <div className="cs-filter_toggle_body">
                    <div className="cs-filter_by_color">
                      <div className="cs-color_item cs-color1"></div>
                      <div className="cs-color_item cs-color2"></div>
                      <div className="cs-color_item cs-color3"></div>
                      <div className="cs-color_item cs-color4"></div>
                      <div className="cs-color_item cs-color5"></div>
                      <div className="cs-color_item cs-color6"></div>
                      <div className="cs-color_item cs-color7"></div>
                      <div className="cs-color_item cs-color8"></div>
                    </div>
                  </div>
                </div>  */}
              {/* .cs-filter_widget */}
            </div>
          </div>
          <div className="cs-sidebar_frame_right">
            <div className="cs-filter_head">
              <div className="cs-filter_head_left">
                {/* <span className="cs-search_result cs-medium cs-ternary_color">
                  29064886 Results
                </span>
                <div className="cs-form_field_wrap">
                  <input
                    type="text"
                    className="cs-form_field cs-field_sm"
                    placeholder="{t("general.auction")}"
                  />
                </div>
                <a href="#" className="cs-clear_btn">
                  모두 지우기
                </a> */}
              </div>
              <div className="cs-filter_head_right">
                <div className="cs-form_field_wrap cs-select_arrow">
                  <select className="cs-form_field cs-field_sm">
                    <option value="11">{t("general.sort_by")}</option>
                    <option value="22">{t("general.last_7_days")}</option>
                    <option value="33">{t("general.last_30_days")}</option>
                    <option value="44">{t("general.all_time")}</option>
                  </select>
                </div>
                <div className="cs-form_field_wrap cs-select_arrow">
                  <select className="cs-form_field cs-field_sm">
                    <option value="1">{t("general.likes")}</option>
                    <option value="2">{t("general.most_popular")}</option>
                    <option value="3">{t("general.price_order")}</option>
                    <option value="4">{t("general.date_order")}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="cs-height_30 cs-height_lg_30"></div>
            <div className="row">
              {data.map((item) => (
                <div className="col-xl-3 col-lg-4 col-sm-6" key={item.id}>
                  <div className="cs-card cs-style4 cs-box_shadow cs-white_bg">
                    {/* <span className="cs-card_like cs-primary_color">
                      <i className="fas fa-heart fa-fw"></i>
                      2.1K
                    </span> */}
                    <a
                      onClick={() => toDetail(item)}
                      className="cs-card_thumb cs-zoom_effect"
                    >
                      <img
                        src={item.image}
                        alt="Image"
                        className="cs-zoom_item"
                      />
                    </a>
                    <div className="cs-card_info" style={{ marginTop: 0 }}>
                      {/* <a href="#" className="cs-avatar cs-white_bg">
                      <img src="/assets/img/avatar/avatar_12.png" alt="Avatar" />
                      <span>Johny E.</span>
                    </a> */}
                      <h3 className="cs-card_title">
                        <a onClick={() => toDetail(item)}>{item.name}</a>
                      </h3>
                      <div className="cs-card_price">
                        {t("general.price")}:{" "}
                        <b className="cs-primary_color">
                          {item.pay_token_number} BPT
                        </b>
                      </div>
                      <hr />
                      <div className="cs-card_footer">
                        <span className="cs-card_btn_1" data-modal="#history_1">
                          {/* <i className="fas fa-redo fa-fw"></i>
                      View History */}
                        </span>
                        <a
                          onClick={() => toDetail(item)}
                          className={
                            item.is_lock ? "cs-card_btn_1" : "cs-card_btn_2"
                          }
                          data-params={JSON.stringify(item)}
                        >
                          <span>
                            {item.is_lock
                              ? t("components.lock")
                              : t("components.purchase")}
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="cs-height_30 cs-height_lg_30"></div>
                </div>
              ))}
            </div>
            <div className="cs-height_10 cs-height_lg_10"></div>
            {/* <div className="text-center">
              <a href="#" className="cs-btn cs-style1 cs-btn_lg">
                <span>더 보기</span>
              </a>
            </div> */}
          </div>
        </div>
      </div>
      <div className="cs-modal_wrap" id="inverter_pop">
        <div className="cs-modal_overlay"></div>
        <div className="cs-modal_container">
          <div className="cs-modal_container_in">
            <div className="cs-modal_close cs-center">
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9649 2.54988C12.3554 2.15936 12.3554 1.52619 11.9649 1.13567C11.5744 0.745142 10.9412 0.745142 10.5507 1.13567L11.9649 2.54988ZM0.550706 11.1357C0.160181 11.5262 0.160181 12.1594 0.550706 12.5499C0.94123 12.9404 1.5744 12.9404 1.96492 12.5499L0.550706 11.1357ZM1.96492 1.13567C1.5744 0.745142 0.94123 0.745142 0.550706 1.13567C0.160181 1.52619 0.160181 2.15936 0.550706 2.54988L1.96492 1.13567ZM10.5507 12.5499C10.9412 12.9404 11.5744 12.9404 11.9649 12.5499C12.3554 12.1594 12.3554 11.5262 11.9649 11.1357L10.5507 12.5499ZM10.5507 1.13567L0.550706 11.1357L1.96492 12.5499L11.9649 2.54988L10.5507 1.13567ZM0.550706 2.54988L10.5507 12.5499L11.9649 11.1357L1.96492 1.13567L0.550706 2.54988Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="cs-bid_card1">
              {props.language == "ko" && <GeneralKo />}
              {props.language == "en" && <GeneralEn />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default connect((state) => {
  return {
    language: state.language,
  };
})(Explore1);
