import about0Img from "@/assets/img/about0.svg";
import addressIcon from "@/assets/img/address.svg";
import emailIcon from "@/assets/img/emailicon.svg";
import { useTranslation } from "react-i18next";
export default function About() {
  const { t } = useTranslation();
  return (
    <>
      <div className="about-container">
        {/* End Header Section */}
        <div className="cs-height_90 cs-height_lg_80"></div>
        {/* Start Page Head */}
        <section
          className="cs-page_head cs-bg"
          data-src="/assets/img/page_head_bg.svg"
        >
          <div className="container">
            <div className="text-center">
              <h1 className="cs-page_title">{t("about.about_company")}</h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#/index">{t("about.home")}</a>
                </li>
                <li className="breadcrumb-item active">
                  {t("about.about_company")}
                </li>
              </ol>
            </div>
          </div>
        </section>
        <div className="cs-height_50 cs-height_lg_50"></div>
        <div className="about-content">
          <div className="item item1">
            <img className="icon1" src={about0Img} alt="" />
            <div className="con">
              <div className="title">{t("about.patent_eft")} </div>
              <div className="text">{t("about.description")}</div>
            </div>
            <img className="baner" src="/assets/img/about/about1.svg" alt="" />
          </div>
          <div className="item item2">
            <img className="baner" src="/assets/img/about/about2.svg" alt="" />
            <div className="con list">
              <div className="li">
                <div className="title">{t("about.history")}</div>
                <div></div>
              </div>
              <div className="li">
                <div className="title">2022</div>
                <div className="txt">{t("about.founding")}</div>
              </div>
              <div className="li">
                <div className="title">2023</div>
                <div className="txt">
                  Best Patent {t("about.project_collaboration")}
                </div>
              </div>
              <div className="li">
                <div className="title">2024</div>
                <div className="txt">
                  Best Patent {t("about.platform_launch")}
                  <br />
                  {t("about.patent_drafting_tool")}
                </div>
              </div>
            </div>
          </div>
          <div className="item item3">
            <div className="con">
              <div className="title">{t("about.contact_us")}</div>
              <div>
                patentnft@patent-nft.com{" "}
                <img className="icon" src={addressIcon} alt="" />
              </div>
              <div>
                {t("contact.company_location_text")}
                <img className="icon" src={emailIcon} alt="" />
              </div>
            </div>
            <img className="baner" src="/assets/img/about/about3.svg" alt="" />
          </div>
        </div>
        {/* Sta rt Logo Carousel */}
        <div className="cs-moving_carousel_1">
          {new Array(19).fill("").map((item, idx) => (
            <div className="cs-single_moving_item">
              <div className="cs-partner">
                <div className="cs-partner_in cs-center cs-white_bg overflow-hidden">
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src={`/assets/img/advlogo/img${idx}.png`}
                    alt="Partner logo"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="cs-height_30 cs-height_lg_30"></div>
        <div className="cs-moving_carousel_2">
          {new Array(19).fill("").map((item, idx) => (
            <div className="cs-single_moving_item">
              <div className="cs-partner">
                <div className="cs-partner_in cs-center cs-white_bg overflow-hidden">
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                    src={`/assets/img/advlogo/img${idx + 19}.png`}
                    alt="Partner logo"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="cs-height_100 cs-height_lg_70"></div>
        {/* E nd Logo Carousel */}
      </div>
    </>
  );
}
