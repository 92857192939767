import { useState } from "react";
import http from "../http";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import store from "@/store";
import { useTranslation } from "react-i18next";
export default function Login() {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  let [remember, setRemember] = useState(false);
  const navigate = useNavigate();
  let login = (event) => {
    event && event.preventDefault();
    let emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailReg.test(username)) {
      messageApi.open({
        type: "error",
        content: t("login.input_email"),
      });
      return;
    }
    let passwordReg = /^(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
    if (!passwordReg.test(password)) {
      messageApi.open({
        type: "error",
        content: t("login.input_password"),
      });
      return;
    }
    http
      .post("/login", {
        username,
        password,
      })
      .then((res) => {
        if (remember) {
          localStorage.setItem("token", res.token);
          sessionStorage.removeItem("token");
        } else {
          localStorage.removeItem("token");
          sessionStorage.setItem("token", res.token);
        }

        store.dispatch({ type: "changeUserInfo", userInfo: res.user });
        store.dispatch({ type: "changeIsLogin", isLogin: true });
        navigate("/");
        setTimeout(window.reset, 20);
      })
      .catch(() => {});
  };
  return (
    <>
      {/* End Header Section */}
      {contextHolder}
      <div className="cs-height_90 cs-height_lg_80"></div>
      {/* Start Page Head */}
      <section
        className="cs-page_head cs-bg"
        data-src="/assets/img/page_head_bg.svg"
      >
        <div className="container">
          <div className="text-center">
            <h1 className="cs-page_title">{t("login.login")}</h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#/index">{t("login.home")}</a>
              </li>
              <li className="breadcrumb-item active">{t("login.login")}</li>
            </ol>
          </div>
        </div>
      </section>
      {/* End Page Head */}

      <div className="cs-height_100 cs-height_lg_70"></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-md-8 offset-xl-3 offset-md-2">
            <form className="cs-form_card cs-style1 cs-box_shadow cs-white_bg">
              <div className="cs-form_card_in">
                <h2 className="cs-form_title text-center">
                  {t("login.login")}
                </h2>
                {/* <div className="cs-form_btns">
              <a href="#" className="cs-btn cs-style2 cs-btn_lg">
                <span><i className="fab fa-google"></i>Google</span>
              </a>
              <a href="#" className="cs-btn cs-style2 cs-btn_lg">
                <span><i className="fab fa-facebook-f"></i>Facebook</span>
              </a>
              <a href="#" className="cs-btn cs-style2 cs-btn_lg">
                <span><i className="fab fa-linkedin-in"></i>Linkedin</span>
              </a>
            </div> */}
                <div className="cs-height_30 cs-height_lg_30"></div>
                <div className="cs-form_field_wrap">
                  <input
                    type="text"
                    value={username}
                    onChange={(event) => setUsername(event.target.value)}
                    className="cs-form_field"
                    placeholder={t("login.email")}
                  />
                </div>
                <div className="cs-height_20 cs-height_lg_20"></div>
                <div className="cs-form_field_wrap">
                  <input
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    className="cs-form_field"
                    placeholder={t("login.password")}
                  />
                </div>
                <div className="cs-height_20 cs-height_lg_20"></div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    value={remember}
                    onChange={(event) => setRemember(event.target.checked)}
                    type="checkbox"
                    id="notify"
                  />
                  <label className="form-check-label" htmlFor="notify">
                    {t("login.remember")}
                  </label>
                </div>
                <div className="cs-height_15 cs-height_lg_15"></div>
                <button
                  className="cs-btn cs-style1 cs-btn_lg w-100"
                  onClick={login}
                >
                  <span>{t("login.sign")}</span>
                </button>
                <div className="cs-height_30 cs-height_lg_30"></div>
                <div className="cs-form_footer text-center">
                  {t("login.no_account")}{" "}
                  <a href="#/register">{t("login.register_now")}</a>
                  {t("login.no_account1")}{" "}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="cs-height_100 cs-height_lg_70"></div>
    </>
  );
}
