export default ()=>(<>

<h2 className="cs-title_title">
                런칭이벤트 인버터 특허 소개 내용
              </h2>
              <div className="cs-bid_info">
                <b>⭘기술 개요</b>
                <ul>
                  <li>
                    <b>특허명 :</b> 단일 전력단 구조를 갖는 인버터 및 그
                    제어장치
                  </li>
                  <li>
                    <b>기술 요약 : </b> 종래 인버터는 두 개의 전력단을 갖거나,
                    대용량 전해 커패시터를 갖는 등 부피가 크고 경제적이지
                    못하다는 문제점이 있었습니다. 해당 발명은 단일 전력단 구조를
                    가지는 인버터로, 트랜지스터 T1  내지 T4 각각의 게이트 단자로
                    입력되는 PWM(Pulse Width Modulation) ON/OFF 제어 신호를
                    조정하여 인버터의 동작을 제어합니다. 이에 따라 대용량 전해질
                    커패시터 없이도 PV패널에서 생성된 직류 전력을 교류 전력으로
                    효율적인 변환이 가능하게 하는 기술입니다.
                    <br />
                    따라서 기존의 방식 대비 인버터의 부피감소, 비용감소,
                    사용기간 증가 등의 혁신적인 특징을 가집니다.
                  </li>
                </ul>
                <b>⭘시장 분석</b>
                <ul>
                  <li>
                    <b>시장 규모 : </b> 세계의 태양광 발전 마이크로 인버터 시장
                    규모는 2023년 3억 1,190만 달러에 달했습니다. IMARC Group은
                    향후 시장이 2032년까지 9억 8,890만 달러에 이를 전망이며,
                    2024년부터 2032년까지 13.3%의 성장률(CAGR)을 보일 것으로
                    예측했습니다. 효율성과 신뢰성 향상에 대한 수요 증가,
                    이산화탄소 배출량 감소 및 지속가능성 유지를 위한 태양 에너지
                    도입 증가, 개별 패널 최적화의 인기 증가 등이 시장을 추진하는
                    주요 요인입니다.
                  </li>
                  <li>
                    <b>타겟 시장 : </b> 전력 변환 장치 제조업체, 재생 에너지
                    시스템 구축 업체, 산업용 전력 시스템 관련 기업 등을 타겟으로
                    하고 있습니다.
                  </li>
                </ul>
                <b>⭘NFT 민팅 정보</b>
                <ul>
                  <li>
                    <b>1차 발행 물량 :</b> 10개
                  </li>
                  <li>
                    <b>민팅 일정 : </b>2024.08.10.(토)
                  </li>
                  <li>
                    <b>판매 가격 : </b>1,000 BPT
                  </li>
                  <li>
                    <b></b>추가 BPT 후원 및 드랍 이벤트 추후 공고
                  </li>
                </ul>
                <b>⭘판매자 정보</b>
                <ul>
                  <li>
                    <b>기관(기업)명:</b>
                    Data-to-energy
                  </li>
                  <li>
                    <b>문의처: </b>
                    tearis1125@gmail.com
                  </li>
                </ul>
              </div>
</>)