export default function HelpCenter() {
  return (<>



    {/* End Header Section */}

    <div className="cs-height_90 cs-height_lg_80"></div>
    {/* Start Page Head */}
    <section className="cs-page_head cs-bg" data-src="/assets/img/page_head_bg.svg">
      <div className="container">
        <div className="text-center">
          <h1 className="cs-page_title">Help & Support</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#/index">홈</a></li>
            <li className="breadcrumb-item active">Help & Support</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Page Head */}

    <div className="container">
      <div className="cs-height_100 cs-height_lg_70"></div>
      <form action="#" className="cs-search cs-search_lg">
        <input type="text" className="cs-search_input" placeholder="검색" />
        <button className="cs-search_btn">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.16667 16.3333C12.8486 16.3333 15.8333 13.3486 15.8333 9.66667C15.8333 5.98477 12.8486 3 9.16667 3C5.48477 3 2.5 5.98477 2.5 9.66667C2.5 13.3486 5.48477 16.3333 9.16667 16.3333Z" stroke="currentColor" strokeOpacity="0.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.5 18L13.875 14.375" stroke="currentColor" strokeOpacity="0.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </button>
      </form>
      <div className="cs-height_95 cs-height_lg_70"></div>
      <h2 className="cs-section_heading cs-style1 text-center">Browse</h2>
      <div className="cs-height_45 cs-height_lg_45"></div>
      <div className="row">
        <div className="col-xl-3 col-lg-4">
          <a href="#" className="cs-text_box cs-style1 cs-box_shadow text-center cs-white_bg">
            <h3>User Guide</h3>
            <p>It is a long established <br />fact that a reader</p>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-3 col-lg-4">
          <a href="#" className="cs-text_box cs-style1 cs-box_shadow text-center cs-white_bg">
            <h3>Collecting</h3>
            <p>It is a long established <br />fact that a reader</p>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-3 col-lg-4">
          <a href="#" className="cs-text_box cs-style1 cs-box_shadow text-center cs-white_bg">
            <h3>Payments and Balances</h3>
            <p>It is a long established <br />fact that a reader</p>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-3 col-lg-6">
          <a href="#" className="cs-text_box cs-style1 cs-box_shadow text-center cs-white_bg">
            <h3>Selling</h3>
            <p>It is a long established <br />fact that a reader</p>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-4 col-lg-6">
          <a href="#" className="cs-text_box cs-style1 cs-box_shadow text-center cs-white_bg">
            <h3>Frequently Asked Questions</h3>
            <p>It is a long established <br />fact that a reader</p>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-4 col-lg-6">
          <a href="#" className="cs-text_box cs-style1 cs-box_shadow text-center cs-white_bg">
            <h3>Currency</h3>
            <p>It is a long established <br />fact that a reader</p>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-4 col-lg-6">
          <a href="#" className="cs-text_box cs-style1 cs-box_shadow text-center cs-white_bg">
            <h3>User Safety</h3>
            <p>It is a long established <br />fact that a reader</p>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
      </div>
      <div className="cs-height_65 cs-height_lg_40"></div>
      <h2 className="cs-section_heading cs-style1 text-center">Promoted Articles</h2>
      <div className="cs-height_45 cs-height_lg_45"></div>
      <div className="row">
        <div className="col-xl-3 col-lg-6">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">What is a wallet?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-3 col-lg-6">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">What is a Best Patent Token?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-3 col-lg-6">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">What is Etherum (ETH)?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-3 col-lg-6">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How do I sell an Best Patent Token?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-3 col-lg-6">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How do I purchase Coin?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-3 col-lg-6">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How do I sell my Best Patent Token?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-3 col-lg-6">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How do I sell an Best Patent Token?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-3 col-lg-6">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">What does Best Patent Token mean in crypto?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-lg-6">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">What can I do if I think my account is compromised?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-lg-6">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How do I sell an Best Patent Token? What is a blockchain & how does it work?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-4 col-lg-6">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How do you connect other wallets?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-4 col-lg-6">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">What is a Non-Fungible Token (Best Patent Token)?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-xl-4 col-lg-6">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How do I sell an Best Patent Token?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-lg-3">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How do I sell  an Best Patent Token?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-lg-3">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How do I sell an Best Patent Token?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-lg-3">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How do I sell an Best Patent Token?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-lg-3">
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">Why do Best Patent Token even make sense?</span>
          </a>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
      </div>
    </div>
    <div className="cs-height_70 cs-height_lg_40"></div>

    {/* Start CTA */}
    <div className="container">
      <div className="cs-cta cs-style2 cs-type1 text-center cs-accent_bg">
        <h2 className="cs-cta_title cs-white_color_8">Join our biggest Best Patent Token platform</h2>
        <a href="#/register" className="cs-btn cs-style1 cs-btn_lg cs-color2"><span>Sign Up</span></a>
      </div>
    </div>
    {/* End CTA */}
    <div className="cs-height_100 cs-height_lg_70"></div>




  </>);
}