import { useTranslation } from 'react-i18next';
export default function ConnectWallet() {
  const { t } = useTranslation();
  return (<>



    {/* End Header Section */}

    <div className="cs-height_90 cs-height_lg_80"></div>

    <div className="cs-height_100 cs-height_lg_70"></div>
    <div className="container">
      <div className="cs-section_heading cs-style4">
        <h2 className="cs-section_title">{t("connectWallet.wallet_connection")}</h2>
        <p className="cs-section_subtitle">{t("connectWallet.install_wallet")} </p>
      </div>
      <div className="cs-height_45 cs-height_lg_45"></div>
      <div className="row">
        <div className="col-lg-4 col-sm-6">
          <div className="cs-iconbox cs-style3 cs-box_shadow cs-white_bg">
            <div className="cs-iconbox_img"><img src="/assets/img/general/iconbox_logo_1.svg" alt="Logo" /></div>
            <div className="cs-iconbox_text">MetaMask is a web browser extension and mobile app that allows you to manage your Ethereum private keys. These programs act as wallets for Ethereum and other tokens and can interact with decentralized applications, or DApps.</div>
            <a href="https://metamask.io/" target="_blank"  className="cs-iconbox_btn cs-primary_font">
              {t("connectWallet.wallet_connection")}
              <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5303 6.75396C16.8232 6.46107 16.8232 5.9862 16.5303 5.6933L11.7574 0.920332C11.4645 0.627439 10.9896 0.627439 10.6967 0.920332C10.4038 1.21323 10.4038 1.6881 10.6967 1.98099L14.9393 6.22363L10.6967 10.4663C10.4038 10.7592 10.4038 11.234 10.6967 11.5269C10.9896 11.8198 11.4645 11.8198 11.7574 11.5269L16.5303 6.75396ZM0 6.97363H16V5.47363H0V6.97363Z" fill="currentColor" />
              </svg>
            </a>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        {/* <div className="col-lg-4 col-sm-6">
          <div className="cs-iconbox cs-style3 cs-box_shadow cs-white_bg">
            <div className="cs-iconbox_img"><img src="/assets/img/general/iconbox_logo_2.svg" alt="Logo" /></div>
            <div className="cs-iconbox_text">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</div>
            <a href="#" className="cs-iconbox_btn cs-primary_font">
              {t("connectWallet.wallet_connection")}
              <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5303 6.75396C16.8232 6.46107 16.8232 5.9862 16.5303 5.6933L11.7574 0.920332C11.4645 0.627439 10.9896 0.627439 10.6967 0.920332C10.4038 1.21323 10.4038 1.6881 10.6967 1.98099L14.9393 6.22363L10.6967 10.4663C10.4038 10.7592 10.4038 11.234 10.6967 11.5269C10.9896 11.8198 11.4645 11.8198 11.7574 11.5269L16.5303 6.75396ZM0 6.97363H16V5.47363H0V6.97363Z" fill="currentColor" />
              </svg>
            </a>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="cs-iconbox cs-style3 cs-box_shadow cs-white_bg">
            <div className="cs-iconbox_img"><img src="/assets/img/general/iconbox_logo_3.svg" alt="Logo" /></div>
            <div className="cs-iconbox_text">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</div>
            <a href="#" className="cs-iconbox_btn cs-primary_font">
              {t("connectWallet.wallet_connection")}
              <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5303 6.75396C16.8232 6.46107 16.8232 5.9862 16.5303 5.6933L11.7574 0.920332C11.4645 0.627439 10.9896 0.627439 10.6967 0.920332C10.4038 1.21323 10.4038 1.6881 10.6967 1.98099L14.9393 6.22363L10.6967 10.4663C10.4038 10.7592 10.4038 11.234 10.6967 11.5269C10.9896 11.8198 11.4645 11.8198 11.7574 11.5269L16.5303 6.75396ZM0 6.97363H16V5.47363H0V6.97363Z" fill="currentColor" />
              </svg>
            </a>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="cs-iconbox cs-style3 cs-box_shadow cs-white_bg">
            <div className="cs-iconbox_img"><img src="/assets/img/general/iconbox_logo_4.svg" alt="Logo" /></div>
            <div className="cs-iconbox_text">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</div>
            <a href="#" className="cs-iconbox_btn cs-primary_font">
              {t("connectWallet.wallet_connection")}
              <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5303 6.75396C16.8232 6.46107 16.8232 5.9862 16.5303 5.6933L11.7574 0.920332C11.4645 0.627439 10.9896 0.627439 10.6967 0.920332C10.4038 1.21323 10.4038 1.6881 10.6967 1.98099L14.9393 6.22363L10.6967 10.4663C10.4038 10.7592 10.4038 11.234 10.6967 11.5269C10.9896 11.8198 11.4645 11.8198 11.7574 11.5269L16.5303 6.75396ZM0 6.97363H16V5.47363H0V6.97363Z" fill="currentColor" />
              </svg>
            </a>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="cs-iconbox cs-style3 cs-box_shadow cs-white_bg">
            <div className="cs-iconbox_img"><img src="/assets/img/general/iconbox_logo_5.svg" alt="Logo" /></div>
            <div className="cs-iconbox_text">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</div>
            <a href="#" className="cs-iconbox_btn cs-primary_font">
              {t("connectWallet.wallet_connection")}
              <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5303 6.75396C16.8232 6.46107 16.8232 5.9862 16.5303 5.6933L11.7574 0.920332C11.4645 0.627439 10.9896 0.627439 10.6967 0.920332C10.4038 1.21323 10.4038 1.6881 10.6967 1.98099L14.9393 6.22363L10.6967 10.4663C10.4038 10.7592 10.4038 11.234 10.6967 11.5269C10.9896 11.8198 11.4645 11.8198 11.7574 11.5269L16.5303 6.75396ZM0 6.97363H16V5.47363H0V6.97363Z" fill="currentColor" />
              </svg>
            </a>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div>
        <div className="col-lg-4 col-sm-6">
          <div className="cs-iconbox cs-style3 cs-box_shadow cs-white_bg">
            <div className="cs-iconbox_img"><img src="/assets/img/general/iconbox_logo_6.svg" alt="Logo" /></div>
            <div className="cs-iconbox_text">Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.</div>
            <a href="#" className="cs-iconbox_btn cs-primary_font">
              {t("connectWallet.wallet_connection")}
              <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5303 6.75396C16.8232 6.46107 16.8232 5.9862 16.5303 5.6933L11.7574 0.920332C11.4645 0.627439 10.9896 0.627439 10.6967 0.920332C10.4038 1.21323 10.4038 1.6881 10.6967 1.98099L14.9393 6.22363L10.6967 10.4663C10.4038 10.7592 10.4038 11.234 10.6967 11.5269C10.9896 11.8198 11.4645 11.8198 11.7574 11.5269L16.5303 6.75396ZM0 6.97363H16V5.47363H0V6.97363Z" fill="currentColor" />
              </svg>
            </a>
          </div>
          <div className="cs-height_30 cs-height_lg_30"></div>
        </div> */}
      </div>
    </div>
    <div className="cs-height_70 cs-height_lg_40"></div>




  </>);
}