export default function Faq() {
  return (<>



    {/* End Header Section */}

    <div className="cs-height_90 cs-height_lg_80"></div>
    {/* Start Page Head */}
    <section className="cs-page_head cs-bg" data-src="/assets/img/page_head_bg.svg">
      <div className="container">
        <div className="text-center">
          <h1 className="cs-page_title">Frequently Asked Questions</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#/index">홈</a></li>
            <li className="breadcrumb-item active">FAQ</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Page Head */}
    <div className="cs-height_100 cs-height_lg_70"></div>
    <div className="container">
      <div className="row cs-col_reverse_lg">
        <div className="col-lg-6">
          <div className="cs-faq">
            <div className="cs-section_heading cs-style3">
              <h2 className="cs-section_title">Getting Started</h2>
              <div className="cs-section_seperator"></div>
            </div>
            <div className="cs-height_30 cs-height_lg_30"></div>
            <div className="cs-accordians">
              <div className="cs-accordian active">
                <div className="cs-accordian_head">
                  <h2 className="cs-accordian_title">Do you have to mint an Best Patent Token to sell it?</h2>
                  <span className="cs-accordian_toggle">
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.62109 0.750977L6.95443 6.08431L12.2878 0.750977" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </div>
                <div className="cs-accordian-body">
                  <p>Once you have minted a piece of artwork on a marketplace as an <br />Best Patent Token, you should never mint it on another platform. If you ever decide <br />to do so, note that most platforms reserve</p>
                </div>
              </div>{/* .cs-accordian */}
              <div className="cs-accordian">
                <div className="cs-accordian_head">
                  <h2 className="cs-accordian_title">How much does the average Best Patent Token sell for?</h2>
                  <span className="cs-accordian_toggle">
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.62109 0.750977L6.95443 6.08431L12.2878 0.750977" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </div>
                <div className="cs-accordian-body">
                  <p>Once you have minted a piece of artwork on a marketplace as an <br />Best Patent Token, you should never mint it on another platform. If you ever decide <br />to do so, note that most platforms reserve</p>
                </div>
              </div>{/* .cs-accordian */}
              <div className="cs-accordian">
                <div className="cs-accordian_head">
                  <h2 className="cs-accordian_title">What kind of Best Patent Token sell best?</h2>
                  <span className="cs-accordian_toggle">
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.62109 0.750977L6.95443 6.08431L12.2878 0.750977" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </div>
                <div className="cs-accordian-body">
                  <p>Once you have minted a piece of artwork on a marketplace as an <br />Best Patent Token, you should never mint it on another platform. If you ever decide <br />to do so, note that most platforms reserve</p>
                </div>
              </div>{/* .cs-accordian */}
              <div className="cs-accordian">
                <div className="cs-accordian_head">
                  <h2 className="cs-accordian_title">How to make money with Best Patent Token as a beginner?</h2>
                  <span className="cs-accordian_toggle">
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.62109 0.750977L6.95443 6.08431L12.2878 0.750977" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </div>
                <div className="cs-accordian-body">
                  <p>Once you have minted a piece of artwork on a marketplace as an <br />Best Patent Token, you should never mint it on another platform. If you ever decide <br />to do so, note that most platforms reserve</p>
                </div>
              </div>{/* .cs-accordian */}
              <div className="cs-accordian">
                <div className="cs-accordian_head">
                  <h2 className="cs-accordian_title">Who owns the most expensive Best Patent Token?</h2>
                  <span className="cs-accordian_toggle">
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.62109 0.750977L6.95443 6.08431L12.2878 0.750977" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </div>
                <div className="cs-accordian-body">
                  <p>Once you have minted a piece of artwork on a marketplace as an <br />Best Patent Token, you should never mint it on another platform. If you ever decide <br />to do so, note that most platforms reserve</p>
                </div>
              </div>{/* .cs-accordian */}
            </div>{/* .cs-accordians */}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="text-center">
            <img src="/assets/img/faq_1.svg" alt="faq" />
          </div>
          <div className="cs-height_0 cs-height_lg_30"></div>
        </div>
      </div>
    </div>
    <div className="cs-height_80 cs-height_lg_70"></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="text-center">
            <img src="/assets/img/faq_2.svg" alt="faq" />
          </div>
          <div className="cs-height_0 cs-height_lg_30"></div>
        </div>
        <div className="col-lg-6">
          <div className="cs-faq">
            <div className="cs-section_heading cs-style3">
              <h2 className="cs-section_title">Safety, Security, and Policies</h2>
              <div className="cs-section_seperator"></div>
            </div>
            <div className="cs-height_30 cs-height_lg_30"></div>
            <div className="cs-accordians">
              <div className="cs-accordian active">
                <div className="cs-accordian_head">
                  <h2 className="cs-accordian_title">How do I get started in Best Patent Token?</h2>
                  <span className="cs-accordian_toggle">
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.62109 0.750977L6.95443 6.08431L12.2878 0.750977" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </div>
                <div className="cs-accordian-body">
                  <b>Step 1:</b> Decide on the concept.
                  <b>Step 2:</b> Decide on the platform. <br />
                  <b>Step 3:</b> Connect and build community.
                  <b>Step 4:</b> Create your art. <br />
                  <b>Step 5:</b> Mint and share.
                  <b>The final step:</b> Selling your Best Patent Token.
                </div>
              </div>{/* .cs-accordian */}
              <div className="cs-accordian">
                <div className="cs-accordian_head">
                  <h2 className="cs-accordian_title">How much does the average Best Patent Token sell for?</h2>
                  <span className="cs-accordian_toggle">
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.62109 0.750977L6.95443 6.08431L12.2878 0.750977" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </div>
                <div className="cs-accordian-body">
                  <p>Once you have minted a piece of artwork on a marketplace as an <br />Best Patent Token, you should never mint it on another platform. If you ever decide <br />to do so, note that most platforms reserve</p>
                </div>
              </div>{/* .cs-accordian */}
              <div className="cs-accordian">
                <div className="cs-accordian_head">
                  <h2 className="cs-accordian_title">What kind of Best Patent Token sell best?</h2>
                  <span className="cs-accordian_toggle">
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.62109 0.750977L6.95443 6.08431L12.2878 0.750977" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </div>
                <div className="cs-accordian-body">
                  <p>Once you have minted a piece of artwork on a marketplace as an <br />Best Patent Token, you should never mint it on another platform. If you ever decide <br />to do so, note that most platforms reserve</p>
                </div>
              </div>{/* .cs-accordian */}
              <div className="cs-accordian">
                <div className="cs-accordian_head">
                  <h2 className="cs-accordian_title">How to make money with Best Patent Token as a beginner?</h2>
                  <span className="cs-accordian_toggle">
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.62109 0.750977L6.95443 6.08431L12.2878 0.750977" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </div>
                <div className="cs-accordian-body">
                  <p>Once you have minted a piece of artwork on a marketplace as an <br />Best Patent Token, you should never mint it on another platform. If you ever decide <br />to do so, note that most platforms reserve</p>
                </div>
              </div>{/* .cs-accordian */}
              <div className="cs-accordian">
                <div className="cs-accordian_head">
                  <h2 className="cs-accordian_title">Who owns the most expensive Best Patent Token?</h2>
                  <span className="cs-accordian_toggle">
                    <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.62109 0.750977L6.95443 6.08431L12.2878 0.750977" stroke="currentColor" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </div>
                <div className="cs-accordian-body">
                  <p>Once you have minted a piece of artwork on a marketplace as an <br />Best Patent Token, you should never mint it on another platform. If you ever decide <br />to do so, note that most platforms reserve</p>
                </div>
              </div>{/* .cs-accordian */}
            </div>{/* .cs-accordians */}
          </div>
        </div>
      </div>
    </div>
    <div className="cs-height_90 cs-height_lg_70"></div>

    {/* Start CTA */}
    <div className="container">
      <div className="cs-cta cs-style2 text-center cs-accent_bg">
        <h2 className="cs-cta_title cs-white_color_8">Join our biggest Best Patent Token platform</h2>
        <div className="cs-cta_subtitle cs-white_color_8">Exercitation veniam consequat sunt nostrud amet. It is a long <br />established fact that a reader</div>
        <a href="#/register" className="cs-btn cs-style1 cs-btn_lg cs-color2"><span>Sign Up</span></a>
      </div>
    </div>
    {/* End CTA */}
    <div className="cs-height_100 cs-height_lg_70"></div>




  </>);
}