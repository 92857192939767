// src/WalletContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import Web3 from "web3";

const WalletContext = createContext();

export const useWallet = () => useContext(WalletContext);

export const WalletProvider = ({ children }) => {
  const [wallet, setWallet] = useState({
    web3: null,
    account: null,
    balance: 0,
  });

  const connectWallet = async () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      const web3 = new Web3(window.ethereum);
      try {
        // Request accounts to trigger MetaMask popup
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        const balances = await web3.eth.getBalance(accounts[0]);
        const balance = await web3.utils.fromWei(balances, "ether");
        setWallet({ web3, account: accounts[0], balance: balance });
        localStorage.setItem("isWalletConnected", "true");
      } catch (error) {
        console.error("User denied account access", error);
      }
    } else {
      console.error("MetaMask not detected or not installed");
      alert("Please install MetaMask to use this application.");
    }
  };

  const disconnectWallet = () => {
    setWallet({ web3: null, account: null, balance: 0 });
    localStorage.removeItem("isWalletConnected");
  };

  useEffect(() => {
    const autoConnect = async () => {
      if (localStorage.getItem("isWalletConnected") === "true") {
        await connectWallet();
      }
    };
    autoConnect();
  }, []);

  const clearAppState = () => {
    // Clear any app state or cache related to the wallet connection
    localStorage.removeItem("isWalletConnected");
    setWallet({ web3: null, account: null });
  };

  return (
    <WalletContext.Provider
      value={{ wallet, connectWallet, disconnectWallet, clearAppState }}
    >
      {children}
    </WalletContext.Provider>
  );
};
