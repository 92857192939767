export default () => (
  <>
  <p>
<strong>Terms of Service</strong>
</p>
<p>
<strong>Article 1 (Purpose)</strong> The purpose of these terms of service is to define the rights and obligations of PatentNFT Corporation (hereinafter referred to as the "Company") and Best Patent members (hereinafter referred to as "Members") who have agreed to the Best Patent Terms of Service in the transaction of NFTs (as defined in Article 2) within the Best Patent service (hereinafter referred to as the "Service") provided by the Company.</p>
<p>
<strong>Article 2 (Definitions)</strong> The definitions of terms used in these terms of service are as follows:</p>
<p>1. <strong>"NFT"</strong> refers to a Non-Fungible Token issued on a blockchain as a digital asset, containing metadata information about "Digital Works" such as images, videos, music, books, etc. NFTs traded on Best Patent are categorized into two types as follows:</p>
<p>o <strong>a. "Artwork NFT"</strong> refers to an NFT first distributed by the Company through Best Patent, which visualizes the contents (technical details, drawings) of historically significant patents, patents held by big tech companies, or patents owned by celebrities, and is sold as a digital asset.</p>
<p>o <strong>b. "Patent NFT"</strong> refers to an NFT first distributed by the Company through Best Patent, where highly marketable patents are sponsored with BPT, and later BPT drop events are held to reward sponsors when business profits are generated.</p>
<p>2. <strong>"Digital Works"</strong> refers to digital works such as digital images, videos, music, books, etc., that are linked 1:1 to the NFT at the time of issuance by the issuer and are not subject to change without special circumstances.</p>
<p>3. <strong>"Digital Works Online Exhibition"</strong> refers to online screens such as websites and apps where the "Digital Works" linked to NFTs can be viewed and appreciated, as well as the servers, databases, etc. required for their implementation.</p>
<p>4. <strong>"NFT Marketplace"</strong> refers to an online place where NFTs can be traded, as well as the servers, databases, etc. required for its implementation. Unless otherwise specified, the term "NFT Marketplace" in these terms of service refers to the NFT service provided by the Company (as defined in Article 8).</p>
<p>5. <strong>"NFT Issuer"</strong> refers to the person or entity that first distributes the NFT by issuing it.</p>
<p>6. <strong>"NFT IP"</strong> refers to all legitimate rights necessary for the following acts:</p>
<p>o <strong>a. "Digital Works" production</strong> [(i) acquisition of intellectual property rights to the "Digital Works" themselves or (ii) acquisition of intellectual property rights or licenses necessary for the production of "Digital Works" through a commission contract with the copyright holder of the original work].</p>
<p>o <strong>b. Issuance and distribution of NFTs linked to "Digital Works".</strong>
</p>
<p>o <strong>c. Guarantee of "Rights of NFT Holders" (as defined in Article 3) to those who hold the issued and distributed NFTs.</strong>
</p>
<p>7. <strong>"External Deposit/Withdrawal"</strong> refers to the act of depositing or withdrawing digital assets, including NFTs, between the digital asset wallet operated by the Company and the digital asset wallet or external personal wallet operated by a third party outside the Company.</p>
<p>8. <strong>"NFT Service"</strong> refers to NFT-related services provided by Best Patent.</p>
<p>9. <strong>"Collection"</strong> refers to the collective term for NFTs distributed under the same smart contract, and in cases where the "NFT Issuer" or the Company classifies them differently, the term refers to NFTs classified accordingly.</p>
<p>10. <strong>"Auxiliary Works"</strong> refers to digital works such as preview images, logo images, etc., displayed to indicate the status, location, characteristics, etc., of the NFT, which do not fall under "Digital Works".</p>
<p>
<strong>Article 3 (Rights of NFT Holders)</strong>
</p>
<p>1. NFT holders may exercise the rights granted or permitted by the "NFT Issuer" or a third party with legitimate authority concerning the relevant NFT (hereinafter referred to as "Rights of NFT Holders"). The content of the "Rights of NFT Holders" may vary for each NFT.</p>
<p>2. If the "Rights of NFT Holders" are displayed within the service, such display may not fully reflect the rights granted to the relevant NFT, and those intending to purchase the NFT should confirm the specific details of the rights granted to each NFT through the web page provided by the "NFT Issuer".</p>
<p>3. Generally, NFTs do not represent specific rights such as copyright, trademark, intellectual property rights, or publicity rights, nor do they imply ownership of the digital works linked to the NFT or acquisition of intellectual property rights related to the digital works. However, this does not apply if otherwise specified by the "NFT Issuer" or the holder of the "NFT IP".</p>
<p>4. The "Rights of NFT Holders" can only be exercised while the NFT is legally held. If the NFT is transferred or assigned to another person, the former holder will no longer be able to exercise the "Rights of NFT Holders". Those who receive the NFT through a method not officially supported by the Company and/or in violation of applicable laws may not have their "Rights of NFT Holders" guaranteed.</p>
<p>
<strong>Article 4 (Cautions Regarding NFTs)</strong>
</p>
<p>1. The NFTs traded on Best Patent are based on the BPT protocol on the Ethereum chain operated by the Company, and the Company may support transactions on new NFT chains and/or protocols in the future.</p>
<p>2. The Company acts as an intermediary for NFT transactions and is not a party to the NFT transaction. The responsibility for NFTs, "Rights of NFT Holders", "NFT IP", and/or NFT transactions lies with the "NFT Issuer" and/or the seller. The Company does not represent the "NFT Issuer" and does not guarantee the authenticity of the digital works linked to the NFT, the completeness of the "NFT IP", or the "Rights of NFT Holders". However, the Company will bear responsibility within the scope prescribed by law if damage is caused due to the Company's intentional or negligent actions.</p>
<p>3. NFTs cannot be transferred to wallets on other chains unless additional development support is provided. If an NFT is transferred to a wallet on a chain other than the one on which it is based, the Company will not bear any responsibility for damages or recovery support, except in cases of intentional or negligent actions by the Company.</p>
<p>4. The Company may use the digital works linked to NFTs for promotional purposes related to Best Patent and NFT transaction mediation within Best Patent.</p>
<p>5. Whether or not to establish a "Digital Works Online Exhibition" may vary for each NFT, and the Company does not guarantee the establishment of a "Digital Works Online Exhibition" for each NFT.</p>
<p>6. Any taxes related to NFTs are to be borne by the person designated as the taxpayer under applicable laws. However, if someone other than the taxpayer agrees to bear the taxes for a specific transaction, this does not apply.</p>
<p>
<br/>
</p>
<hr/>
<p>
<br/>
</p>
<p>
<br/>
</p>
<p>
<strong>Article 5 (Cautions Regarding NFT Services)</strong>
</p>
<p>1. The Company has sole discretion over the scope of services provided on "Best Patent" (including but not limited to the types of NFT chains and/or protocols supported for transactions, whether or not bridges are provided, whether or not individual NFTs are supported for transactions, the transaction support period, and whether or not external deposit/withdrawal is supported), and the scope of services provided may vary for each NFT.</p>
<p>2. The following information may be disclosed on the service screens: (i) all information related to NFTs that may be distributed or traded on "Best Patent" ("NFT Issuer", creator, information related to digital works, etc.) and (ii) the member's nickname, transaction-related information (transaction date, transaction amount, current sale status, desired sale amount, details of owned NFTs, etc.), and activities within the service.</p>
<p>3. If the "NFT Issuer" demonstrates that it is necessary to take action regarding NFTs that have been or are being distributed (including but not limited to cases where there is an error in the NFT, its metadata, or distribution-related data that needs to be corrected), the Company may suspend the distribution and/or transaction of the NFT and allow the "NFT Issuer" to take the necessary measures to issue a new NFT without errors. The Company will notify the members of the reason and details of the action within five business days after cooperating with the action in this clause.</p>
<p>4. At the request of the "NFT Issuer", the Company may announce events related to the NFT within the service. The "NFT Issuer" is responsible for the NFT-related event, and the Company is not liable for the NFT-related event unless the Company is at fault or negligent.</p>
<p>5. If a specific type of NFT is intended to be distributed to NFT holders as a separate NFT, and an "arbitrary deposit" occurs as per Article 15, Clause 11 of the Best Patent Terms of Service, and the Company decides to process the deposit or provide recovery support, the NFT subject to "arbitrary deposit" may be distributed randomly to the "NFT Holders" who are the target of the arbitrary deposit. If additional actions (such as applying, entering the digital address to receive the arbitrary deposit, etc.) are required to trigger the "arbitrary deposit" beyond simply holding the NFT, the "NFT Holder" must independently carry out these actions, and the Company will not be responsible for this unless the Company is at fault or negligent.</p>
<p> </p>
<p>·6. &nbsp;The Company may suspend, discontinue, or terminate transaction support for individual NFTs in the following cases. NFTs whose transaction support has been suspended or terminated will not be supported for external deposits:</p>
<p>1. In cases corresponding to Article 5, Clause 3.</p>
<p>2. In cases where a dispute arises concerning the rights to the "Digital Works" or "NFT IP" (including but not limited to cases falling under Article 8).</p>
<p>3. In cases where the "NFT Issuer" is identified as a special related party of the Company (as defined in Article 34, Clause 4 of the Enforcement Decree of the Commercial Act).</p>
<p>4. In cases where the operation of the chain and/or protocol on which the NFT is based is discontinued.</p>
<p>5. In cases where the content based on the NFT is lost, or it is confirmed that the NFT is being distributed through other distribution channels based on similar content.</p>
<p>6. In cases where significant abnormal transactions related to the NFT occur.</p>
<p>7. In cases where the termination of digital asset transaction support is applicable under Article 15, Clause 4 of the Best Patent Terms of Service.</p>
<p>8. Or in cases where similar reasons as the above, or the necessity for the termination of transaction support is recognized to protect Best Patent NFT members.</p>
<p>·7. &nbsp;If the Company suspends or terminates transaction support for an NFT according to Clause 6, except for the case under Clause 6.2, (i) if transaction support is terminated for an NFT that previously allowed external deposits/withdrawals, the Company will support external withdrawals of the NFT for at least thirty (30) days from the date of termination of transaction support, and (ii) for NFTs that did not allow external deposits/withdrawals, the Company will support the download of digital works linked to the NFT (digital works downloaded according to this clause can only be used within the scope and purpose granted by the "Rights of NFT Holders").</p>
<p style={{textIndent: "11pt"}}>8. &nbsp;The Company may terminate all or part of the NFT service for the following reasons. In this case, those who hold NFTs of a terminated service will no longer be able to exercise the "Rights of NFT Holders" within the Company's NFT service. If the Company terminates all or part of the NFT service, Clause 7 of this Article shall apply. Matters not specified in this Article, such as methods of prior notification for the termination of NFT services, support for external withdrawals after termination, etc., are governed by the Best Patent Terms of Service.</p>
<p>1. If the "Company" discontinues the operation of Best Patent.</p>
<p>2. If it becomes necessary to terminate all or part of the existing NFT services due to instructions, recommendations, or related laws from national institutions.</p>
<p>3. In cases corresponding to the reasons for service discontinuation specified in Article 15, Clause 3 of the Best Patent Terms of Service.</p>
<p>
<br/>
</p>
<p>
<br/>
</p>
<p>
<br/>
</p>
<p>
<br/>
</p>
<p>
<strong>Article 6 (Prohibited Acts and Termination)</strong>
</p>
<p>1. Members are not allowed to perform the following acts in connection with NFTs distributed through Best Patent and the related digital works. However, this does not apply to items 1 through 4 if the "NFT Issuer" or the holder of the "NFT IP" specifies otherwise.</p>
<p>1. Modifying or distorting digital works and posting them.</p>
<p>2. Using digital works for commercial purposes, such as advertising one's own or third-party products or services.</p>
<p>3. Issuing separate NFTs linked to the digital works.</p>
<p>4. Abusing the digital works available under the "Rights of NFT Holders" to register or acquire separate intellectual property rights.</p>
<p>5. Manipulating NFT prices abnormally by placing multiple orders in collusion with specific members in sales or auction transactions.</p>
<p>6. Depositing or withdrawing NFTs using methods other than those provided by the Company.</p>
<p>7. Other acts equivalent to the above items.</p>
<p>2. If a member violates the previous paragraph, the Company may restrict the use of the service and simultaneously request corrective action. If the violation is not corrected within a reasonable period despite the request, or if the same violation is repeated more than twice, the Company may terminate the NFT service usage agreement and/or the Best Patent service usage agreement.</p>
<p>3. Members cannot withdraw from the service while holding NFTs. Members may withdraw from the service after disposing of the NFTs they hold or after "external withdrawal." However, members may request the Company to burn or dispose of the NFT before withdrawing from the service, and if the Company agrees to the request, the member cannot request the return of the burned or disposed NFT. However, this does not apply in cases of the Company's intentional or negligent acts.</p>
<p>4. The application of Articles 10, 17, 18, and 20 of the Best Patent Terms of Service is not excluded by this Article.</p>
<p>
<strong>Article 7 (Cautions Regarding NFT Trading Methods)</strong>
</p>
<p>1. Members may "externally deposit" NFTs they hold, issued outside the Company, into the virtual asset wallet operated by the Company in a manner supported by the Company. When a member "externally deposits" an "externally issued NFT" into the Company's virtual asset wallet, they delegate to the Company the authority to externally deposit the entire collection to which the "externally issued NFT" belongs at the time of the first deposit. The member may revoke the delegation of authority granted to the Company using the method provided by the wallet service.</p>
<p>2. The Company may intermediate transactions of NFTs held by members in personal wallets. In such cases, once the member's requested transaction begins processing on the network, the transaction request cannot be withdrawn or stopped.</p>
<p>
<strong>Article 8 (Objections Regarding Digital Works)</strong>
</p>
<p>1. A person who claims that their copyright or other rights are infringed upon by the NFTs distributed through "Best Patent" provided by the "Company" and the "digital works" linked to those NFTs (hereinafter referred to as the "Claimant") may provide evidence of the infringement and request the "Company" to suspend the use and distribution of the relevant NFT.</p>
<p>2. If the "Company" receives a request to suspend the use and distribution of an NFT according to the previous paragraph, the Company must immediately suspend the transaction support for the relevant NFT and notify the Claimant, the member holding the NFT, and the "NFT Issuer" of the matter.</p>
<p>3. If the "NFT Issuer," who has received the notification under paragraph 2, demonstrates that they have legitimate rights and requests the resumption of the NFT's use and distribution, the "Company" will notify the Claimant and the member holding the NFT of the request for resumption and the scheduled resumption date without delay and will resume the "Rights of NFT Holders" on the scheduled date. However, if the Claimant informs the Company before the scheduled resumption date that they have filed a lawsuit against the "NFT Issuer" for infringement, this does not apply.</p>
<p>4. If a "Claimant" asserts rights infringement related to a specific NFT and the digital works linked to it and/or the "NFT IP," the "Company" will suspend the intermediary services for the relevant NFT and promptly notify the "Claimant" and the "NFT Issuer" according to this Article and Article 5, Clause 6. The Company will mediate discussions between the "Claimant" and the "NFT Issuer" to resolve the related dispute as quickly as possible. Once the dispute is resolved, the Company may resume the suspended NFT transaction intermediation based on the conditions of the dispute resolution.</p>
<p>5. The Company is not liable for any damages that may occur to members holding the NFT due to unresolved disputes between the NFT Issuer and the Claimant. However, if damage occurs due to the Company's intentional or negligent actions, the Company will bear responsibility within the scope prescribed by law.</p>
<p>6. If a person claims that their copyright, portrait rights, or other rights are infringed upon by a specific "Auxiliary Work" and provides evidence of the infringement to the "Company," or if such an issue is likely to arise, the Company may suspend the exposure of the relevant "Auxiliary Work" and replace it with a separate image designated by the Company.</p>
<p>
<strong>Article 9 (Relationship with Best Patent Terms of Service)</strong>
</p>
<p>1. Matters not specified in these terms of service are governed by the Best Patent Terms of Service and applicable laws. Matters not prescribed by applicable laws follow customary practices.</p>
<p>2. In the event of a conflict between these terms of service and the Best Patent Terms of Service, these terms of service shall prevail.</p>
<p>
<strong>Article 10 (Governing Law and Dispute Resolution)</strong> Korean law applies to disputes related to these terms of service.</p>
<p>
<strong>Addendum</strong> These terms of service will take effect on August 10, 2024. You can review the previous terms of service below.</p>
<p> </p>
  </>
);
