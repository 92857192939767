import { useRoutes } from "react-router-dom";
import Home from "../page/Home";
import Explore1 from "../page/explore-1";
import Explore2 from "../page/explore-2";
import ExploreDetails from "../page/explore-details";
import LiveAction from "../page/live-action";
import Collection from "../page/collection";
import CollectionDetails from "../page/collection-details";
import Page404 from '../page/page-404';
import About from '../page/about';
import Activity from '../page/activity';
import BlogDetails from '../page/blog-details';
import BlogWithSidebar from '../page/blog-with-sidebar';
import Blog from '../page/blog';
import ConnectWallet from '../page/connect-wallet';
import Contact from '../page/contact';
import CreateItems from '../page/create-items';
import Faq from '../page/faq';
import ForgetPassword from '../page/forget-password';
import HelpCenterBrowserByCategory from '../page/help-center-browser-by-category';
import HelpCenter from '../page/help-center';
import HelpDetails from '../page/help-details';
import HowItWorks from '../page/how-it-works';
import Login from '../page/login';
import PrivacyPolicy from '../page/privacy-policy';
import Register from '../page/register';
import TermsCondition from '../page/terms-condition';
import UserAccountSettings from '../page/user-account-settings';
import UserActivity from '../page/user-activity';
import UserItems from '../page/user-items';
import UserNFTS from '../page/user-nfts';
import UserProfile from '../page/user-profile';
import UserWallet from '../page/user-wallet';
import Idea from '../page/idea';
import MyIdea from '../page/my-idea';
import General from '../page/general';
import Bigtech from '../page/bigtech';
const routes = [
    {
        path:"/",
        element:<Home />
    },
    {
        path:"/index",
        element:<Home />
    },
    {
        path:"/explore-1",
        element:<Explore1/>
    },
    {
        path:"/explore-2",
        element:<Explore2/>
    },
    {
        path:"/explore-details",
        element:<ExploreDetails/>
    },
    {
        path:"/live-action",
        element:<LiveAction/>
    },
    {
        path:"/collection",
        element:<Collection/>
    },
    {
        path:"/collection-details",
        element:<CollectionDetails/>
    },
    { path: '/404', element: <Page404/> },
    { path: '/about', element: <About/> },
    { path: '/activity', element: <Activity/> },
    { path: '/blog-details', element: <BlogDetails/> },
    { path: '/blog-with-sidebar', element: <BlogWithSidebar/> },
    { path: '/blog', element: <Blog/> },
    { path: '/connect-wallet', element: <ConnectWallet/> },
    { path: '/contact', element: <Contact/> },
    { path: '/create-items', element: <CreateItems/> },
    { path: '/faq', element: <Faq/> },
    { path: '/forget-password', element: <ForgetPassword/> },
    {
      path: '/help-center-browser-by-category',
      element: <HelpCenterBrowserByCategory/>
    },
    { path: '/help-center', element: <HelpCenter/> },
    { path: '/help-details', element: <HelpDetails/> },
    { path: '/how-it-works', element: <HowItWorks/> },
    { path: '/live-action', element: <LiveAction/> },
    { path: '/login', element: <Login/> },
    { path: '/privacy-policy', element: <PrivacyPolicy/> },
    { path: '/register', element: <Register/> },
    { path: '/terms-condition', element: <TermsCondition/> },
    { path: '/user-account-settings', element: <UserAccountSettings/> },
    { path: '/user-activity', element: <UserActivity/> },
    { path: '/user-items', element: <UserItems/> },
    { path: '/user-nfts', element: <UserNFTS/> },
    { path: '/user-profile', element: <UserProfile/> },
    { path: '/user-wallet', element: <UserWallet/> },
    { path: '/idea', element: <Idea/> },
    { path: '/my-idea', element: <MyIdea/> },
    { path: '/general', element: <General/> },
    { path: '/bigtech', element: <Bigtech/> },
    { path: '*', element:<Page404/>}
];
// 使用useRoutes 创建
export default function RouterView() {
    // 创建路由
    const elem = useRoutes(routes);
    // 返回接口
    return elem;
}