import { useState } from "react";
import http from "../http";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function Register() {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  let [name, setName] = useState("");
  let [phone, setPhone] = useState("");
  let [birthday, setBirthday] = useState("");
  let [walletAddress, setWalletAddress] = useState("");
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [code, setCode] = useState("");
  let [lastTime, setLastTime] = useState(0);
  let [uuid, setUuid] = useState("");

  let register = (event) => {
    event && event.preventDefault();
    let emailReg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailReg.test(email)) {
      messageApi.open({
        type: "error",
        content: t("register.input_email"),
      });
      return;
    }
    if (!code) {
      messageApi.open({
        type: "error",
        content: t("register.input_auth_code"),
      });
      return;
    }
    let passwordReg = /^(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
    if (!passwordReg.test(password)) {
      messageApi.open({
        type: "error",
        content: t("register.input_password"),
      });
      return;
    }
    if (password != confirmPassword) {
      messageApi.open({
        type: "error",
        content: t("register.password_mismatch"),
      });
      return;
    }
    if (!name) {
      messageApi.open({
        type: "error",
        content: t("register.input_name"),
      });
      return;
    }
    if (phone.length < 6) {
      messageApi.open({
        type: "error",
        content: t("register.input_phone"),
      });
      return;
    }
    if (!birthday) {
      messageApi.open({
        type: "error",
        content: t("register.input_birthday"),
      });
      return;
    }

    http
      .post("/register", {
        name,
        phone,
        birthday,
        walletAddress,
        email,
        password,
        confirmPassword,
        uuid,
        code,
      })
      .then((res) => {
        navigate("/login");
      })
      .catch(() => {});
  };
  let sendMsgTime;
  let sendMsg = () => {
    if (lastTime != 0) return;

    http
      .post("/getRegisterCode", {
        email,
      })
      .then((res) => {
        setUuid(res.uuid);
        lastTime = 60;
        setLastTime(lastTime);
        sendMsgTime = setInterval(() => {
          if (lastTime <= 0) {
            clearInterval(sendMsgTime);
            setLastTime(0);
            return;
          }
          lastTime--;
          setLastTime(lastTime);
        }, 1000);
      })
      .catch((err) => {});
  };
  return (
    <>
      {contextHolder}
      {/* End Header Section */}

      <div className="cs-height_90 cs-height_lg_80"></div>
      {/* Start Page Head */}
      <section
        className="cs-page_head cs-bg"
        data-src="/assets/img/page_head_bg.svg"
      >
        <div className="container">
          <div className="text-center">
            <h1 className="cs-page_title">{t('register.sign')}</h1>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#/index">{t("register.home")}</a>
              </li>
              <li className="breadcrumb-item active">{t('register.sign')}</li>
            </ol>
          </div>
        </div>
      </section>
      {/* End Page Head */}

      <div className="cs-height_100 cs-height_lg_70"></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-md-8 offset-xl-3 offset-md-2">
            <form className="cs-form_card cs-style1 cs-box_shadow cs-white_bg">
              <div className="cs-form_card_in" style={{ maxWidth: "530px" }}>
                <div className="cs-section_heading cs-style2">
                  <h2 className="cs-form_title">Create Account</h2>
                  <div style={{ color: "#8B8B8B", fontWeight: "300" }}>
                    <b className="cs-form-item_must">*</b>{t('register.tip1')}
                  </div>
                </div>
                <div className="cs-form-item">
                  <label className="cs-form-item_label">
                    E-mail<b className="cs-form-item_must">*</b>
                  </label>
                  <div className="cs-form_field_wrap">
                    <div className="cs-form_field cs-form-item_field">
                      <input
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        type="email"
                        placeholder={t("register.input_email_id")}
                      />
                    </div>
                  </div>
                </div>
                <div className="cs-height_30 cs-height_lg_30"></div>
                <div className="cs-form-item">
                  <label className="cs-form-item_label">
                    {t("register.auth_code")}
                    <b className="cs-form-item_must">*</b>
                  </label>
                  <div className="cs-form_field_wrap">
                    <div className="cs-form_field cs-form-item_field">
                      <input
                        value={code}
                        onChange={(event) => setCode(event.target.value)}
                        type="text"
                        style={{ width: "100px" }}
                        placeholder={t("register.input_auth_code")}
                      />
                      <div
                        onClick={sendMsg}
                        style={{
                          cursor: "pointer",
                          color: "#475EFF",
                          fontSize: "13px",
                        }}
                      >
                        {lastTime
                          ? `${lastTime}S`
                          : t("register.send_auth_code")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cs-height_20 cs-height_lg_20"></div>
                <div className="cs-form-item">
                  <label className="cs-form-item_label">
                    PW<b className="cs-form-item_must">*</b>
                  </label>
                  <div className="cs-form_field_wrap">
                    <div className="cs-form_field cs-form-item_field">
                      <input
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        type="password"
                        placeholder={t("register.password_requirements")}
                      />
                    </div>
                  </div>
                </div>
                <div className="cs-height_20 cs-height_lg_20"></div>
                <div className="cs-form-item">
                  <label className="cs-form-item_label">
                    PW {t("register.confirm")}
                    <b className="cs-form-item_must">*</b>
                  </label>
                  <div className="cs-form_field_wrap">
                    <div className="cs-form_field cs-form-item_field">
                      <input
                        value={confirmPassword}
                        onChange={(event) =>
                          setConfirmPassword(event.target.value)
                        }
                        type="password"
                        placeholder={t("register.password_requirements")}
                      />
                    </div>
                  </div>
                </div>
                <div className="cs-height_20 cs-height_lg_20"></div>
                <div className="cs-form-item">
                  <label className="cs-form-item_label">
                    {t("register.name")}
                    <b className="cs-form-item_must">*</b>
                  </label>
                  <div className="cs-form_field_wrap">
                    <div className="cs-form_field cs-form-item_field">
                      <input
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        type="text"
                        placeholder={t("register.input_user_name")}
                      />
                    </div>
                  </div>
                </div>
                <div className="cs-height_20 cs-height_lg_20"></div>
                <div className="cs-form-item">
                  <label className="cs-form-item_label">
                    {t("register.phone")}
                    <b className="cs-form-item_must">*</b>
                  </label>
                  <div className="cs-form_field_wrap">
                    <div className="cs-form_field cs-form-item_field">
                      <input
                        value={phone}
                        onChange={(event) => setPhone(event.target.value)}
                        type="text"
                        placeholder="010-0000-0000"
                      />
                    </div>
                  </div>
                </div>
                <div className="cs-height_20 cs-height_lg_20"></div>
                <div className="cs-form-item">
                  <label className="cs-form-item_label">
                    {t("register.birthday")}
                    <b className="cs-form-item_must">*</b>
                  </label>
                  <div className="cs-form_field_wrap">
                    <div className="cs-form_field cs-form-item_field">
                      <input
                        value={birthday}
                        onChange={(event) => setBirthday(event.target.value)}
                        type="date"
                        placeholder={t("register.birthday_format")}
                      />
                    </div>
                  </div>
                </div>
                <div className="cs-height_20 cs-height_lg_20"></div>
                <div className="cs-form-item">
                  <label className="cs-form-item_label">
                    {t("register.wallet_address")}
                  </label>
                  <div className="cs-form_field_wrap">
                    <div className="cs-form_field cs-form-item_field">
                      <input
                        value={walletAddress}
                        onChange={(event) =>
                          setWalletAddress(event.target.value)
                        }
                        type="text"
                        placeholder={t("register.input_wallet_address")}
                      />
                    </div>
                  </div>
                </div>

                <div className="cs-height_20 cs-height_lg_20"></div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="notify"
                  />
                  <label className="form-check-label" htmlFor="notify">
                    I'm attracted in receiving marketing emails for updates on
                    upcoming promotions, events and new features
                  </label>
                </div>
                <div className="cs-height_10 cs-height_lg_10"></div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="terms"
                  />
                  <label className="form-check-label" htmlFor="terms">
                    I'm attracted in receiving marketing emails for updates on
                    upcoming promotions, events and new features
                  </label>
                </div>
                <div className="cs-height_20 cs-height_lg_20"></div>
                <button
                  className="cs-btn cs-style1 cs-btn_lg w-100"
                  onClick={register}
                >
                  <span>Register Now</span>
                </button>
                <div className="cs-height_30 cs-height_lg_30"></div>
                <div className="cs-form_footer text-center">
                  Have an account? <a href="#/login">Log In</a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="cs-height_100 cs-height_lg_70"></div>
    </>
  );
}
