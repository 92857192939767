export default function HelpCenterBrowserByCategory() {
  return (<>



    {/* End Header Section */}

    <div className="cs-height_90 cs-height_lg_80"></div>
    {/* Start Page Head */}
    <section className="cs-page_head cs-bg" data-src="/assets/img/page_head_bg.svg">
      <div className="container">
        <div className="text-center">
          <h1 className="cs-page_title">Help & Support Browse By Category</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#/index">홈</a></li>
            <li className="breadcrumb-item active">Help & Support by Category</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Page Head */}

    <div className="cs-height_100 cs-height_lg_70"></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          <div className="cs-section_heading cs-style3">
            <h2 className="cs-section_title">Payment & Balance</h2>
            <div className="cs-section_seperator"></div>
          </div>
          <div className="cs-height_50 cs-height_lg_50"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">Do you have to mint an Best Patent Token to sell it?</span>
          </a>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How much does the average Best Patent Token sell for?</span>
          </a>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">What kind of Best Patent Token sell best?</span>
          </a>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How to make money with Best Patent Token as a beginner?</span>
          </a>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">Who owns the most expensive Best Patent Token?</span>
          </a>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How much does the average Best Patent Token sell for?</span>
          </a>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How to make money with Best Patent Token as a beginner?</span>
          </a>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How much does the average Best Patent Token sell for?</span>
          </a>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How much does the average Best Patent Token sell for?</span>
          </a>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">What kind of Best Patent Token sell best?</span>
          </a>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">Who owns the most expensive Best Patent Token?</span>
          </a>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How much does the average Best Patent Token sell for?</span>
          </a>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How to make money with Best Patent Token as a beginner?</span>
          </a>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How much does the average Best Patent Token sell for?</span>
          </a>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">How much does the average Best Patent Token sell for?</span>
          </a>
          <div className="cs-height_20 cs-height_lg_20"></div>
          <a href="#" className="cs-text_box cs-style2">
            <span className="cs-white_bg">What kind of Best Patent Token sell best?</span>
          </a>
        </div>
        <div className="col-lg-4">
          <div className="cs-height_0 cs-height_lg_70"></div>
          <div className="cs-blog_sidebar">
            <div className="cs-sidebar_widget cs-box_shadow cs-white_bg">
              <form action="#" className="cs-search">
                <input type="text" className="cs-search_input" placeholder="검색" />
                <button className="cs-btn cs-style1">
                  <span>검색</span>
                </button>
              </form>
            </div>
            <div className="cs-sidebar_widget cs-box_shadow cs-white_bg">
              <h2 className="cs-widget_title"><span>All Best Patent Token Topic</span></h2>
              <ul className="cs-widget_list">
                <li>
                  <a href="#">User Guide</a>
                </li>
                <li>
                  <a href="#">Collecting</a>
                </li>
                <li>
                  <a href="#">Payment & Balance</a>
                </li>
                <li>
                  <a href="#">Selling</a>
                </li>
                <li>
                  <a href="#">Frequently Asked Questions</a>
                </li>
                <li>
                  <a href="#">User Safety</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="cs-height_100 cs-height_lg_70"></div>

    {/* Start CTA */}
    <div className="container">
      <div className="cs-cta cs-style2 cs-type1 text-center cs-accent_bg">
        <h2 className="cs-cta_title cs-white_color_8">Join our biggest Best Patent Token platform</h2>
        <a href="#/register" className="cs-btn cs-style1 cs-btn_lg cs-color2"><span>Sign Up</span></a>
      </div>
    </div>
    {/* End CTA */}
    <div className="cs-height_100 cs-height_lg_70"></div>




  </>);
}