export default function ForgetPassword() {
  return (<>



    {/* End Header Section */}

    <div className="cs-height_90 cs-height_lg_80"></div>
    {/* Start Page Head */}
    <section className="cs-page_head cs-bg" data-src="/assets/img/page_head_bg.svg">
      <div className="container">
        <div className="text-center">
          <h1 className="cs-page_title">Reset Password</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#/index">홈</a></li>
            <li className="breadcrumb-item active">Reset Password</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Page Head */}

    <div className="cs-height_100 cs-height_lg_70"></div>
    <div className="container">
      <div className="row">
        <div className="col-xl-6 col-md-8 offset-xl-3 offset-md-2">
          <form className="cs-form_card cs-style1 cs-type1 cs-box_shadow cs-white_bg">
            <h2 className="cs-form_title">Enter your username to continue</h2>
            <div className="cs-form_field_wrap">
              <input type="email" className="cs-form_field" placeholder="Enter Your Email" />
            </div>
            <div className="cs-height_20 cs-height_lg_20"></div>
            <button className="cs-btn cs-style1 cs-btn_lg w-100">
              <span>Forgot Password</span>
            </button>
          </form>
        </div>
      </div>
    </div>
    <div className="cs-height_100 cs-height_lg_70"></div>




  </>);
}