export default () => (
  <>
    <b>⭘ 런칭 이벤트</b>
    <p>
      전기차 폐배터리의 재사용·재활용에 대한 혁신적이고 창의적인 아이디어를
      모집합니다!
    </p>
    <b>⭘ 일정</b>
    <ul>
      <li>
        <b> 공모 접수:</b>2024년 9월 1일 ~ 2024년 11월 30일 (3개월)
      </li>
      <li>
        <b> 결과 발표:</b>2024년 12월 20일(금)
      </li>
    </ul>

    <b>⭘ 공모 주제</b>
    <p>- 폐배터리 재사용 아이디어</p>
    <ul>
      <li>폐배터리를 활용한 새로운 제품 개발</li>
      <li>기존 제품에 폐배터리를 적용하여 성능 개선</li>
    </ul>
    <p>- 폐배터리 재활용 아이디어</p>
    <ul>
      <li> 폐배터리 재활용을 위한 효율적인 기술 개발</li>
      <li> 재활용 과정에서 발생하는 문제점 해결 방법</li>
    </ul>
    <b>⭘ 응모 방법</b>
    <p>- BPT 플랫폼에서 회원 가입</p>
    <p>- 아이디어 기본정보 작성 및 파일 첨부 후 제출</p>
    <b>⭘ 심사 방법</b>
    <p>- 특허전문가들의 서면 평가를 통한 심사</p>
    <p>- 심사 기준</p>
    <table>
      <thead className="cs-gray_bg ">
        <tr>
          <th>지 표</th>
          <th>배 점</th>
          <th>내 용</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>창의성</td>
          <td>30</td>
          <td>독창적이고 혁신적인 아이디어인가?</td>
        </tr>
        <tr>
          <td>실현가능성</td>
          <td>30</td>
          <td>기술적, 경제적으로 실현 가능한가?</td>
        </tr>
        <tr>
          <td>지속가능성</td>
          <td>30</td>
          <td>환경에 긍정적인 영향을 미치는가?</td>
        </tr>
        <tr>
          <td>사회영향력</td>
          <td>10</td>
          <td>사회에 미치는 긍정적인 효과가 큰가?</td>
        </tr>
      </tbody>
    </table>
    <b>⭘ 시상 내역</b>
    <p></p>
    <table>
      <thead className="cs-gray_bg">
        <tr>
          <th>상 급</th>
          <th>시상 규모</th>
          <th>수상 혜택</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>대상</td>
          <td>1인</td>
          <td>1,000BPT 지급, AI 특허명세서 이용권</td>
        </tr>
        <tr>
          <td>최우수</td>
          <td>1인</td>
          <td>800BPT 지급</td>
        </tr>
        <tr>
          <td>우수상</td>
          <td>2인</td>
          <td>500BPT 지급</td>
        </tr>
        <tr>
          <td>장려상</td>
          <td>3인</td>
          <td>300BPT 지급</td>
        </tr>
      </tbody>
    </table>
    <p>⭘ 문의처: patentnft@patent-nft.com</p>
    <p>⭘ 기타 사항</p>
    <p>- 적합한 아이디어가 없는 경우 선정하지 않을 수 있습니다.</p>
    <p>
      - 제출된 아이디어에 대한 소유권은 제출자에게 있습니다. 단, 기업 소속으로
      아이디어를 제출하실 경우 기업과 제출자 간 소유권 문제가 없도록 유의하시기
      바랍니다.
    </p>
    <p>
      - 수상작은 주최 측의 판단에 따라 실용화될 수 있으며, 이 과정에서 추가적인
      협력이 필요할 수 있습니다.
    </p>
    <p>
      - 참가자는 응모와 동시에 본 공모전의 모든 조건에 동의한 것으로 간주됩니다.
    </p>
  </>
);
