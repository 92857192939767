export default function Collection() {
  return (<>



    {/* End Header Section */}

    <div className="cs-height_90 cs-height_lg_80"></div>
    {/* Start Page Head */}
    <section className="cs-page_head cs-bg" data-src="/assets/img/page_head_bg.svg">
      <div className="container">
        <div className="text-center">
          <h1 className="cs-page_title">Explore Collections</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="#/index">홈</a></li>
            <li className="breadcrumb-item active">Explore Collections</li>
          </ol>
        </div>
      </div>
    </section>
    {/* End Page Head */}
    <div className="cs-height_100 cs-height_lg_70"></div>
    <div className="container">
      <div className="cs-isotop_filter cs-style1 cs-center">
        <ul className="cs-mp0 cs-center">
          <li className="active"><a href="#" data-filter="*"><span>All Items</span></a></li>
          <li><a href="#" data-filter=".fashion"><span>Fashion</span></a></li>
          <li><a href="#" data-filter=".music"><span>Music</span></a></li>
          <li><a href="#" data-filter=".video"><span>Video</span></a></li>
          <li><a href="#" data-filter=".games"><span>Games</span></a></li>
        </ul>
      </div>
      <div className="cs-height_30 cs-height_lg_30"></div>
      <div className="cs-isotop cs-style1 cs-isotop_col_5 cs-has_gutter_30">
        <div className="cs-grid_sizer"></div>
        <div className="cs-isotop_item fashion">
          <div className="cs-card cs-style3 cs-box_shadow cs-white_bg">
            <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
              <img src="/assets/img/general/general_7.jpeg" alt="Image" className="cs-zoom_item" />
            </a>
            <a href="#" className="cs-avatar">
              <img src="/assets/img/avatar/avatar_7.png" alt="Avatar" />
              <span className="cs-avatar_status"></span>
            </a>
            <div className="cs-card_info">
              <h3 className="cs-card_title"><a href="#/explore-details">Audioglyphs</a></h3>
              <div className="cs-card_subtitle">
                <i className="far fa-check-circle fa-fw"></i>
                <span>Guarranted Authentic</span>
              </div>
              <div className="cs-catd_meta">
                <a href="#" className="cs-catd_meta_item">
                  <i className="fas fa-list-ul fa-fw"></i>
                  <span>120 Items</span>
                </a>
                <a href="#" className="cs-catd_meta_item">
                  <i className="far fa-eye fa-fw"></i>
                  <span>1K Views</span>
                </a>
              </div>
              <div className="cs-catd_footer">
                <div className="cs-left">
                  <h4>Volume</h4>
                  <p>22.9k</p>
                </div>
                <div className="cs-left">
                  <h4>Floor Price</h4>
                  <p>4.2 ETH</p>
                </div>
              </div>
            </div>
          </div>
        </div>{/* .cs-isotop_item */}
        <div className="cs-isotop_item music">
          <div className="cs-card cs-style3 cs-box_shadow cs-white_bg">
            <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
              <img src="/assets/img/general/general_8.jpeg" alt="Image" className="cs-zoom_item" />
            </a>
            <a href="#" className="cs-avatar">
              <img src="/assets/img/avatar/avatar_8.png" alt="Avatar" />
            </a>
            <div className="cs-card_info">
              <h3 className="cs-card_title"><a href="#/explore-details">Audioglyphs</a></h3>
              <div className="cs-card_subtitle">
                <i className="far fa-check-circle fa-fw"></i>
                <span>Guarranted Authentic</span>
              </div>
              <div className="cs-catd_meta">
                <a href="#" className="cs-catd_meta_item">
                  <i className="fas fa-list-ul fa-fw"></i>
                  <span>234 Items</span>
                </a>
                <a href="#" className="cs-catd_meta_item">
                  <i className="far fa-eye fa-fw"></i>
                  <span>2.1K Views</span>
                </a>
              </div>
              <div className="cs-catd_footer">
                <div className="cs-left">
                  <h4>Volume</h4>
                  <p>87.9k</p>
                </div>
                <div className="cs-left">
                  <h4>Floor Price</h4>
                  <p>6.7 ETH</p>
                </div>
              </div>
            </div>
          </div>
        </div>{/* .cs-isotop_item */}
        <div className="cs-isotop_item fashion">
          <div className="cs-card cs-style3 cs-box_shadow cs-white_bg">
            <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
              <img src="/assets/img/general/general_9.jpeg" alt="Image" className="cs-zoom_item" />
            </a>
            <a href="#" className="cs-avatar">
              <img src="/assets/img/avatar/avatar_9.png" alt="Avatar" />
              <span className="cs-avatar_status"></span>
            </a>
            <div className="cs-card_info">
              <h3 className="cs-card_title"><a href="#/explore-details">Audioglyphs</a></h3>
              <div className="cs-card_subtitle">
                <i className="far fa-check-circle fa-fw"></i>
                <span>Guarranted Authentic</span>
              </div>
              <div className="cs-catd_meta">
                <a href="#" className="cs-catd_meta_item">
                  <i className="fas fa-list-ul fa-fw"></i>
                  <span>560 Items</span>
                </a>
                <a href="#" className="cs-catd_meta_item">
                  <i className="far fa-eye fa-fw"></i>
                  <span>3.1K Views</span>
                </a>
              </div>
              <div className="cs-catd_footer">
                <div className="cs-left">
                  <h4>Volume</h4>
                  <p>99.9k</p>
                </div>
                <div className="cs-left">
                  <h4>Floor Price</h4>
                  <p>4.8 ETH</p>
                </div>
              </div>
            </div>
          </div>
        </div>{/* .cs-isotop_item */}
        <div className="cs-isotop_item video">
          <div className="cs-card cs-style3 cs-box_shadow cs-white_bg">
            <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
              <img src="/assets/img/general/general_10.jpeg" alt="Image" className="cs-zoom_item" />
            </a>
            <a href="#" className="cs-avatar">
              <img src="/assets/img/avatar/avatar_10.png" alt="Avatar" />
            </a>
            <div className="cs-card_info">
              <h3 className="cs-card_title"><a href="#/explore-details">Audioglyphs</a></h3>
              <div className="cs-card_subtitle">
                <i className="far fa-check-circle fa-fw"></i>
                <span>Guarranted Authentic</span>
              </div>
              <div className="cs-catd_meta">
                <a href="#" className="cs-catd_meta_item">
                  <i className="fas fa-list-ul fa-fw"></i>
                  <span>345 Items</span>
                </a>
                <a href="#" className="cs-catd_meta_item">
                  <i className="far fa-eye fa-fw"></i>
                  <span>2.1K Views</span>
                </a>
              </div>
              <div className="cs-catd_footer">
                <div className="cs-left">
                  <h4>Volume</h4>
                  <p>19.8k</p>
                </div>
                <div className="cs-left">
                  <h4>Floor Price</h4>
                  <p>3.5 ETH</p>
                </div>
              </div>
            </div>
          </div>
        </div>{/* .cs-isotop_item */}
        <div className="cs-isotop_item games">
          <div className="cs-card cs-style3 cs-box_shadow cs-white_bg">
            <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
              <img src="/assets/img/general/general_11.jpeg" alt="Image" className="cs-zoom_item" />
            </a>
            <a href="#" className="cs-avatar">
              <img src="/assets/img/avatar/avatar_11.png" alt="Avatar" />
            </a>
            <div className="cs-card_info">
              <h3 className="cs-card_title"><a href="#/explore-details">Audioglyphs</a></h3>
              <div className="cs-card_subtitle">
                <i className="far fa-check-circle fa-fw"></i>
                <span>Guarranted Authentic</span>
              </div>
              <div className="cs-catd_meta">
                <a href="#" className="cs-catd_meta_item">
                  <i className="fas fa-list-ul fa-fw"></i>
                  <span>140 Items</span>
                </a>
                <a href="#" className="cs-catd_meta_item">
                  <i className="far fa-eye fa-fw"></i>
                  <span>2.1K Views</span>
                </a>
              </div>
              <div className="cs-catd_footer">
                <div className="cs-left">
                  <h4>Volume</h4>
                  <p>12.9k</p>
                </div>
                <div className="cs-left">
                  <h4>Floor Price</h4>
                  <p>2.8 ETH</p>
                </div>
              </div>
            </div>
          </div>
        </div>{/* .cs-isotop_item */}
        <div className="cs-isotop_item fashion">
          <div className="cs-card cs-style3 cs-box_shadow cs-white_bg">
            <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
              <img src="/assets/img/general/general_9.jpeg" alt="Image" className="cs-zoom_item" />
            </a>
            <a href="#" className="cs-avatar">
              <img src="/assets/img/avatar/avatar_9.png" alt="Avatar" />
              <span className="cs-avatar_status"></span>
            </a>
            <div className="cs-card_info">
              <h3 className="cs-card_title"><a href="#/explore-details">Audioglyphs</a></h3>
              <div className="cs-card_subtitle">
                <i className="far fa-check-circle fa-fw"></i>
                <span>Guarranted Authentic</span>
              </div>
              <div className="cs-catd_meta">
                <a href="#" className="cs-catd_meta_item">
                  <i className="fas fa-list-ul fa-fw"></i>
                  <span>560 Items</span>
                </a>
                <a href="#" className="cs-catd_meta_item">
                  <i className="far fa-eye fa-fw"></i>
                  <span>3.1K Views</span>
                </a>
              </div>
              <div className="cs-catd_footer">
                <div className="cs-left">
                  <h4>Volume</h4>
                  <p>99.9k</p>
                </div>
                <div className="cs-left">
                  <h4>Floor Price</h4>
                  <p>4.8 ETH</p>
                </div>
              </div>
            </div>
          </div>
        </div>{/* .cs-isotop_item */}
        <div className="cs-isotop_item music">
          <div className="cs-card cs-style3 cs-box_shadow cs-white_bg">
            <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
              <img src="/assets/img/general/general_10.jpeg" alt="Image" className="cs-zoom_item" />
            </a>
            <a href="#" className="cs-avatar">
              <img src="/assets/img/avatar/avatar_10.png" alt="Avatar" />
            </a>
            <div className="cs-card_info">
              <h3 className="cs-card_title"><a href="#/explore-details">Audioglyphs</a></h3>
              <div className="cs-card_subtitle">
                <i className="far fa-check-circle fa-fw"></i>
                <span>Guarranted Authentic</span>
              </div>
              <div className="cs-catd_meta">
                <a href="#" className="cs-catd_meta_item">
                  <i className="fas fa-list-ul fa-fw"></i>
                  <span>345 Items</span>
                </a>
                <a href="#" className="cs-catd_meta_item">
                  <i className="far fa-eye fa-fw"></i>
                  <span>2.1K Views</span>
                </a>
              </div>
              <div className="cs-catd_footer">
                <div className="cs-left">
                  <h4>Volume</h4>
                  <p>19.8k</p>
                </div>
                <div className="cs-left">
                  <h4>Floor Price</h4>
                  <p>3.5 ETH</p>
                </div>
              </div>
            </div>
          </div>
        </div>{/* .cs-isotop_item */}
        <div className="cs-isotop_item games">
          <div className="cs-card cs-style3 cs-box_shadow cs-white_bg">
            <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
              <img src="/assets/img/general/general_11.jpeg" alt="Image" className="cs-zoom_item" />
            </a>
            <a href="#" className="cs-avatar">
              <img src="/assets/img/avatar/avatar_11.png" alt="Avatar" />
            </a>
            <div className="cs-card_info">
              <h3 className="cs-card_title"><a href="#/explore-details">Audioglyphs</a></h3>
              <div className="cs-card_subtitle">
                <i className="far fa-check-circle fa-fw"></i>
                <span>Guarranted Authentic</span>
              </div>
              <div className="cs-catd_meta">
                <a href="#" className="cs-catd_meta_item">
                  <i className="fas fa-list-ul fa-fw"></i>
                  <span>140 Items</span>
                </a>
                <a href="#" className="cs-catd_meta_item">
                  <i className="far fa-eye fa-fw"></i>
                  <span>2.1K Views</span>
                </a>
              </div>
              <div className="cs-catd_footer">
                <div className="cs-left">
                  <h4>Volume</h4>
                  <p>12.9k</p>
                </div>
                <div className="cs-left">
                  <h4>Floor Price</h4>
                  <p>2.8 ETH</p>
                </div>
              </div>
            </div>
          </div>
        </div>{/* .cs-isotop_item */}
        <div className="cs-isotop_item music">
          <div className="cs-card cs-style3 cs-box_shadow cs-white_bg">
            <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
              <img src="/assets/img/general/general_7.jpeg" alt="Image" className="cs-zoom_item" />
            </a>
            <a href="#" className="cs-avatar">
              <img src="/assets/img/avatar/avatar_7.png" alt="Avatar" />
              <span className="cs-avatar_status"></span>
            </a>
            <div className="cs-card_info">
              <h3 className="cs-card_title"><a href="#/explore-details">Audioglyphs</a></h3>
              <div className="cs-card_subtitle">
                <i className="far fa-check-circle fa-fw"></i>
                <span>Guarranted Authentic</span>
              </div>
              <div className="cs-catd_meta">
                <a href="#" className="cs-catd_meta_item">
                  <i className="fas fa-list-ul fa-fw"></i>
                  <span>120 Items</span>
                </a>
                <a href="#" className="cs-catd_meta_item">
                  <i className="far fa-eye fa-fw"></i>
                  <span>1K Views</span>
                </a>
              </div>
              <div className="cs-catd_footer">
                <div className="cs-left">
                  <h4>Volume</h4>
                  <p>22.9k</p>
                </div>
                <div className="cs-left">
                  <h4>Floor Price</h4>
                  <p>4.2 ETH</p>
                </div>
              </div>
            </div>
          </div>
        </div>{/* .cs-isotop_item */}
        <div className="cs-isotop_item games">
          <div className="cs-card cs-style3 cs-box_shadow cs-white_bg">
            <a href="#/explore-details" className="cs-card_thumb cs-zoom_effect">
              <img src="/assets/img/general/general_8.jpeg" alt="Image" className="cs-zoom_item" />
            </a>
            <a href="#" className="cs-avatar">
              <img src="/assets/img/avatar/avatar_8.png" alt="Avatar" />
            </a>
            <div className="cs-card_info">
              <h3 className="cs-card_title"><a href="#/explore-details">Audioglyphs</a></h3>
              <div className="cs-card_subtitle">
                <i className="far fa-check-circle fa-fw"></i>
                <span>Guarranted Authentic</span>
              </div>
              <div className="cs-catd_meta">
                <a href="#" className="cs-catd_meta_item">
                  <i className="fas fa-list-ul fa-fw"></i>
                  <span>234 Items</span>
                </a>
                <a href="#" className="cs-catd_meta_item">
                  <i className="far fa-eye fa-fw"></i>
                  <span>2.1K Views</span>
                </a>
              </div>
              <div className="cs-catd_footer">
                <div className="cs-left">
                  <h4>Volume</h4>
                  <p>87.9k</p>
                </div>
                <div className="cs-left">
                  <h4>Floor Price</h4>
                  <p>6.7 ETH</p>
                </div>
              </div>
            </div>
          </div>
        </div>{/* .cs-isotop_item */}
      </div>
      <div className="cs-height_40 cs-height_lg_40"></div>
      <div className="text-center">
        <a href="#" className="cs-btn cs-style1 cs-btn_lg"><span>더 보기</span></a>
      </div>
    </div>

    <div className="cs-height_100 cs-height_lg_70"></div>



  </>);
}