import { createStore } from 'redux'
import i18n from 'i18next';
let language=navigator.language || navigator.userLanguage;
if(language!="en"){
    language="ko";
}
let data = {
    isLoading: false,
    userInfo: JSON.parse(localStorage.getItem('userInfo') || '{}'),
    isLogin: !!localStorage.getItem('token')||!!sessionStorage.getItem('token'),
    language:localStorage.getItem('language')||language||"ko"
}
function loading(state, action) {
    switch (action.type) {
        case "showLoading":
            state.isLoading = true
            return { ...state };
        case "hideLoading":
            state.isLoading = false
            return { ...state };
        case "changeUserInfo":
            state.userInfo = action.userInfo
            localStorage.setItem('userInfo', JSON.stringify(action.userInfo))
            return { ...state }
        case "changeIsLogin":
            state.isLogin = action.isLogin
            if(!action.isLogin){
                localStorage.removeItem('token')
                sessionStorage.removeItem('token')
            }
            return { ...state }
        case "changeLanguage":
            state.language=action.language
            localStorage.setItem("language",action.language)
            i18n.changeLanguage(state.language)
            return {...state}
        case "logout":
            state.isLogin=false
            localStorage.removeItem('token')
            sessionStorage.removeItem('token')
            state.userInfo={}
            localStorage.removeItem('userInfo')
            return { ...state };
        default:
            return state;
    }
}
export default createStore(loading, data);